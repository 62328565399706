import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import useCustomerStore, { getAllCustomer, searchCustomerList } from "../../App/Stores/CustomerStore";
import useLayoutStore from "../../App/Stores/LayoutStore";
import useShopStore, { searchShops } from "../../App/Stores/ShopStore";
import useVoucherStore, { addNewVoucher } from "../../App/Stores/VoucherStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import CommonButton from "../../Components/Button/CommonButton";
import CommonDropDown from "../../Components/DropDown/CommonDropDown";
import AsyncSingleCombobox from "../../Components/Input/AsyncSingleCombobox";
import CommonInput from "../../Components/Input/CommonInput";
import CommonLabel from "../../Components/Label/CommonLabel";
import BackLink from "../../Components/Pagination/BackLink";

export default function AddVoucher() {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();
  const { shopListAll } = useShopStore();
  const { customerListAll } = useCustomerStore();
  const {
    addVoucherFormData,
    setAddVoucherFormData,
    voucherListPagination,
    setVoucherLastPageAdd,
    addVoucherSelectedShop,
    setAddVoucherSelectedShop,
    selectedCustomer,
    setSelectedCustomer,
  } = useVoucherStore();

  const [tempDataArrayShop, setTempDataArrayShop] = useState([]);
  const [tempDataArrayCustomer, setTempDataArrayCustomer] = useState([]);
  const [searchShopValue, setSearchShopValue] = useState("");

  const [searchValueDebounced] = useDebounce(searchShopValue, 500);

  useEffect(() => { searchShops(searchValueDebounced, "", 0) }, [searchValueDebounced]);


  const [onState, setOnState] = useState(false);

  const navigateTo = useNavigate();

  const HandleSubmit = async (e) => {
    e.preventDefault()
    // console.log("HandleSubmit is starting...!!");
    // console.log("~ NON-VALIDATED ~ addVoucherFormData:::", addVoucherFormData);
    if (!addVoucherFormData?.type) {
      setOnState(true);
    } else {
      setOnState(false);
    }
    if (
      addVoucherFormData?.title &&
      addVoucherFormData?.type &&
      addVoucherFormData?.voucher_code &&
      addVoucherFormData?.start_date &&
      addVoucherFormData?.end_date &&
      addVoucherFormData?.amount &&
      addVoucherFormData?.limit
    ) {
      // console.log("All Form Data OK!!");
      // console.log("addVoucherFormData:::", addVoucherFormData);
      let loadUrl =
        voucherListPagination.path +
        "?page=" +
        voucherListPagination.last_page;

      setVoucherLastPageAdd(loadUrl);

      let tempSuccess = await addNewVoucher(loadUrl);
      if (tempSuccess) {
        navigateTo("/voucher");
      }
    } else {
      // console.log("HandleSubmit: Failed !");
    }
  };

  useEffect(() => {
    // set the title of page and top bar
    setBarTitle(t("Add Voucher"));
    PageTitle(t("Add Voucher"));

    searchShops("", "", true, 5);
    getAllCustomer("", 5);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // l    shop list all 
  useEffect(() => {
    let t_array = []
    if (shopListAll?.length) {
      shopListAll?.map((item) => t_array.push({ label: item?.user_data?.name, value: item?.user_data?.id }));
    }
    setTempDataArrayShop(t_array);

  }, [shopListAll]);

  // e    customer list all 
  useEffect(() => {
    let t_array_customer = []
    if (customerListAll?.length) {
      customerListAll?.map((item) => t_array_customer.push({ label: item?.name, value: item?.id }));
    }    
    setTempDataArrayCustomer(t_array_customer);

  }, [customerListAll]);


  return (
    <>
      <BackLink linksArray={[
        { label: t("Voucher"), linkTo: "/voucher" },
        { label: t("Add Voucher"), linkTo: "/voucher/add-voucher" },
      ]} />
      <form onSubmit={HandleSubmit } className="bg-white p-5">
        <CommonInput
          className="mb-0"
          label={t("title")}
          name="title"
          required={true}
          max_input={255}
          min_input={5}
          value={addVoucherFormData?.title}
          onChange={setAddVoucherFormData}
          placeholder={t("Enter Voucher title")}
        />

        <div className="flex items-center pt-5">
          <div className="w-full z-10">
            <CommonLabel required={true} labelText={t("Voucher type")} />
            <div className="pt-s16"></div>
            <CommonDropDown
              toggleTitle={t("select Voucher type")}
              data={["global",
              // "customer", 
              // "shop"
            ]} 
              //"type" => "required|in:global,customer,shop",
              itemOnClick={(e) => {
                let tmp = {
                  target: {
                    name: "type",
                    value: e,
                  },
                };
                console.log(tmp);
                setAddVoucherFormData(tmp);
                console.log(tmp.target.name);
                console.log(tmp.target.value);
              }}
              name="type"
              warningBorder={onState}
              value={addVoucherFormData?.type}
              placeholderTextShow={true}
              basicColor={true}
              fullWidth={true}
            />
          </div>
          <div className="p-5"></div>
          <div className="w-full">
            <CommonLabel labelText={t("select shop")} required={addVoucherFormData?.type === "shop" ? true : false} />
            <div className="pt-s16"></div>

            <AsyncSingleCombobox
              withLabel={false}
              placeholder={addVoucherSelectedShop?.name === "" ? 'Select or search a shop' : addVoucherSelectedShop?.name}
              onSearchItem={(value) => setSearchShopValue(value)}
              optionArray={tempDataArrayShop}
              onChangeLabel={(label) => {
                setAddVoucherSelectedShop({ ...addVoucherSelectedShop, name: label });
                console.log("SELECTED item-name: ", label);
              }}
              onChange={(value) => {
                console.log("SELECTED ITEM-ID: ", value);
                setAddVoucherSelectedShop({ ...addVoucherSelectedShop, id: value });
                let tmp = {
                  target: {
                    name: "shop_id",
                    value: value,
                  },
                };
                if (addVoucherFormData?.type === "shop") {
                  setAddVoucherFormData(tmp);
                }
              }}
            />
           
          </div>
        </div>

        <div className="flex items-center py-5">
          <div className="w-full">
            <CommonInput
              className="mb-0"
              label={t("voucher code")}
              name="voucher_code"
              max_input={30}
              min_input={2}
              required={true}
              value={addVoucherFormData?.voucher_code}
              onChange={setAddVoucherFormData}
              placeholder={t("Enter Voucher code to be set")}
            />
          </div>
          <div className="p-5"></div>
          <div className="w-full">
            <CommonInput
              className="mb-0"
              label={t("voucher limit")}
              required={true}
              type="number"
              min_number={1}              
              min_input={1}
              max_input={10}
              name="limit"
              value={addVoucherFormData?.limit}
              onChange={setAddVoucherFormData}
              placeholder={t("Set Usage Limit of a Voucher for same user")}
            />
          </div>
        </div>

        <div className="flex items-center">
          <div className="w-full">
            <CommonInput
              className="mb-0"
              label={t("Start Date")}
              required={true}
              name="start_date"
              type="date"
              value={addVoucherFormData?.start_date}
              onChange={(e) => {
                if (e.target.value > addVoucherFormData?.end_date) {
                  let tmp = {
                    target: {
                      name: "end_date",
                      value: "",
                    },
                  };
                  setAddVoucherFormData(tmp);
                }
                let startDate = {
                  target: {
                    name: "start_date",
                    value: e.target.value,
                  },
                };
                setAddVoucherFormData(startDate);
              }}
              placeholder={t("Select start date")}
            />
          </div>
          <div className="p-5"></div>
          <div className="w-full">
            <CommonInput
              className="mb-0"
              label={t("Expire date")}
              required={true}
              disabled={addVoucherFormData?.start_date ? false : true}
              startDate={addVoucherFormData?.start_date}
              name="end_date"
              type="date"
              value={addVoucherFormData?.end_date}
              onChange={setAddVoucherFormData}
              placeholder={t("Select expiry date")}
            />
          </div>
        </div>

        <div className="flex items-center py-5">
          {/* <div className="w-full">
            <CommonLabel labelText={t("select user")} required={addVoucherFormData?.type === "customer" ? true : false} />
            <div className="pt-2.5"></div>
            <AsyncSingleCombobox
              withLabel={false}
              placeholder={selectedCustomer?.name === "" ? 'select or search a customer' : selectedCustomer?.name}
              onSearchItem={(value) => searchCustomerList(value)}
              optionArray={tempDataArrayCustomer}
              onChangeLabel={(label) => {
                setSelectedCustomer({ ...selectedCustomer, name: label });
                console.log("SELECTED item-name: ", label);
              }}
              onChange={(value) => {
                console.log("SELECTED ITEM-ID: ", value);
                setSelectedCustomer({ ...selectedCustomer, id: value });
                let tmp = {
                  target: {
                    name: "user_id",
                    value: value,
                  },
                };
                if (addVoucherFormData?.type === "customer") {
                  setAddVoucherFormData(tmp);
                }
              }} 
            />

            
            <div className="pt-s16"></div>
            <ComboBox
              noDataMessage={t("No customer available to select.")}
              required={addVoucherFormData?.type === "customer" ? true : false}
              placeholder={t("Start typing Customer name")}
              data={tempCustomerArray ?? tempCustomerArray}
              onSelectItem={(e) => {
                console.log(e);
                let tmp = {
                  target: {
                    name: "user_id",
                    value: e.id,
                  },
                };
                if (addVoucherFormData?.type === "customer") {
                  setAddVoucherFormData(tmp);
                }
              }}
            /> 
          {/* </div> */}
          <div className="grid "></div>
          <div className="w-full">
            <CommonInput
              className="mb-0"
              label={t("discount amount")}
              min_number={1}              
              max_input={10}
              name="amount"
              type="number"
              required={true}
              value={addVoucherFormData?.amount}
              onChange={setAddVoucherFormData}
              placeholder={t("Set discount amount")}
            />
          </div>
        </div>

        <div className="flex justify-center">
          <CommonButton type="submit" btnLabel={t("add voucher")} width="w-[180px]" />
        </div>
      </form>
    </>
  );
}
