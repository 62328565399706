import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useLayoutStore from "../../App/Stores/LayoutStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import OrderTable from "./DataTable/OrderTable";
import OrderDetails from "./OrderDetails";

const Order = () => {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();

  const headers = [
    { index: 1, name: t("Invoice Id") },
    { index: 2, name: t("Customer name") },
    { index: 3, name: t("Shop Name") },
    { index: 4, name: t("Shop phone") },
    { index: 5, name: t("order date") },
    { index: 6, name: t("Status") },
  ];

  useEffect(() => {
    setBarTitle(t("Orders"));
    PageTitle(t("Orders"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
  return (
    <div>
      <OrderTable headers={headers} tableBtnTitle={t("Create Order")} />
    </div>
  );
};

export default Order;
