import React from 'react'
import CommonInput from '../../../Components/Input/CommonInput'
import CommonButton from '../../../Components/Button/CommonButton'
import useSettingsStore, { settingsUpdate } from '../../../App/Stores/SettingsStore';
import { useTranslation } from 'react-i18next';

const TaxPercentage = () => {
    const { t } = useTranslation();
    const { taxPercentage, setTaxPercentage } = useSettingsStore();

    const handleSubmit = () => {
        if (taxPercentage) {
            settingsUpdate({ type: "tax_percentage", value: taxPercentage });
        }
    }
    return (
        <form onSubmit={(e) => e.preventDefault()} className="w-full">
            <div className="pb-5 text-fs20 font-fw600">{t("Tax Percentage")}</div>

            <div className="p-5 bg-white shadow-md rounded-br5">
                <CommonInput
                    min_input={0}
                    className="mb-0"
                    no_label={true}
                    placeholder={t("Enter amount..")}
                    type="number"
                    required={true}
                    min_number={1}
                    value={taxPercentage}
                    onChange={(e) => setTaxPercentage(e.target.value)}
                />

                <div className="pt-5">
                    <CommonButton type="submit" btnLabel={t("Save Changes")} onClick={handleSubmit} colorType="primary" />
                </div>
            </div>
        </form>
    )
}

export default TaxPercentage