import React from "react";
import { useTranslation } from "react-i18next";
import Clamp from "react-multiline-clamp";
import useVoucherStore from "../../App/Stores/VoucherStore";
import { formatDate } from "../../App/Utility/UtilityFunctions";
import CommonButton from "../../Components/Button/CommonButton";
import CommonModal from "../../Components/Modal/CommonModal";
import CommonTable from "../../Components/Table/CommonTable";
import NoDataRow from "../../Components/Table/NoDataRow";

export default function VoucherDetails() {
  const { t } = useTranslation();

  const {
    showDetailsVoucher,
    setShowDetailsVoucher,
    voucherDetails,
    setShowDeleteVoucher,
  } = useVoucherStore();

  return (
    <>
      <CommonModal
        showModal={showDetailsVoucher}
        setShowModal={setShowDetailsVoucher}
        modalTitle={
          <div className="text-center flex justify-center">
            <Clamp withTooltip lines={1} >
              <div className="max-w-[300px]">
                {`${voucherDetails?.voucher?.title === "" || voucherDetails?.voucher?.title === null ? "Voucher" : voucherDetails?.voucher?.title}`}
              </div>
            </Clamp>{": "}
            {t("Details")}
          </div>
        }
        mainContent={
          <div className="pt-5">
            <DeliveryDetails voucherDetails={voucherDetails?.voucher} />
            {voucherDetails?.voucher_orders?.length ? (
              <>
                <div className="pt-5"></div>
                <CommonTable
                  outerPadding="p-0"
                  secondaryPaddingBottom="p-0"
                  shoSearchBox={false}
                  showPagination={false}
                  pagination={false}
                  showPageCountText={false}
                  headers={[
                    { index: 1, name: t("user name") },
                    { index: 2, name: t("order") },
                    { index: 3, name: t("date") },
                  ]}
                  items={
                    voucherDetails?.voucher_orders?.length > 0 ?
                      voucherDetails?.voucher_orders?.map((item, index) => (
                        <VoucherDetailsTableItem item={item} index={index} />
                      )) : <NoDataRow columnNumber={3} />
                  }
                />
              </>
            ) : (
              ""
            )}
            <div className="flex justify-center pt-5">
              <CommonButton
                btnLabel={t("delete")}
                colorType="danger"
                onClick={() => setShowDeleteVoucher(true)}
              />
            </div>
          </div>
        }
      />
    </>
  );
}

const DeliveryDetails = ({ voucherDetails }) => {
  const { t } = useTranslation();

  return (
    <div className="ring-2 ring-cNmSelect py-s10 rounded-br5 text-base">
      <div className="flex justify-between items-center pb-s10 capitalize">
        <div className="font-fw600 px-5">{t("voucher details")}</div>
      </div>
      {barDivider()}
      <div className="pt-s10"></div>
      {dataRow(t("title"), voucherDetails?.title ?? "NA")}
      {dataRow(t("voucher type"), voucherDetails?.type)}
      {dataRow(t("code"), voucherDetails?.voucher_code)}
      {dataRow(t("voucher limit"), voucherDetails?.limit ?? "NA")}
      {dataRow(t("active status"), voucherDetails?.is_active === 1 ? "active" : "inactive")}
      {dataRow(t("start date"), formatDate(voucherDetails?.start_date))}
      {dataRow(t("expire date"), formatDate(voucherDetails?.end_date))}
      {dataRow(t("discount"), voucherDetails?.amount)}
    </div>
  );

  function barDivider() {
    return <div className="w-full bg-cNmSelect h-[1px]"></div>;
  }

  function dataRow(dataName, dataDetails) {
    return (
      <div className="flex justify-between items-center pt-s10 px-5 capitalize">
        <div className="font-medium">{dataName}</div>
        <Clamp withTooltip lines={1}><div className="max-w-[300px]">{dataDetails}</div></Clamp>
      </div>
    );
  }
};

function VoucherDetailsTableItem({ item, index }) {
  return (
    <tr key={index} className="text-center text-fs16 font-fw400 border-b">
      <td className="border-r  py-s10">{item?.user_name}</td>
      <td className="border-r  py-s10">{item?.card_title}</td>
      <td className=" py-s10">
        {item?.ordered_at.slice(0, 10)}
      </td>
    </tr>
  )
}