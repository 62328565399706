// import { Switch } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CommonButton from "../Button/CommonButton";
import CommonButtonOutlined from "../Button/CommonButtonOutlined";
// import FiveStarRating from "../FiveStarRating";
import {
  EmailIconGray,
  LocationIconGray,
  NotVerified,
  NoUser,
  PhoneIconGray,
  Verified
} from "../Utility/ImageImports";

export default function CommonDetailsCard({
  mainImage,
  name = "Lorem",
  shopCode = "45sd2f5",
  email,
  phone,
  coinBalance = 0,
  address,
  ratingAvg,
  totalReview,
  isActive = false,
  showDelete = false,
  onDelete,
  onEdit,
  onDeactivate,
  isCustomer = false,
  styleClass,
  isVerified = false
}) {
  const { t } = useTranslation();


  return (
    <div
      className={`flex xl:flex-row flex-col xl:space-y-0 space-y-5 items-baseline justify-between w-full relative ${styleClass}`}
    >
      <div className="flex items-center">
        {/* left part */}
        <div className="min-w-[170px] min-h-[170px]">
          <img
            src={mainImage}
            alt=""
            className="mb-s10 w-s160 h-s160 object-cover rounded-full hover:rounded-br5 hover:drop-shadow-lg duration-500 transition-all"
            onError={(e) => { e.target.onerror = null; e.target.src = NoUser }}
          />

          {!isVerified ? isActive ? (
            <div className="text-fs14 flex justify-center pt-5 text-cSuccess">
              {t("Active")}
            </div>
          ) : (
            <div className="text-fs14 flex justify-center pt-5 text-cRed">
              {t("Deactivated")}
            </div>
          )
            :
            isVerified ? (
              <div className="text-fs14 flex justify-center pt-s7 text-cSuccess">
                <div className='flex ml-s10'><img className='mr-s5' src={Verified} alt="" /><span>{t("Verified")}</span></div>
              </div>
            ) : (
              <div className="text-fs14 flex justify-center pt-s7 text-cRed">
                <div className='flex ml-s10'><img className='mr-s5' src={NotVerified} alt="" /><span>  {t("Not Verified")}</span></div>
              </div>
            )}
        </div>

        {/* mid part */}
        <div className="pl-s80">

          <>
            {isCustomer ? <div className="px-2 py-1 rounded-full mb-4 bg-cCoinBalanceBG">
              <div className="text-center text-xs font-normal">Available Coins: {coinBalance}</div>
            </div> : ""}
            <div className="flex items-center">
              <div className="pr-5 text-[20px] font-bold">{name}</div>
            </div>

            <div className="flex items-center pt-5 text-cTextShopDetails">
              <div className="pr-[7px]">
                <img className="max-w-[20px] min-w-[20px]" src={EmailIconGray} alt="" />
              </div>
              <div className="opacity-70 text-fs16 font-medium">{email}</div>
            </div>

            <div className="flex items-center pt-5 text-cTextShopDetails">
              <div className="pr-[7px]">
                <img className="max-w-[20px] min-w-[20px]" src={PhoneIconGray} alt="" />
              </div>
              <div className="opacity-70 text-fs16 font-medium">{phone}</div>
            </div>

            <div className="flex items-center pt-5 text-cTextShopDetails">
              <div className="pr-[7px]">
                <img className="max-w-[20px] min-w-[20px]" src={LocationIconGray} alt="" />
              </div>
              <div className="opacity-70 text-fs16 font-medium">
                {address}
              </div>
            </div>
          </>

          {/* {!isCustomer ? (
            <>
              {isCustomer ? <div className="px-2 py-1 rounded-full mb-4 bg-cCoinBalanceBG">
                <div className="text-center text-xs font-normal">Available Coins: {123}</div>
              </div> : ""}
              <div className="flex items-center">
                <div className="pr-5 text-[20px] font-bold">{name}</div>
              </div>

              <div className="flex items-center pt-5 text-cTextShopDetails">
                <div className="pr-[7px]">
                  <img className="max-w-[20px] min-w-[20px]" src={EmailIconGray} alt="" />
                </div>
                <div className="opacity-70 text-fs16 font-medium">{email}</div>
              </div>

              <div className="flex items-center pt-5 text-cTextShopDetails">
                <div className="pr-[7px]">
                  <img className="max-w-[20px] min-w-[20px]" src={PhoneIconGray} alt="" />
                </div>
                <div className="opacity-70 text-fs16 font-medium">{phone}</div>
              </div>

              <div className="flex items-center pt-5 text-cTextShopDetails">
                <div className="pr-[7px]">
                  <img className="max-w-[20px] min-w-[20px]" src={LocationIconGray} alt="" />
                </div>
                <div className="opacity-70 text-fs16 font-medium">
                  {address}
                </div>
              </div>
            </>
          ) : (
            <>

              <div className="px-2 py-1 rounded-full mb-4 bg-cCoinBalanceBG">
                <div className="text-center text-xs font-normal">Available Coins: {123}</div>
              </div>

              <div className="flex items-center text-fs16 font-fw500">
                <div className="text-cIconColor pr-s5">{t("Name:")}</div>
                <div>{name}</div>
              </div>

              <div className="flex items-center text-fs14 font-fw500 py-5">
                <div className="text-cIconColor pr-s5">{t("Email:")}</div>
                <div>{email}</div>
              </div>

              <div className="flex items-center text-fs14 font-fw500">
                <div className="text-cIconColor pr-s5">{t("Phone:")}</div>
                <div>{phone}</div>
              </div>
              {isCustomer ?
                <div className="flex items-center pt-5 text-fs14 font-fw500">
                  <div className="text-cIconColor pr-s5">{t("Address:")}</div>
                  <div>{address}</div>
                </div> : ""}
            </>
          )} */}
        </div>
      </div>

      <div className={`${isCustomer ? "pb-5 pr-5" : "pb-0 pr-0"}`}>
        <div className="flex items-center">
          {showDelete ?
            <>
              <CommonButtonOutlined
                colorType="danger"
                btnLabel={t("delete")}
                onClick={onDelete}
              />
              <div className="pl-s30"></div>
            </> : ''
          }
          <CommonButton btnLabel={t("edit")} onClick={onEdit} />
        </div>
      </div>
    </div>
  );
}
