import React from 'react'
import useSettingsStore, { settingsUpdate } from '../../../../App/Stores/SettingsStore'
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonModal from '../../../../Components/Modal/CommonModal'

const OrderBonusStatus = () => {

    const { setShow_order_bonus_modal,show_order_bonus_modal,is_order_bonus,setIs_order_bonus } = useSettingsStore();

    return (
        <div>
            <CommonModal
                showModal={show_order_bonus_modal}
                setShowModal={setShow_order_bonus_modal}
                // widthClass="w-[45vw]"
                modalTitle="confirmation"

                mainContent={
                    <>
                        <div className='my-s20'>Do you want to Deactivate?</div>

                        <div className='flex justify-center'>
                            <CommonButton onClick={() => {
                                const success = settingsUpdate({type:"is_order_bonus",value:!is_order_bonus});
                                if (success) { setIs_order_bonus(0); setShow_order_bonus_modal(false) }
                            }} btnLabel='Deactivate' />
                        </div>
                    </>
                }
            />
        </div>
    )
}

export default OrderBonusStatus