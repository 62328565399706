import React from "react";
import { useTranslation } from "react-i18next";
import useSettingsStore, { settingsUpdate } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";

const BonusLimit = () => {

  const { t } = useTranslation();

  const { orderBonusLimitValue, setOrderBonusLimitValue } = useSettingsStore();

  const handleSubmit = () => {
    if (orderBonusLimitValue) {
      settingsUpdate({type:"bonus_order_limit",value:orderBonusLimitValue});
    }
  }
  return (
    <form onSubmit={(e) => e.preventDefault()} className="w-full">
      <div className="pb-5 text-fs20 font-fw600">{t("Bonus Order Limit")}</div>

      <div className="p-5 bg-white shadow-md rounded-br5">
        <CommonInput
          min_input={0}
          className="mb-0"
          no_label={true}
          placeholder={t("Enter amount..")}
          type="number"
          required={true}
          min_number={1}
          value={orderBonusLimitValue}
          onChange={(e) => setOrderBonusLimitValue(e.target.value)}
        />

        <div className="pt-5">
          <CommonButton type="submit" btnLabel={t("Save Changes")} onClick={handleSubmit} colorType="primary" />
        </div>
      </div>
    </form>
  );
};

export default BonusLimit;
