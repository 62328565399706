import React from "react";
import { useTranslation } from "react-i18next";
import useSettingsStore, { settingsUpdate } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";

const OrderBonusPercentage = () => {

  const { t } = useTranslation();

  const { order_user_point, setOrder_user_point } = useSettingsStore();

  const handleSubmit = (e) => {
    e.preventDefault()
    if (order_user_point) {
      settingsUpdate({ type: "user_order_bonus", value: order_user_point });
    }
  }
  return (
    <form onSubmit={handleSubmit} className="w-full">
      <div className="pb-5 text-fs20 font-fw600">{t("Order Bonus Percentage")}</div>

      <div className="p-5 bg-white shadow-md rounded-br5">
        <CommonInput
          max_number={100}
          min_input={0}
          className="mb-0"
          no_label={true}
          placeholder={t("Enter amount..")}
          type="number"
          required={true}
          min_number={1}
          value={order_user_point}
          onChange={(e) => setOrder_user_point(e.target.value)}
        />

        <div className="pt-5">
          <CommonButton type="submit" btnLabel={t("Save Changes")} colorType="primary" />
        </div>
      </div>
    </form>
  );
};

export default OrderBonusPercentage;
