import React, { useEffect, useState } from 'react'
import CommonLabel from '../Label/CommonLabel'

const DefaultSelect = ({
    value = "",
    colorType = "base",                 //base, danger
    placeholder = "Select an item",
    onChange = () => { },
    label = "",
    withPlaceholder = true,
    required = false,
    withLabel = false,
    data = [
        { label: "okk", value: 1 },
        { label: "not", value: 0 },
    ]
}) => {
    const [dataSelect, setDataSelect] = useState(data);
    const [selectValue, setSelectValue] = useState(value);

    useEffect(() => {
        setDataSelect(data)
    }, [data]);

    useEffect(() => {
        setSelectValue(value);
    }, [value]);

    return (
        <>
            {withLabel && <CommonLabel labelText={label} required={required} />}
            <select
                value={selectValue}
                onChange={onChange}
                required={required}
                className={`capitalize py-2 min-w-[120px] px-2 w-full border ${colorType === "danger" ? "border-cBrand outline-cBrand text-cBrand" : "border-gray-300"} rounded-md bg-white`}
            >
                {withPlaceholder && <option
                    className='py-2 h-10'
                >{placeholder}</option>}
                {dataSelect?.map((item, index) => (
                    <option
                        key={index}
                        value={item?.value}
                        className='py-2 h-10 my-4 capitalize border-y'
                    >{item?.label}</option>
                ))}
            </select>
        </>
    )
}

export default DefaultSelect