/* eslint-disable react-hooks/exhaustive-deps */
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import useAuthStore from '../../App/Stores/authStore';
import { changeLanguage } from '../../App/Stores/SettingsStore';

const LanguageSwitch = () => {
    const { isLoggedIn } = useAuthStore();

    const [currentLang, setCurrentLang] = useState(null);

    const lan = localStorage.getItem("lang_code");

    const languageOnChange = (code) => {
        localStorage.setItem("lang_code", code);
        if (isLoggedIn) {
            const success = changeLanguage(code);
            if (success) {
                i18next.changeLanguage(code);
                setCurrentLang(code);
            }
        }
        else {
            i18next.changeLanguage(code);
            setCurrentLang(code);
        }
    }

    useEffect(() => {
        const code = localStorage.getItem("lang_code");
        // console.log('code', code);
        
        if (isLoggedIn) {
            const success = changeLanguage(code);
            if (success) {
                i18next.changeLanguage(code);
                setCurrentLang(code);
            }
        }
        else {
            i18next.changeLanguage(code);
            setCurrentLang(code);
        }
    }, [isLoggedIn, lan])


    useEffect(() => {
        const code = localStorage.getItem("lang_code");
        // console.log('code', code);
        
        if (isLoggedIn) {
            const success = changeLanguage(code);
            if (success) {
                i18next.changeLanguage(code);
                setCurrentLang(code);
            }
        }
        else {
            i18next.changeLanguage(code);
            setCurrentLang(code);
        }
    }, []);


    return (
        <div className="relative cursor-pointer select-none font-archivo text-md">
            <select value={currentLang} onChange={(e) => { languageOnChange(e.target.value) }} className='px-2 py-2 border-2 border-gray-900 rounded-md text-md font-archivo'>
                <option value={'en'}>English</option>
                <option value={'ja'}>日本語</option>
                <option value={'zh'}>中文</option>
                <option value={'bn'}>বাংলা</option>
            </select>
        </div>
    );
}

export default LanguageSwitch;