import React, { useState } from "react";
import useContactStore, { contactReply } from "../../App/Stores/ContactStore";
import { isOnlyWhitespace } from "../../App/Utility/UtilityFunctions";
import CommonButton from "../../Components/Button/CommonButton";
import Image from "../../Components/Image/Image";
import CommonInput from "../../Components/Input/CommonInput";
import CommonLabel from "../../Components/Label/CommonLabel";
import CommonModal from "../../Components/Modal/CommonModal";

export default function ContactReply({ data }) {
  const { showContactMessageDetails, setShowContactMessageDetails, contactDetails } = useContactStore();

  const [replyForm, setReplyForm] = useState({
    id: 0,
    reply: "",
  });

  return (
    <>
      <CommonModal
        showModal={showContactMessageDetails}
        setShowModal={setShowContactMessageDetails}
        // widthClass="w-[45vw]"
        modalTitle="Message Reply"
        // singleButton={
        //   <>

        //   </>
        // }
        mainContent={
          <div onClick={() => { console.log("contactDetails data:::::", contactDetails); }} className="pt-5">
            <div className="bg-cVerify rounded-br5 flex py-5 mb-5 pl-5 items-center">
              <Image src={contactDetails?.user?.image_url} className="h-s80 w-s80 rounded-full object-cover" />

              <div className="pl-s10">
                <div className="text-fs18 font-semibold pb-s10">{contactDetails?.name ?? "NA"}</div>
                <div className="text-fs14">{contactDetails?.email ?? "NA"}</div>
              </div>
            </div>
            <div className="flex pb-5">
              <div className="text-cInputLabel font-fw500 text-fs16">
                Subject:
              </div>
              <div className="pl-s10">{contactDetails?.subject}</div>
            </div>
            <div className="w-full pb-5">
              <div className="text-cInputLabel font-fw500 text-fs16 pb-s10">
                Message
              </div>
              <div>
                {contactDetails?.message}
              </div>
            </div>
            <div className="pb-5">
              <hr />
            </div>
            {contactDetails?.replay ?
              <div>
                <CommonLabel labelText="Reply" />

                <div>{contactDetails?.replay}</div>
              </div>
              :
              <form onSubmit={(e) => e.preventDefault()} className="w-full">
                <CommonInput required={true} textarea={true} label="Reply" value={replyForm?.reply} onChange={(e) => setReplyForm({ ...replyForm, reply: e.target.value })} />
                <div className="flex justify-center">
                  <CommonButton
                    btnLabel="Send"
                    type="submit"
                    colorType="primary"
                    onClick={async () => {
                      if (!isOnlyWhitespace(replyForm?.reply)) {
                        console.log("replyForm ::::", { id: contactDetails?.id, reply: replyForm?.reply });
                        let replySuccess = contactReply({ id: contactDetails?.id, reply: replyForm?.reply });
                        if (replySuccess) setReplyForm({});
                      }
                    }}
                  />
                </div>
              </form>}
          </div>
        }
      />
    </>
  );
}
