import axios from "axios";
import create from "zustand";
import { allFeesUrl, changeLanguageUrl, changePasswordUrl, getGeneralInfoUpdateUrl, getGeneralInfoUrl, getProfileUrl, updateFeesUrl, updateProfileUrl, updateRecommendedCategoryUrl } from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import { getAllCategory } from "./CategoryStore";
import UseTestStore from "./TestStore";
import useUtilityStore from "./UtilityStore";

const { setLoading } = useUtilityStore.getState();

const useSettingsStore = create((set) => ({

  userData: {
    name: "",
    email: "",
    phone: "",
    image_url: "",
  },
  setUserData: (e) => set((state) => (state.userData[e.target.name] = e.target.value)),

  finalUpdateUserData: {},
  setFinalUpdateUserData: (value) => set({ finalUpdateUserData: value }),

  dataWithImage: false,
  setDataWithImage: (value) => set({ dataWithImage: value }),

  privacyEditState: false,
  setPrivacyEditState: (value) => set({ privacyEditState: value }),

  termsEditState: false,
  setTermsEditState: (value) => set({ termsEditState: value }),

  authFee: 0,
  setAuthFee: (value) => set({ authFee: value }),

  coinValue: 0,
  setCoinValue: (value) => set({ coinValue: value }),

  referralPoint: 0,
  setReferralPoint: (value) => set({ referralPoint: value }),

  shippingFee: 0,
  setShippingFee: (value) => set({ shippingFee: value }),

  is_referral: 0,
  setIs_referral: (value) => set({ is_referral: value }),

  showReferralModal: false,
  setShowReferralModal: (value) => set({ showReferralModal: value }),

  generalInfo: "",
  setGeneralInfo: (value) => set({ generalInfo: value }),

  editTermsCondition: { type: 'terms-and-conditions', info: "", lang: "" },
  setEditTermsCondition: (value) => set({ editTermsCondition: value }),

  editPrivacyPolicy: { type: 'privacy-policy', info: "", lang: "" },
  setEditPrivacyPolicy: (value) => set({ editPrivacyPolicy: value }),

  editAboutUs: { type: 'about-us', info: "", lang: "" },
  setEditAboutUs: (value) => set({ editAboutUs: value }),

  editDeleteMessage: { type: 'delete-message', info: "", lang: "" },
  setEditDeleteMessage: (value) => set({ editDeleteMessage: value }),

  termsData: "",
  setTermsData: (value) => set({ termsData: value }),

  selectedOption: "",
  setSelectedOption: (option) => set((state) => (state.selectedOption = option)),

  order_user_point: "",
  setOrder_user_point: (value) => set({ order_user_point: value }),

  orderBonusLimitValue: "",
  setOrderBonusLimitValue: (value) => set({ orderBonusLimitValue: value }),

  taxPercentage: "",
  setTaxPercentage: (value) => set({ taxPercentage: value }),

  order_refer_user_point: "",
  setOrder_refer_user_point: (value) => set({ order_refer_user_point: value }),

  is_order_bonus: "",
  setIs_order_bonus: (option) => set((state) => (state.is_order_bonus = option)),

  show_order_bonus_modal: false,
  setShow_order_bonus_modal: (option) => set((state) => (state.show_order_bonus_modal = option)),

  changePasswordForm: { old_password: '', password: '', password_confirmation: '' },
  setChangePasswordForm: (e) => set((state) => (state.changePasswordForm[e.target.name] = e.target.value)),
  resetChangePasswordForm: (e) => set((state) => (state.changePasswordForm = { old_password: '', password: '', password_confirmation: '' })),

}));

export default useSettingsStore;


// ====================================================
//          API Fn
// ====================================================


// green: get general info[privacy-policy, terms-and-conditions, about-us]
export const getGeneralInfo = async (type = 'privacy-policy') => {

  const { setEditDeleteMessage, editDeleteMessage, setEditAboutUs, editAboutUs, setEditPrivacyPolicy, setEditTermsCondition, setGeneralInfo, editTermsCondition, editPrivacyPolicy } = useSettingsStore.getState();

  try {
    setLoading(true);

    let url = "";

    if (type === 'privacy-policy') {
      url = getGeneralInfoUrl + '?type=' + type + `&lang=` + editPrivacyPolicy?.lang
    } else if (type === 'terms-and-conditions') {
      url = getGeneralInfoUrl + '?type=' + type + `&lang=` + editTermsCondition?.lang
    } else if (type === "about-us") {
      url = getGeneralInfoUrl + '?type=' + type + `&lang=` + editAboutUs?.lang
    } else if (type === "delete-message") {
      url = getGeneralInfoUrl + '?type=' + type + `&lang=` + editDeleteMessage?.lang
    } else {
      url = getGeneralInfoUrl + '?type=' + type
    }

    const res = await axios.get(url);

    console.log("getGeneralInfo res.data:::: ", res.data);

    if (res.data.success) {
      setGeneralInfo(res?.data?.data?.info ?? '')
      if (type === 'terms-and-conditions') { setEditTermsCondition({ ...editTermsCondition, info: res?.data?.data?.info ?? '', lang: res?.data?.data?.lang ?? '' }); }
      if (type === 'privacy-policy') { setEditPrivacyPolicy({ ...editPrivacyPolicy, info: res?.data?.data?.info ?? '', lang: res?.data?.data?.lang ?? '' }) }
      if (type === "about-us") { setEditAboutUs({ ...editAboutUs, info: res?.data?.data?.info ?? '', lang: res?.data?.data?.lang ?? '' }) }
      if (type === "delete-message") { setEditDeleteMessage({ ...editDeleteMessage, info: res?.data?.data?.info ?? '', lang: res?.data?.data?.lang ?? '' }) }
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getGeneralInfo: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// green: get all shop list
export const getAllFees = async (paginationUrl = "") => {

  const { setOrder_user_point, setReferralPoint, setCoinValue, setAuthFee, setShippingFee, setIs_referral, setOrderBonusLimitValue, setTaxPercentage, setIs_order_bonus, setOrder_refer_user_point } = useSettingsStore.getState();

  try {
    setLoading(true);

    const res = await axios.get(allFeesUrl);

    console.log("getALlFees res.data:::: ", res.data.data);

    if (res.data.success) {
      setAuthFee(res?.data?.data?.auth_fee?.value)
      setShippingFee(res?.data?.data?.ship_fee?.value)
      setReferralPoint(res?.data?.data?.referral_point?.value);
      setCoinValue(res?.data?.data?.coin_value?.value);
      setIs_referral(res?.data?.data?.is_referral?.value);

      setIs_order_bonus(res?.data?.data?.is_order_bonus?.value);
      setOrder_refer_user_point(res?.data?.data?.order_refer_user_point?.value);
      setOrderBonusLimitValue(res?.data?.data?.order_bonus_limit?.value);
      setTaxPercentage(res?.data?.data?.tax_percentage?.value);
      setOrder_user_point(res?.data?.data?.order_user_point?.value)

      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getAllFees: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};


// blue: update shipping fee
export const updateShippingFee = async (urlToLoad = "url...") => {
  try {
    setLoading(true);

    const res = await axios.post(
      updateFeesUrl,
      {
        type: "ship_fee",
        value: useSettingsStore.getState().shippingFee,
      }
    );

    console.log("updateShippingFee::::", res.data);

    if (res.data.success) {
      getAllFees();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("updateShippingFee: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


// blue: update Auth fee
export const settingsUpdate = async (body) => {

  try {
    setLoading(true);

    const res = await axios.post(updateFeesUrl, body);

    console.log("updateAuthFee::::", res.data);

    if (res.data.success) {
      getAllFees();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("updateAuthFee: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


// blue: update Auth fee
export const updateRecommendedCategory = async (catID) => {
  try {
    setLoading(true);

    const res = await axios.post(
      updateRecommendedCategoryUrl,
      {
        category_id: catID
      }
    );

    console.log("updateRecommendedCategory::::", res.data);

    if (res.data.success) {
      getAllCategory();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("updateRecommendedCategory: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};




// green: update general info[privacy-policy, terms-and-conditions, about-us]
export const updateGeneralInfo = async (type = "privacy-policy") => {

  const { editDeleteMessage, editAboutUs, editTermsCondition, generalInfo, editPrivacyPolicy } = useSettingsStore.getState();
  try {
    setLoading(true);
    console.log('type:::', type);

    let body = {}

    if (type === "terms-and-conditions") {
      body = { type: editTermsCondition?.type, info: editTermsCondition?.info, lang: editTermsCondition?.lang }
    } else if (type === "privacy-policy") {
      body = { type: editPrivacyPolicy?.type, info: editPrivacyPolicy?.info, lang: editPrivacyPolicy?.lang }
    } else if (type === "about-us") {
      body = { type: editAboutUs?.type, info: editAboutUs?.info, lang: editAboutUs?.lang }
    } else if (type === "delete-message") {
      body = { type: editDeleteMessage?.type, info: editDeleteMessage?.info, lang: editDeleteMessage?.lang }
    } else { body = { type: type, info: generalInfo } }

    const res = await axios.post(getGeneralInfoUpdateUrl, body);

    console.log("updateGeneralInfo res.data:::: ", res.data);

    if (res.data.success) {
      getGeneralInfo(type)
      useSettingsStore.getState().setGeneralInfo(res?.data?.data?.info ?? '');
      useSettingsStore.getState().setPrivacyEditState(false);
      useSettingsStore.getState().setTermsEditState(false);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("updateGeneralInfo: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// green: get profile data 
export const getProfileData = async () => {
  try {
    setLoading(true);

    const res = await axios.get(getProfileUrl);

    // console.log("getProfileData res.data:::: ", res.data.data);

    if (res.data.success) {
      // useSettingsStore.getState().setTermsData(res.data.data)
      useUtilityStore.getState().setLoggedUser(res.data.data);
      localStorage.setItem("user", JSON.stringify(res.data.data));
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getProfileData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// green: update Profile 
export const updateProfileData = async () => {
  try {
    setLoading(true);
    const { userData, dataWithImage } = useSettingsStore.getState();
    let res = {};
    if (dataWithImage) {
      res = await axios.post(updateProfileUrl, userData);
    } else {
      res = await axios.post(updateProfileUrl, {
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
      });
    }

    console.log("updateProfileData res.data:::: ", res.data);

    if (res.data.success) {
      getProfileData()
      // useSettingsStore.getState().setProfileData(res.data.data)
      setLoading(false);
      return true
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false
    }

  } catch (error) {
    console.log("updateProfileData: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false
  }
};

export const changePassword = async () => {
  try {
    const { changePasswordForm } = useSettingsStore.getState();
    if (changePasswordForm.password !== changePasswordForm.password_confirmation) {
      Toastr({ message: "Password and confirm password do not match!", type: "error" });
      return false;
    }

    setLoading(true);

    const res = await axios.post(changePasswordUrl, changePasswordForm);

    console.log("changePassword res.data:::: ", res.data.data);

    if (res.data.success) {
      UseTestStore.getState().setShowEditPassModal(false)
      useSettingsStore.getState().resetChangePasswordForm();
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("changePassword: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

//  change Language
export const changeLanguage = async (code = "en") => {
  try {
    setLoading(true);

    const res = await axios.post(changeLanguageUrl, {
      code: code,
    });

    console.log("changeLanguage res.data:::: ", res.data.data);

    if (res.data.success) {
      await getProfileData();
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("changeLanguage: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};