import ShopTableItem from "./ShopTableItem";
import useShopStore, {
  getAllShop,
  searchShops,
} from "../../../App/Stores/ShopStore";
import AddShop from "../Popup/AddShop";
import CommonTable from "../../../Components/Table/CommonTable";
import DeactivateShop from "../Popup/DeactivateShop";
import { useDebounce } from "use-debounce";
import { useEffect } from "react";
import NoDataRow from "../../../Components/Table/NoDataRow";
import useUtilityStore from "../../../App/Stores/UtilityStore";


const ShopTable = ({ title, headers = [], isImage, tableBtnTitle }) => {
  const {
    setAddingNewShop,
    searchKey,
    shopListAll,
    shopListPagination,
    setShopPaginationUrl,
    setSearchKey, shopPaginationUrl,shopTakeAmount
  } = useShopStore();

  const [searchValue] = useDebounce(searchKey, 500);

  useEffect(() => {
    if (searchValue) {
      searchShops(searchValue, shopPaginationUrl);
    } else {
      if (shopListPagination.current_page) {
        let urlToLoad = shopListPagination.path + "?page=" + shopListPagination.current_page;
        // console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
        getAllShop(urlToLoad);
      } else {
        getAllShop(shopPaginationUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => setSearchKey(""), []);


  return (
    <>
      <CommonTable
        takeComponent={<TakeItem />}
        headers={headers}
        addItemTitle={tableBtnTitle}
        showingFrom={shopListPagination.from}
        showingTo={shopListPagination.to}
        totalResult={shopListPagination.total}
        items={
          shopListAll.length > 0
            ? shopListAll.map((item, index) => (
              <ShopTableItem
                key={index}
                barIndex={index}
                end={shopListAll.length - 1}
                tableData={item}
              />
            ))
            : <NoDataRow columnNumber={headers?.length} />
        }
        addItemModal={
          <>
            <AddShop />
          </>
        }
        paginationObject={shopListPagination}
        addItemFunction={setAddingNewShop}
        searchValue={searchKey}
        searchOnChange={(e) => setSearchKey(e.target.value)}
        pagesArray={shopListPagination.links}
        currentPageNumber={shopListPagination.current_page}
        onSearchClear={async () => {
          await setSearchKey("");
          await shopPaginationUrl("")
          await getAllShop();
        }}
        showPagination={
          shopListPagination?.last_page !== 1 ? true : false
        }
        prevPageUrl={shopListPagination.prev_page_url}
        nextPageUrl={shopListPagination.next_page_url}
        paginationOnClick={(url) => {
          setShopPaginationUrl(url)
          // console.log("url::::", url);
          if (searchValue) {
            searchShops(searchValue, url);
          } else{ getAllShop(url,shopTakeAmount.take);}
        }}
      />
      <DeactivateShop />
    </>
  );
};
 
export default ShopTable;


const TakeItem = () => {
  const {
    shopTakeAmount,
    setShopTakeAmount,
    searchKey,
    shopPaginationUrl,
    setShopPaginationUrl
  } = useShopStore();

  const { setLoading } = useUtilityStore.getState();

  return (
    <div className=''>
      <span className='pr-s10'>Show</span>
      <select id="cars"
        value={shopTakeAmount.take ? shopTakeAmount.take : 10}
        onChange={async (e) => {
          setLoading(true);
          await setShopTakeAmount("take", e.target.value);
          await setShopPaginationUrl("")
          if (searchKey) {
            await searchShops(searchKey, "");
          } else {
            await getAllShop('',e.target.value);
          }
          await setLoading(false)
        }}
        className='pl-2 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={shopTakeAmount.take === 10}
          value={10}
        >10</option>

        <option
          className='py-s10 text-cHighlightedTexts'
          selected={shopTakeAmount.take === 25}
          value={25}
        >25</option>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={shopTakeAmount.take === 50}
          value={50}
        >50</option>
        <option
          className='py-s10 text-cMainBlack'
          selected={shopTakeAmount.take === 100}
          value={100}
        >100</option>
      </select>   <span className='pl-s10'>Entries</span>
    </div>
  )
}
