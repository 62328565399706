import { Tooltip } from "@mui/material";
import React from "react";
import useContactStore, { getContactDetails } from "../../App/Stores/ContactStore";
import { formatDate } from "../../App/Utility/UtilityFunctions";
import Image from "../../Components/Image/Image";

export default function ContactTableRow({ data, index, end }) {
  const { setShowContactMessageDetails } = useContactStore();

  return (
    <tr
      onClick={() => {
        console.log(data);
        getContactDetails(data?.id);
        setShowContactMessageDetails(true);
      }}
      className="cursor-pointer border-b border-collapse bg-white hover:bg-cBandHover"
    >
      <td className="border-r text-left pl-5 py-s10 min-w-[200px] max-w-[200px] truncate relative">
        {
          data?.is_seen === 0 ?
            <div div className="absolute -ml-s15 top-[50%] -translate-y-[50%] h-s10 w-s10 rounded-full bg-cBrandDark"></div>
            : data?.is_replied === 0 ?
              <div div className="absolute -ml-s15 top-[50%] -translate-y-[50%] h-s10 w-s10 rounded-full bg-gray-300"></div>
              : ""
        }

        <div className="flex items-center">
          <Image
            className="h-8 w-8 rounded-full"
            src={data?.image_url}
            alt=""
          />
           {data?.message ? <Tooltip title={data?.name}><span className="max-w-[150px] min-w-[150px] truncate pl-2.5">{data?.name}</span></Tooltip> : "NA"}
        </div>
      </td >
      <td className="border-r text-center p-s10 min-w-[180px] max-w-[180px] truncate">
        {data?.email ? <Tooltip title={data?.email}><span>{data?.email}</span></Tooltip> : "NA"}
      </td>

      <td className="border-r text-center p-s10 min-w-[190px] max-w-[190px] truncate">
        {data?.message ? <Tooltip title={data?.message}><span>{data?.message}</span></Tooltip> : "NA"}
      </td>
      <td className="border-r text-center p-s10 min-w-[120px]  max-w-[120px]">{data?.created_at ? formatDate(data?.created_at) : "NA"}</td>
      <td className="border-r text-center p-s10 min-w-[120px] max-w-[120px]">{data?.updated_at ? formatDate(data?.updated_at) : "NA"}</td>
    </tr >
  );
}
