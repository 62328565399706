import { alpha, styled, Switch } from "@mui/material";
import create from "zustand";

const useUtilityStore = create((set) => ({
  loggedUser: {
    name: "",
    email: "",
  },
  setLoggedUser: (value) => set({ loggedUser: value }),

  isLoading: false,
  setLoading: (status) => set((state) => (state.isLoading = status)),

  showEditProfileModal: false,
  setShowEditProfileModal: (value) => set({ showEditProfileAccordion: value }),

  showEditAccordion: false,
  setShowEditAccordion: (value) => set({ showEditAccordion: value }),

  isLoadingSearch: false,
  setLoadingSearch: (status) => set((state) => (state.isLoadingSearch = status)),

  commonDropDownSelectedItem: false,
  setCommonDropDownSelectedItem: (status) => set((state) => (state.commonDropDownSelectedItem = status)),

  commonSelectItem: false,
  setCommonSelectItem: (status) => set((state) => (state.commonSelectItem = status)),

}));

export default useUtilityStore;


export const MuiCustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#FB607F',
    '&:hover': {
      backgroundColor: alpha('#FB607F', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#FB607F',
  },
}));