import React from "react";
import { useTranslation } from "react-i18next";
import useSettingsStore, { settingsUpdate } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";

const Coin = () => {
  const { t } = useTranslation();

  const { coinValue, setCoinValue } = useSettingsStore();

  const handleSubmit = () => {
    if (coinValue) {
        settingsUpdate({type:"coin_value",value:coinValue});
    }
  }
  return (
    <form onSubmit={(e) => e.preventDefault()}  className="w-full">
      <div className="pb-5 text-fs20 font-fw600">{t("Set Coin")}</div>

      <div className="p-5 bg-white shadow-md rounded-br5">
        <CommonInput
          className="mb-0"
          no_label={true}
          placeholder={t("Enter amount..")}
          type="number"
          required={true}
          value={coinValue ?? 0}
          min_number={1}
          onChange={(e) => setCoinValue(e.target.value)}
        />

        <div className="pt-5">
          <CommonButton type="submit" onClick={handleSubmit} btnLabel={t("Save Changes")} colorType="primary" />
        </div>
      </div>
    </form>
  );
};

export default Coin;