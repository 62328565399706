import React from 'react';
import ContactReply from '../../Pages/ContactMessage/ContactReply';
import FilterContactMessage from '../../Pages/ContactMessage/FilterContactMessage';
import NotificationDetailsModal from '../../Pages/Notification/NotificationDetailsModal';
import DownloadInvoiceModal from '../../Pages/Order/DownloadInvoiceModal';
import ReferralModal from '../../Pages/Settings/Details/modal/ReferralModal';
import RejectRequestModal from '../../Pages/VerifyIdentity/Modal/RejectRequestModal';
import VerificationDetailsModal from '../../Pages/VerifyIdentity/Modal/VerificationDetailsModal';
import VerificationModal from '../../Pages/VerifyIdentity/Modal/VerificationModal';
import OrderBonusStatus from '../../Pages/Settings/Details/modal/OrderBonusStatus';

const CommonModalArea = () => {
    return (
        <div>
            <VerificationDetailsModal />
            <VerificationModal />
            <RejectRequestModal />

            {/* order ui modals */}
            <DownloadInvoiceModal />

            {/* contact message reply */}
            <ContactReply />
            <FilterContactMessage />

            {/* notification details */}
            <NotificationDetailsModal />

            {/* settings Modal */}
            <ReferralModal />
            <OrderBonusStatus/>

        </div>
    );
};

export default CommonModalArea;