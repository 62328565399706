import { FormControlLabel, Radio } from "@mui/material";
import i18next from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useUpdateLogger from "../../../App/Hooks/useUpdateLogger";
import useLayoutStore from "../../../App/Stores/LayoutStore";
import { changeLanguage } from "../../../App/Stores/SettingsStore";
import useUtilityStore from "../../../App/Stores/UtilityStore";
import { PageTitle } from "../../../App/Utility/UtilityFunctions";

const LanguageDetails = () => {
  const { loggedUser } = useUtilityStore();
  const { t } = useTranslation()
  const { setBarTitle } = useLayoutStore();

  useEffect(() => {
    // set the title of page and top bar
    setBarTitle(t("Settings"));
    PageTitle(t("Settings"));


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateLogger("loggedUser", loggedUser);
  return (
    <div className="w-full">
      <div className="text-fs20 font-fw600">{t("Language Setup")}</div>

      {/* green: options for language */}
      <div className="my-5">
        <LanguageOption onChange={() => {localStorage.setItem("lang_code", "en"); changeLanguage('en'); i18next.changeLanguage('en'); }} checked={loggedUser?.lang_code === 'en'} className="my-s10" langTitle={"English"} />
        <LanguageOption onChange={() => {localStorage.setItem("lang_code", "ja"); changeLanguage('ja'); i18next.changeLanguage('ja'); }} checked={loggedUser?.lang_code === 'ja'} className="my-s10" langTitle={"日本語"} />
        <LanguageOption onChange={() => {localStorage.setItem("lang_code", "bn"); changeLanguage('bn'); i18next.changeLanguage('bn'); }} checked={loggedUser?.lang_code === 'bn'} className="my-s10" langTitle={"বাংলা"} />
        <LanguageOption onChange={() => {localStorage.setItem("lang_code", "zh"); changeLanguage('zh'); i18next.changeLanguage('zh'); }} checked={loggedUser?.lang_code === 'zh'} className="my-s10" langTitle={"中文"} />
      </div>
    </div>
  );
};

export default LanguageDetails;

const LanguageOption = ({ langTitle, className, checked, onChange }) => {
  return (
    <label
      htmlFor={langTitle}
      className={`bg-white p-5 rounded-br5 shadow-md flex items-center justify-between ${className}`}
    >
      <div>{langTitle}</div>
      <div>       
        <FormControlLabel
          value={langTitle}
          control={
            <Radio
              checked={checked}
              onChange={onChange}

              sx={{
                '&, &.Mui-checked': {
                  color: '#FB607F',
                },
              }}
            />
          }
        />
      </div>
    </label>
  );
};

