import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useCustomerStore, { saveNewCustomer } from "../../../App/Stores/CustomerStore";
import { Toastr } from "../../../App/Utility/UtilityFunctions";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import ImageUpload from "../../../Components/Input/ImageUpload";
// import InputImage from "../../../Components/Input/InputImage";
import CommonModal from "../../../Components/Modal/CommonModal";

const AddCustomer = () => {
  const { t } = useTranslation();

  const [passMatch, setPassMatch] = useState(false);
  const { addingNewCustomer, setAddingNewCustomer, customerFormData, setCustomerFormData, customerListPagination } = useCustomerStore();

  const HandleSubmit = () => {
    let toPage = customerListPagination.last_page;
    if (
      customerListPagination.per_page * customerListPagination.last_page ===
      customerListPagination.total
    ) {
      toPage += 1;
    }
    let loadUrl = customerListPagination.path + "?page=" + toPage;
    console.log(loadUrl);




    if (
      customerFormData.name &&
      customerFormData.email &&
      customerFormData.phone &&
      customerFormData.password &&
      customerFormData.password.length >= 6 &&
      customerFormData.password_confirmation &&
      customerFormData.password_confirmation.length >= 6
    ) {

      if (customerFormData.password !== customerFormData.password_confirmation) {
        setPassMatch(true);
        return Toastr({ message: t("Password and Confirm Password should be Same!"), type: "error" });
      }

      let saveSuccess = saveNewCustomer(loadUrl)
      if (saveSuccess) {
        setAddingNewCustomer(false);
      }
    }
  }
  return (
    <>
      <CommonModal
        withExtraPadding={true}
        showModal={addingNewCustomer}
        setShowModal={setAddingNewCustomer}
        
        modalTitle={t("Add Customer")}
        mainContent={
          <>
            <form onSubmit={(e) => e.preventDefault()} className="pt-s20 bg-white">
              <div className="flex justify-center items-center pb-s25">
                <div className="w-full">
                  <ImageUpload
                    customWidth={"w-s100"}
                    CustomStyles={"mx-s30"}
                    onChangeFile={(e) => {
                      let imgEvent = {
                        target: {
                          name: "image_url",
                          value: e
                        }
                      }
                      setCustomerFormData(imgEvent);
                    }}
                  />
                </div>
              </div>
              <div className="space-y-5">
                <CommonInput label={t("Name")} placeholder={t('Customer name')} name="name" onChange={setCustomerFormData} value={customerFormData?.name} required={true} />
                <div className="flex items-center">
                  <div className="w-full">
                    <CommonInput label={t("Email")} type="email" name={"email"} onChange={setCustomerFormData} value={customerFormData?.email} required={true} placeholder={t('Customer email')} />
                  </div>
                  <div className="p-s10"></div>
                  <div className="w-full">
                    <CommonInput label={t("Phone")} placeholder={t('Customer phone')} type="number" max_input="15" name={"phone"} onChange={setCustomerFormData} value={customerFormData?.phone} required={true} />
                  </div>
                </div>
                <CommonInput label={t("Address")} name={"address"} onChange={setCustomerFormData} value={customerFormData?.address} placeholder={t('Customer address')} />
                <div className="flex items-center">
                  <div className="w-full">
                    <CommonInput min_input={6} label={t("Password")} type="password" togglePasswordBtn={true} warningBorder={passMatch} name={"password"} onChange={setCustomerFormData} value={customerFormData?.password} required={true} placeholder={t('New password')} />
                  </div>
                  <div className="p-s10"></div>
                  <div className="w-full">
                    <CommonInput min_input={6} label={t("Confirm Password")} type="password" togglePasswordBtn={true} warningBorder={passMatch} name={"password_confirmation"} onChange={setCustomerFormData} value={customerFormData?.password_confirmation} required={true} placeholder={t('Retype password')} />
                  </div>
                </div>
              </div>
              <div className="flex justify-center pt-5">
                <CommonButton
                  btnLabel={t("Save")}
                  colorType="primary"
                  type="submit"
                  onClick={() => HandleSubmit()}
                />
              </div>
            </form>
          </>
        }
      />
    </>
  );
};

export default AddCustomer;
