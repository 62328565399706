import axios from "axios";
import create from "zustand";
import { contactReplyUrl, getContactIndexUrl, getContactShowUrl } from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import useUtilityStore from "./UtilityStore";

const { setLoading, setLoadingSearch } = useUtilityStore.getState();

const useContactStore = create((set) => ({

  showContactMessageDetails: false,
  setShowContactMessageDetails: (value) => set({ showContactMessageDetails: value }),

  showFilterMessage: false,
  setShowFilterMessage: (value) => set({ showFilterMessage: value }),

  isFilterMessage: false,
  setIsFilterMessage: (value) => set({ isFilterMessage: value }),

  customDateShow: false,
  setCustomDateShow: (value) => set({ customDateShow: value }),

  contactFilterForm: {
    status: "",
    is_reply: "",
    is_seen: "",
    date: "",
    custom_date: "",
    start_date: "",
    end_date: "",
  },
  setContactFilterForm: (value) => set({ contactFilterForm: value }),

  contactTakeAmount: 10,
  setContactTakeAmount: (value) => set({ contactTakeAmount: value }),

  contactSearchKey: "",
  setContactSearchKey: (value) => set({ contactSearchKey: value }),

  contactPagination: "",
  setContactPagination: (value) => set({ contactPagination: value }),

  contactIndex: {},
  setContactIndex: (value) => set({ contactIndex: value }),

  contactDetails: {},
  setContactDetails: (value) => set({ contactDetails: value }),

}));

export default useContactStore;


// get contact index, search, filter    
export const getContactIndex = async (paginationUrl = "", searchKey = "", filterForm = {}, showSearchLoading = false) => {
  const { setContactIndex, contactTakeAmount } = useContactStore.getState();

  try {
    if (showSearchLoading) setLoadingSearch(true);
    else setLoading(true);
    let targetUrl = paginationUrl === "" ? getContactIndexUrl : paginationUrl;

    const res = await axios.get(targetUrl, {
      params: {
        take: contactTakeAmount,
        search_key: searchKey,
        role: filterForm?.status ?? "",
        is_replied: filterForm?.is_reply ?? "",
        is_read: filterForm?.is_seen ?? "",
        start_date: filterForm?.start_date ?? "",
        end_date: filterForm?.end_date ?? "",
      }
    });
    // const res = await axios.get(targetUrl + "?take=" + contactTakeAmount + "&search_key=" + searchKey + "&role=" + filterForm?.status+"&is_read="+"false"+"&is_replied="+"false");
    // const res = await axios.get(targetUrl + "?take=" + contactTakeAmount + "&search_key=" + searchKey + "&role=" + filterForm?.status+"&is_read="+filterForm?.is_seen+"&is_replied="+filterForm?.is_reply);

    console.table("getContactIndex res.data:::: ", res.data.data.data);


    if (res.data.success) {
      setContactIndex(res.data.data);
      setLoading(false);
      setLoadingSearch(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      setLoadingSearch(false);
      return false;
    }
  } catch (error) {
    console.log("getContactIndex: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    setLoadingSearch(false);
    return false;
  }
};


// show contact details
export const getContactDetails = async (id = 0) => {
  const { setContactDetails } = useContactStore.getState();

  try {
    setLoading(true);

    const res = await axios.get(getContactShowUrl + "?id=" + id);

    console.log("getContactDetails res.data:::: ", res.data.data);
    // return

    if (res.data.success) {
      await getContactIndex();
      setContactDetails(res.data.data);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getContactDetails: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};


// reply contact  
export const contactReply = async (replyForm = {}) => {
  const { contactPagination, contactSearchKey, contactFilterForm } = useContactStore.getState();
  try {
    setLoading(true);
    // "id": 2,
    // "reply": "contact update test"
    console.log("before api call replyForm::::", replyForm);

    const res = await axios.post(contactReplyUrl, replyForm);

    console.log("contactReply res.data:::: ", res.data.data);

    if (res.data.success) {
      await getContactDetails(replyForm?.id);
      await getContactIndex(contactPagination, contactSearchKey, contactFilterForm);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("contactReply: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};