import { useEffect } from "react";
import useLayoutStore from "../../App/Stores/LayoutStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import { Outlet } from "react-router-dom";
import useShopStore, { getAllShop } from "../../App/Stores/ShopStore";
import { useTranslation } from "react-i18next";

const Shop = () => {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();
  const { shopListAll, shopListPagination } = useShopStore();

  useEffect(() => {
    if (window.location.pathname === "/shops") {
      setBarTitle(t("Shops"));
      PageTitle(t("Shops"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname,t]);

  useEffect(() => {
    if (shopListPagination.current_page) {
      let urlToLoad = shopListPagination.path + "?page=" + shopListPagination.current_page;
      // console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
      getAllShop(urlToLoad);
    } else {
      getAllShop();
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("shopListAll:::", shopListAll);
    // console.log("shopListPagination:::", shopListPagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="overflow-y-auto">
      {/* green: outputs from nested route*/}
      <Outlet />
    </div>
  );
};

export default Shop;
