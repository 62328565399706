import React from "react";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";
import CommonLabel from "../../../Components/Label/CommonLabel";
import useUtilityStore from '../../../App/Stores/UtilityStore';
// import ProfilePic from '../../../Images/dummy-img/profilepic.jpeg'
import ProfileImageUpload from '../../../Components/Input/ProfileImageUpload';

const EditAccount = () => {
  const { showEditProfileModal, setShowEditProfileModal, loggedUser } = useUtilityStore();

  console.log("EditAccount");

  return (
    <>
      <CommonModal
        showModal={showEditProfileModal}
        setShowModal={setShowEditProfileModal}
        
        modalTitle="Edit Profile"
        mainContent={
          <>
            <div className="py-s20 bg-cWhite">

              <div className="flex justify-center items-center pb-[25px]">
                <div>
                  {/* <img src={ProfilePic} alt="" className="w-s160 h-s160 object-cover rounded-full mx-auto"/>                 */}
                  <ProfileImageUpload />
                </div>
              </div>

              <CommonLabel labelText="Name" />
              <CommonInput value={loggedUser.name} />
              <CommonLabel labelText="Email" />
              <CommonInput value={loggedUser.email} />
              <CommonLabel labelText="Phone Number" />
              <CommonInput value={loggedUser.phone} />

            </div>
          </>
        }
        singleButton={
          <>
            <CommonButton
              btnLabel="Save Changes"
              colorType="primary"
              onClick={() => setShowEditProfileModal(false)}
            />
          </>
        }
      />
    </>
  );
};

export default EditAccount;
