import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import useBoostedStore, {
  deleteBoostedShop,
  getAllBoostedShop,
  searchBoostedShops
} from "../../../../App/Stores/BoostedStore";
import useLayoutStore from "../../../../App/Stores/LayoutStore";
import { getAllShop } from "../../../../App/Stores/ShopStore";
import useUtilityStore from "../../../../App/Stores/UtilityStore";
import { PageTitle } from "../../../../App/Utility/UtilityFunctions";
import CommonDeleteConfirmModal from "../../../../Components/Modal/CommonDeleteConfirmModal";
import CommonTable from "../../../../Components/Table/CommonTable";
import NoDataRow from "../../../../Components/Table/NoDataRow";
import AddBoostShop from "./AddBoostShop";
import BoostedShopTableItem from "./BoostedShopTableItem";
import EditBoostedShop from "./EditBoostedShop";

export default function BoostedShop() {

  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();
  const {
    setShowAddBoostedShop,
    boostedShopListPagination,
    boostedShopList,
    searchShopBoost,
    setSearchShopBoost,
    setBoostShopPaginationUrl,
    boostShopPaginationUrl
  } = useBoostedStore();

  const [searchValue] = useDebounce(searchShopBoost, 500);

  const BoostedShopHeaders = [
    { index: 1, name: t("shop name") },
    { index: 2, name: t("start date") },
    { index: 3, name: t("expire date") },
    { index: 4, name: t("status") },
    { index: 5, name: t("action") },
  ];

  useEffect(() => {
    if (searchValue) {
      searchBoostedShops(searchValue,boostShopPaginationUrl );
    } else {
      getAllShop("", 500, true);
      getAllBoostedShop(boostShopPaginationUrl);
      console.log("boostedShopListPagination:::", boostedShopListPagination);
    }
  }, [searchValue]);

  useEffect(() => {
    setBarTitle(t("Boosted Shop"));
    PageTitle(t("Boosted Shop"));
  }, [t])


  return (
    <>
      <AddBoostShop />
      <EditBoostedShop />
      <ConfirmDelete />
      <div className="w-full">
        <div className="">
          <CommonTable
            takeComponent={<TakeItem />}
            addItemTitle={t("add boosted shop")}
            addItemFunction={() => {
              getAllShop("", 500, true);
              setShowAddBoostedShop(true);
            }}
            headers={BoostedShopHeaders}
            items={
              boostedShopList?.length > 0 ?
                boostedShopList.map((item, index) => (
                  <BoostedShopTableItem key={index} tableData={item} />
                ))
                : <NoDataRow columnNumber={BoostedShopHeaders?.length} />
            }
            showingFrom={boostedShopListPagination?.from}
            showingTo={boostedShopListPagination?.to}
            totalResult={boostedShopListPagination?.total}
            searchValue={searchShopBoost}
            searchOnChange={(e) => setSearchShopBoost(e.target.value)}
            onSearchClear={() => {
              setSearchShopBoost("");
              getAllBoostedShop();
            }}

            showPagination={
              boostedShopListPagination?.last_page !== 1 ? true : false
            }
            paginationObject={boostedShopListPagination}
            pagesArray={boostedShopListPagination.links}
            currentPageNumber={boostedShopListPagination.current_page}
            prevPageUrl={boostedShopListPagination.prev_page_url}
            nextPageUrl={boostedShopListPagination.next_page_url}
            paginationOnClick={(url) => {
              setBoostShopPaginationUrl(url)
              if (searchValue) {
                searchBoostedShops(searchValue, url);
              } else {
                getAllBoostedShop(url);
              }
            }}
          />
        </div>
      </div>
    </>
  );
}

const ConfirmDelete = ({ id }) => {
  const { t } = useTranslation();

  const { showDeleteShopBoost, setShowDeleteShopBoost, boostedShopListPagination } = useBoostedStore();
  return (
    <>
      <CommonDeleteConfirmModal
        showDeleteModal={showDeleteShopBoost}
        setShowDeleteModal={setShowDeleteShopBoost}
        messageToShow={t("Are you sure you want to delete this shop?")}
        onConfirm={() => {
          deleteBoostedShop(boostedShopListPagination.from !== boostedShopListPagination.to ? boostedShopListPagination.path + "?page=" + boostedShopListPagination.current_page : "");
        }}
      />
    </>
  );
};



const TakeItem = () => {

  const {
    setBoostShopTakeAmount,
    boostShopTakeAmount,
    setBoostShopPaginationUrl,
    customerPaginationUrl,
    searchShopBoost
  } = useBoostedStore();

  const { setLoading } = useUtilityStore.getState();

  return (
    <div className=''>
      <span className='pr-s10'>Show</span>
      <select id="cars"
        value={boostShopTakeAmount.take ? boostShopTakeAmount.take : 10}
        onChange={async (e) => {
          setLoading(true);
          await setBoostShopTakeAmount("take", e.target.value);
          await setBoostShopPaginationUrl("")
          if (searchShopBoost) {
            await setBoostShopPaginationUrl("")
            // await searchCustomerList(searchCustomerKey, customerPaginationUrl.url,e.target.value);
            await searchBoostedShops(searchShopBoost, "")
          } else {
            await setBoostShopPaginationUrl("")
            await getAllShop("", 500, true);
            await getAllBoostedShop("");
          }
          setLoading(false);
        }}
        className='pl-2 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={boostShopTakeAmount.take === 10}
          value={10}
        >10</option>

        <option
          className='py-s10 text-cHighlightedTexts'
          selected={boostShopTakeAmount.take === 25}
          value={25}
        >25</option>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={boostShopTakeAmount.take === 50}
          value={50}
        >50</option>
        <option
          className='py-s10 text-cMainBlack'
          selected={boostShopTakeAmount.take === 100}
          value={100}
        >100</option>
      </select>   <span className='pl-s10'>Entries</span>
    </div>
  )
}