import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import useOrderStore, {
  getAllOrderList,
  searchOrdersList
} from "../../../App/Stores/OrderStore";
import useUtilityStore from "../../../App/Stores/UtilityStore";
import CommonDropDown from "../../../Components/DropDown/CommonDropDown";
import CommonTable from "../../../Components/Table/CommonTable";
import NoDataRow from "../../../Components/Table/NoDataRow";
import OrderTableItem from "./OrderTableItem";
import CommonSelect from "../../../Components/DropDown/CommonSelect";
import DefaultSelect from "../../../Components/Select/DefaultSelect";

const OrderTable = ({ title, headers = [], tableBtnTitle }) => {

  const { t } = useTranslation();

  const {
    searchOrderValue,
    orderList,
    orderListPagination,
    setSearchOrderValue,

    setPaginationUrl,
    showOrderDetails,
    setDropdownItem,
    dropdownItem,

    orderTakeAmount
  } = useOrderStore();

  const [searchValue] = useDebounce(searchOrderValue, 500);

  const fetchAllOrders = async () => {
    await getAllOrderList();
  }

  useEffect(() => {
    if (window.location.pathname === "/orders") {
      setSearchOrderValue("")
      fetchAllOrders();
      setDropdownItem('all orders')
    }
  }, []);

  useEffect(() => {
    if (searchValue) {
      searchOrdersList(searchValue, "", orderTakeAmount.take);
    } else {
      fetchAllOrders();
    }
  }, [searchValue]);


  const { setCommonDropDownSelectedItem } = useUtilityStore();

  return (
    <>
      <CommonTable
        takeComponent={<TakeItem />}
        titleComponent={
          <div className="pb-0">
            <DefaultSelect
              withPlaceholder={false}
              value={dropdownItem}
              data={[
                { value: "all orders", label: "all orders", },
                { value: "pending", label: "pending", },
                { value: "processing", label: "processing", },
                { value: "completed", label: "completed", },
                { value: "canceled", label: "canceled", },
                { value: "rejected", label: "rejected", },
                { value: "cancellation request", label: "cancellation request" },
              ]}
              onChange={(e) => {
                setDropdownItem(e.target.value)
                setSearchOrderValue("")
                if (e.target.value === "all orders") getAllOrderList();
                else if (e.target.value === "cancellation request") getAllOrderList("cancellation_request");
                else getAllOrderList(e.target.value);
              }}
            />
          </div>
        }
        headers={headers}
        showingFrom={orderListPagination.from}
        showingTo={orderListPagination.to}
        totalResult={orderListPagination.total}
        items={
          orderList?.length > 0 ?
            orderList?.map((item, index) => (
              <OrderTableItem
                orderData={item}
                key={index}
                end={orderList?.length - 1}
                barIndex={index}
              />
            )) : <NoDataRow columnNumber={headers?.length} />
        }

        searchValue={searchOrderValue}
        searchOnChange={(e) => { setCommonDropDownSelectedItem("all orders"); setSearchOrderValue(e.target.value); setPaginationUrl("") }}
        onSearchClear={() => {
          setSearchOrderValue("");
          getAllOrderList();
          setPaginationUrl("")
          setCommonDropDownSelectedItem("all orders");
        }}

        paginationObject={orderListPagination}
        pagesArray={orderListPagination.links}
        currentPageNumber={orderListPagination.current_page}
        prevPageUrl={orderListPagination.prev_page_url}
        nextPageUrl={orderListPagination.next_page_url}
        showPagination={
          orderListPagination?.last_page !== 1 ? true : false
        }
        paginationOnClick={(url) => {
          setPaginationUrl(url)
          console.log(url);
          if (searchValue) {
            searchOrdersList(searchValue, url, orderTakeAmount.take)
          } else getAllOrderList(dropdownItem, url);
        }}
      />
    </>
  );
};

export default OrderTable;


const TakeItem = () => {
  const {
    orderTakeAmount,
    setPaginationUrl,
    setOrderTakeAmount,
    searchOrderValue,
    dropdownItem
  } = useOrderStore();

  const { setLoading } = useUtilityStore.getState();

  return (
    <div className=''>
      <span className='pr-s10'>Show</span>
      <select id=""
        value={orderTakeAmount.take ? orderTakeAmount.take : 10}
        onChange={async (e) => {
          setLoading(true);
          await setOrderTakeAmount("take", e.target.value);
          await setPaginationUrl("url", "")
          if (searchOrderValue) {
            await setPaginationUrl("url", "")
            await searchOrdersList(searchOrderValue, "", e.target.value);
          } else {
            await setPaginationUrl("url", "")
            await getAllOrderList(dropdownItem)
          }
          setLoading(false);
        }}
        className='pl-2 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={orderTakeAmount.take === 10}
          value={10}
        >10</option>

        <option
          className='py-s10 text-cHighlightedTexts'
          selected={orderTakeAmount.take === 25}
          value={25}
        >25</option>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={orderTakeAmount.take === 50}
          value={50}
        >50</option>
        <option
          className='py-s10 text-cMainBlack'
          selected={orderTakeAmount.take === 100}
          value={100}
        >100</option>
      </select>   <span className='pl-s10'>Entries</span>
    </div>
  )
}
