/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useSettingsStore, { getGeneralInfo, updateGeneralInfo } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import RichTextEditor from "../../../Components/Input/RichTextEditor";

const TermsDetails = () => {
  const { t } = useTranslation();

  const {
    termsEditState,
    setTermsEditState,
    generalInfo,
    editTermsCondition,
    setEditTermsCondition
  } = useSettingsStore();

  //console.log("editTermsCondition", editTermsCondition)

  const handleSubmit = () => {
    if (editTermsCondition?.info) {
      updateGeneralInfo('terms-and-conditions');
    }
  }

  useEffect(() => {
    getGeneralInfo('terms-and-conditions');
    setTermsEditState(false)
  }, [editTermsCondition?.lang])

  return (
    <form onSubmit={(e) => e.preventDefault()} className="w-full">
      <div className="flex justify-between">
        <div className="pb-5 text-fs20 font-fw600">{t("Terms & Condition")}</div>
        <div>{!termsEditState &&
          <select value={editTermsCondition?.lang} onChange={(e) => { setEditTermsCondition({ ...editTermsCondition, lang: e.target.value }) }}
            className='px-2 py-2 border-2 border-gray-900 rounded-md text-md font-archivo'>
            <option value="en">English</option>
            <option value="ja">日本語</option>
            <option value="zh">中文</option>
            <option value="bn">বাংলা</option>
          </select>
        }</div></div>
      <div className={`bg-white p-5 rounded-br5 ${termsEditState ? "h-s400" : ""}  drop-shadow-md`}>
        {termsEditState ? <div className="h-[318px]">
          <RichTextEditor
            placeholder={t("Write your business Terms & Condition...")}
            value={editTermsCondition?.info}
            onChange={(e) => setEditTermsCondition({ ...editTermsCondition, info: e })}
          />
        </div> :
          <div className="flex">
            <div dangerouslySetInnerHTML={{ __html:editTermsCondition?.info }}></div>
          </div>
        }
      </div>

      <div className="flex justify-end pt-5">
        {termsEditState ? <CommonButton btnLabel={t("Save Changes")} type="submit" colorType="primary" onClick={handleSubmit} /> :
          <CommonButton btnLabel={t("Edit")} type="submit" colorType="primary" onClick={() => setTermsEditState(true)} />
        }
      </div>
    </form>
  );
};

export default TermsDetails;
