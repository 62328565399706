import React from "react";
import { useTranslation } from "react-i18next";
import useFaqStore, { saveNewFAQ } from '../../../../App/Stores/FaqStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonModal from '../../../../Components/Modal/CommonModal';

const AddFaq = () => {
  const {t} = useTranslation();

  const { addingNewFaq, setAddingNewFaq } = useFaqStore();
  
  // console.log("ADD FAQ LOADED !!");
  return (
    <>
      <CommonModal
        showModal={addingNewFaq}
        setShowModal={setAddingNewFaq}
        
        modalTitle={t("Add FAQ")}
        mainContent={
          <>
            <AddForm />
          </>
        }
      />
    </>
  );
};

function AddForm() {
  const {t} = useTranslation();

  const { setFaqFormData, faqFormData, setAddingNewFaq } = useFaqStore();
  const handleFormSubmit = async (e) => {
    // console.log('categoryFormData', categoryFormData);
    e.preventDefault();
    await saveNewFAQ();
    setAddingNewFaq(false);
  }

  return (
    <div className="pt-5 bg-white">
      <form onSubmit={handleFormSubmit}>

        <CommonInput
          onChange={(e) => setFaqFormData('title', e.target.value)}
          label={t("FAQ from admin")}
          name={"title"}
          value={faqFormData.title}
          required={true}
          placeholder={t("Write Question...")}
        />

        <CommonInput
          onChange={(e) => setFaqFormData('description', e.target.value)}
          name={"description"}
          value={faqFormData.description}
          required={true}
          textarea={true}
          rows={5}
          label={t("Answer")}
          placeholder={t("Write answer...")}
        />

        <div className="flex flex-row justify-center mt-5">
          <CommonButton
            btnLabel={t("Add")}
            colorType="primary"
            type="submit"
          />
        </div>

      </form>
    </div>
  );
}

export default AddFaq;
