import React, { useEffect } from "react";
import useSettingsStore, { getAllFees, settingsUpdate } from "../../../App/Stores/SettingsStore";
import ReferralPoint from "./ReferralPoint";
import AuthorityFee from "./AuthorityFee";
import Coin from "./Coin";
import ShippingFee from "./ShippingFee";
import { useTranslation } from "react-i18next";
import { MuiCustomSwitch } from "../../../App/Stores/UtilityStore";
import OrderBonusPercentage from "./OrderBonusPercentage";
import ReferUserOrderBonus from "./ReferUserOrderBonus";
import BonusLimit from "./BonusLimit";
import OrderBonus from "./OrderBonus";
import TaxPercentage from "./TaxPercentage";

export default function AllFees() {

  const { setIs_referral, is_referral, setShowReferralModal } = useSettingsStore();
  const { t } = useTranslation();

  useEffect(() => {
    getAllFees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="flex items-center ">
      <div className="grid w-full grid-cols-2 gap-12">
        <AuthorityFee />
        <ShippingFee />
        <Coin />
        <ReferralPoint />

        <div className="flex flex-row items-center justify-between w-full p-5 pb-5 bg-white shadow-md text-fs20 font-fw600 rounded-br5">
          <span>{t("Referral Status")}</span>
          <MuiCustomSwitch
            color="secondary"
            size="small"
            checked={is_referral}
            onClick={() => {
              if (is_referral) {
                setShowReferralModal(true);
              } else {
                const success = settingsUpdate({ type: "is_referral", value: !is_referral });
                if (success) return setIs_referral(1);
              }
            }}
            // onChange={(e) => handleActiveShop(e)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>

        <OrderBonus />

        <OrderBonusPercentage />
        <ReferUserOrderBonus />
        <BonusLimit />
        <TaxPercentage />
      </div>
    </div>
  );
}
