import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import useContactStore, { getContactIndex } from "../../App/Stores/ContactStore";
import useLayoutStore from "../../App/Stores/LayoutStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import AddBtnOutlined from "../../Components/Button/AddBtnOutlined";
import CommonTable from "../../Components/Table/CommonTable";
import NoDataRow from "../../Components/Table/NoDataRow";
import TakeItem from "../../Components/Table/TakeItem";
import { FilterHover, FilterNormal } from "../../Components/Utility/ImageImports";
import ContactTableRow from "./ContactTableRow";

export default function ContactMessage() {
  const { setBarTitle } = useLayoutStore();

  const {
    contactIndex,
    isFilterMessage,
    setShowFilterMessage,
    contactTakeAmount,
    setContactTakeAmount,
    contactSearchKey,
    setContactSearchKey,
    contactFilterForm,
    contactPagination,
    setContactPagination,
  } = useContactStore();

  const { t } = useTranslation();

  const contactHeader = [
    { index: 1, name: t("name") },
    // { index: 2, name: "subject" },
    { index: 3, name: t("email") },
    // { index: 4, name: "user type" },
    { index: 5, name: t("message") },
    { index: 6, name: t("created on") },
    { index: 7, name: t("last action") },
  ];

  useEffect(() => {
    fetchContactIndex();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchContactIndex = async () => {
    await getContactIndex(contactPagination, contactSearchKey, contactFilterForm);
  }

  useEffect(() => {
    // set the title of page and top bar
    setBarTitle(t("Contact Us"));
    PageTitle(t("Contact Us"));
  }, [t])

  const [searchValue] = useDebounce(contactSearchKey, 500);

  useEffect(() => {
    smartSearchAllContacts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  const smartSearchAllContacts = async () => {
    if (contactSearchKey) await getContactIndex("", contactSearchKey, contactFilterForm, true);
    else await getContactIndex("", "", contactFilterForm, true);
  }

  return (
    <>
      <CommonTable
        takeComponent={
          <TakeItem
            value={contactTakeAmount}
            onChange={async (value) => {
              await setContactTakeAmount(value);
              await getContactIndex(contactPagination, contactSearchKey, contactFilterForm);
            }}
          />
        }
        secondaryTitle={
          <AddBtnOutlined
            btnLabel="Filter"
            showActiveDot={isFilterMessage}
            onClick={() => setShowFilterMessage(true)}
            iconHover={FilterHover}
            iconNormal={FilterNormal}
          />
        }
        headers={contactHeader}
        items={
          contactIndex?.data?.length > 0 ?
            contactIndex?.data?.map((item, index) =>
              <ContactTableRow key={index} data={item} dataIndex={index} end={contactIndex?.data.length - 1} />
            ) : <NoDataRow columnNumber={contactHeader?.length} />
        }
        addItemModal={<>{/* <AddShop /> */}</>}

        shoSearchBox={true}
        searchOnChange={async (e) => {
          await setContactSearchKey(e.target.value);
        }}
        onSearchClear={async () => {
          await setContactSearchKey("");
          await getContactIndex("", "", contactFilterForm);
        }}

        showPagination={
          contactIndex?.last_page !== 1 ? true : false
        }

        prevPageUrl={contactIndex?.prev_page_url}
        paginationObject={contactIndex}
        nextPageUrl={contactIndex?.next_page_url}
        pagesArray={contactIndex?.links}
        paginationOnClick={async (url) => {
          await setContactPagination(url);
          console.log("url::::", url);
          await getContactIndex(url, contactSearchKey, contactFilterForm);
        }}


        showingFrom={contactIndex?.from ?? 0}
        showingTo={contactIndex?.to ?? 0}
        totalResult={contactIndex?.total ?? 0}
      />
    </>
  );
}
