import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import useCustomerStore, { getAllOrderListOfCustomer } from '../../../App/Stores/CustomerStore';
import useOrderStore, { searchOrdersList } from '../../../App/Stores/OrderStore';
import CommonTable from '../../../Components/Table/CommonTable';
import NoDataRow from '../../../Components/Table/NoDataRow';
import OrderTableItem from '../../Order/DataTable/OrderTableItem';
import CustomerDetails from '../Details/CustomerDetails';

const OrderHistoryTableItem = () => {

    const { t } = useTranslation();

    const {
        orderListPagination,
        searchOrderValue,
        setSearchOrderValue,
        orderList,
        setCustomerOrderHistoryPaginationUrl,
    } = useOrderStore();
    const { setDeletableCustomer, customerDetails } = useCustomerStore();

    const headers = [
        { index: 1, name: t("Invoice Id") },
        { index: 2, name: t("Customer name") },
        { index: 3, name: t("Shop Name") },
        { index: 4, name: t("Shop phone") },
        { index: 5, name: t("order date") },
        { index: 6, name: t("Status") },
    ];

    const [searchValue] = useDebounce(searchOrderValue, 500);

    useEffect(() => {
        if (searchValue) {
            //setOrderList('all orders')
            searchOrdersList(searchValue);
        } else {
            fetchAllOrders();
        }
    }, [searchValue]);

    const fetchAllOrders = async () => {
        await getAllOrderListOfCustomer("all orders", "", localStorage.getItem("customerID"));
    };

    useEffect(() => {        
        if (orderList?.length > 0) {
            orderList?.map(item => {        
                if (item?.status !== "completed") setDeletableCustomer(false);
                else setDeletableCustomer(true);
                return null;
            })
        } else setDeletableCustomer(true);
    }, [orderList, customerDetails]);

    return (
        <CustomerDetails>
            <CommonTable
                // titleComponent={
                //   <div className="pb-0">
                //     <CommonDropDown
                //       toggleTitle={"Filter Status"}
                //       data={[
                //         "all orders",
                //         "pending",
                //         "processing",
                //         "completed",
                //         "canceled",
                //         "rejected",
                //       ]}
                //       itemOnClick={(item) => {
                //         // console.log(item);
                //         if (item === "all orders") {
                //           getAllOrderListOfCustomer();
                //         } else getAllOrderListOfCustomer(item);
                //       }}
                //     />
                //   </div>
                // }
                headers={headers}
                showingFrom={orderListPagination.from}
                showingTo={orderListPagination.to}
                totalResult={orderListPagination.total}
                // secondaryTitle={t("Order Details")}
                items={
                    orderList?.length > 0 ?
                        orderList?.map((item, index) => (
                            <>
                                <OrderTableItem
                                    orderData={item}
                                    key={index}
                                    end={orderList?.length - 1}
                                    barIndex={index}
                                />
                            </>
                        )) : <NoDataRow columnNumber={headers?.length} />
                }

                // todo:    search for this table will be added 
                shoSearchBox={false}
                searchValue={searchOrderValue}
                searchOnChange={(e) => setSearchOrderValue(e.target.value)}

                pagesArray={orderListPagination.links}

                paginationObject={orderListPagination}
                currentPageNumber={orderListPagination.current_page}
                prevPageUrl={orderListPagination.prev_page_url}
                nextPageUrl={orderListPagination.next_page_url}
                showPagination={orderListPagination?.last_page > 1 ? true : false}
                paginationOnClick={(url) => {
                    console.log(url);
                    setCustomerOrderHistoryPaginationUrl(url);
                    getAllOrderListOfCustomer("all orders", url, localStorage.getItem("customerID"));
                }}
            />

        </CustomerDetails>
    );
};

export default OrderHistoryTableItem;