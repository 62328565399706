import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AddBtnOutlined from "../Button/AddBtnOutlined";
import AutoPaginate from "../Pagination/AutoPaginate";
import CommonSearchBox from "../SearchBox/CommonSearchBox";

export default function CommonTable({
  roundedBorderOuter = true,
  withOuterShadow = true,
  outerPadding = "p-s20",
  secondaryPaddingBottom = "pb-5",
  titleComponent,
  tableTitle = "",
  secondaryTitle = "",
  headers,
  items,
  pagination = true,
  addItemModal,
  addItemTitle,
  addItemFunction,
  searchValue,
  searchOnChange,
  shoSearchBox = true,
  onSearchClear = () => { },
  seeAllText,
  seeAllLink = "#",
  showingFrom = 1,
  showingTo = 5,
  totalResult = 100,
  pagesArray = [],
  currentPageNumber,
  prevPageUrl,
  nextPageUrl,
  paginationOnClick,
  showPagination = true,
  showPageCountText = true,
  paginationObject,
  takeComponent,

  showChip = false,
  chipWidth = "min-w-[180px]",
  chipAreaWidth = "max-w-[95vw]",
  chipArray = [
    {
      title: "Option 01",
      selected: false,
      action: () => {
        console.log("Option 01");
      },
    },
    {
      title: "Option 02",
      selected: true,
      action: () => {
        console.log("Option 02");
      },
    },
    {
      title: "Option 03",
      selected: false,
      action: () => {
        console.log("Option 03");
      },
    },
    {
      title: "Option 04",
      selected: false,
      action: () => {
        console.log("Option 04");
      },
    },
    {
      title: "Long Option 05 that has more data",
      selected: false,
      action: () => {
        console.log("Option 05");
      },
    },
    {
      title: "Long Option 06",
      selected: false,
      action: () => {
        console.log("Option 06");
      },
    },
  ],
}) {
  const { t } = useTranslation();


  return (
    <>
      {addItemModal}
      <div
        className={`${outerPadding} bg-white text-cTextBlack w-full rounded-br5 ${withOuterShadow ? "shadow-sm" : "shadow-none"} `}
      >

        {/* idea: chip to show... */}
        {showChip ?
          <div className={`flex space-x-2 overflow-x-auto mb-s10 ${seeAllText ? "max-w-[75vw]" : chipAreaWidth}`}>
            {
              chipArray.map((item, index) =>
                <div
                  key={index}
                  onClick={item.action}

                  className={`
                        mb-s10 px-s5 ${chipWidth} capitalize h-s36 flex items-center justify-center rounded-full cursor-pointer select-none text-fs16 font-fw600 
                        ${item.selected ? "bg-cBrand text-white" : "bg-white text-cBrand border border-cChipBorder hover:bg-cBrand hover:border-cBrand hover:text-white"}
                      `}

                >{item.title}</div>
              )}
          </div> : ""}

        <div
          className={`flex lg:flex-row flex-col space-y-5 justify-between lg:space-y-0 z-50 items-start lg:items-center ${secondaryPaddingBottom} max-w-full pt-s5 px-s5`}
        >

          <div className="flex items-center">
            {tableTitle ? (
              <div className="text-fs20 pr-5 pb-p10 font-fw500 capitalize">
                {tableTitle}
              </div>
            ) : (
              ""
            )}

            <div>
              {/* g   search box */}
              {shoSearchBox === true ? (
                <CommonSearchBox withClearSearch={true} onSearchClear={onSearchClear} value={searchValue} onChange={searchOnChange} />
              ) : (
                ""
              )}

              {/* l     take component location */}
              {
                takeComponent ? <div className="pt-5">{takeComponent}</div> : ""
              }
            </div>
          </div>


          <div className="flex items-center">
            {titleComponent ? (
              <div className="bg-cWhite">{titleComponent}</div>
            ) : (
              ""
            )}
            {addItemTitle ? (
              <div className="pl-5 min-w-[250px] flex flex-row-reverse">
                <AddBtnOutlined
                  btnLabel={addItemTitle}
                  onClick={() => addItemFunction(true)}
                />
              </div>
            ) : (
              ""
            )}

            {seeAllText ? (
              <Link
                to={seeAllLink}
                className="text-fs16 font-fw600 text-cBrand"
              >
                {seeAllText}
              </Link>
            ) : (
              ""
            )}

            {secondaryTitle ?
              <>
                <div className="pr-1 text-fs20 font-fw500">
                  {secondaryTitle}
                </div>
              </>
              : ""}
          </div>
        </div>

        {/* blue: main table ui */}
        {/* green: Headers... */}
        <div className="p-s5 lg:overflow-auto overflow-x-auto lg:max-w-full max-w-[1024px]">
          <table className={`w-full overflow-hidden  ${roundedBorderOuter ? "table-border-outer" : "border"}`}>
            <thead className="">
              <tr>
                {headers.map(({ index, name }) => {
                  return (
                    <th
                      key={index}
                      className="border-collapse border-[1px] text-center capitalize text-fs16 font-fw500 py-s10"
                    >
                      {name}
                    </th>
                  );
                })}
              </tr>
            </thead>

            {/* green: table body rows.. */}
            <tbody className="border-collapse border-[1px]"> {items}</tbody>
          </table>
        </div>


        {pagination ? (
          <div className="xl:h-s50 h-[90px] flex flex-col xl:flex-row space-y-5 xl:space-y-5 justify-between xl:items-center items-start mt-5">
            {showPageCountText ? (
              totalResult > 0 ?
                <div className="text-sm xl:mt-[19.5px]">
                  {t("Showing")} {showingFrom} {t("to")} {showingTo}, {t("out of")} {totalResult}{" "}
                  {t("results.")}
                </div>
                :
                <div className="text-sm">
                  {t("No results available.")}
                </div>
            ) : (
              ""
            )}
            {showPagination ? (
              <div className="pb-10 xl:pb-0">
                <AutoPaginate
                  paginationObject={paginationObject}
                  paginationOnClick={paginationOnClick}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
