
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../App/Utility/UtilityFunctions';
import Image from '../../Components/Image/Image';
import { Verified } from '../../Components/Utility/ImageImports';

const VerifyIdentityTable = ({ data, index }) => {

    const navigateTo = useNavigate();

    function VerifyIdentityType(state) {
        switch (state) {
            case 'mncard':
                return " My Number Card"
            case 'driving':
                return "Driving License"
            case 'rcard':
                return "Residence Card"
            case 'passport':
                return "Passport"
            default:
                return 'NA'
        }
    }

    console.log("data", data?.status)

    return (
        <>
            <tr onClick={async () => {
                await localStorage.setItem('verificationDetails', JSON.stringify(data))
                navigateTo('/verify/identity/details')
            }}
                className={`border-b cursor-pointer border-collapse border-cNmSelect hover:bg-cBandHover`} >
                <th className='font-normal py-s10 border-r text-center min-w-[50px]'>
                    {index + 1}
                </th>

                <td className='border-r-[1px] p-s10 text-left min-w-[200px] max-w-[200px]'>
                    <div className='flex items-center'>
                        <Image src={data?.image_url} alt="" className="rounded-full w-6 h-6 object-cover" />

                        {data?.status === 'verified' ? <div class="absolute top-0 right-0">
                            <img className="w-s15 h-s18" src={Verified} alt="" />
                        </div> : ''}
                        <div className='truncate my-s10 font-fw600 ml-s8 justify-center text-cMainBlack lg:text-fs14 sm:text-fs12'>
                            {data?.name ? data?.name : 'N/A'}
                        </div>
                    </div>
                </td>

                <td className='p-s10 border-r-[1px] text-fs14 text-center text-cTextBody min-w-[250px]'>
                    {data?.email ? data.email : 'N/A'}
                </td>

                <td className='p-s10 border-r-[1px] text-center text-cTextBody text-fs14 capitalize min-w-[180px]'>
                    {data?.type ? VerifyIdentityType(data?.type) : 'N/A'}
                </td>

                <td className='p-s10 border-r-[1px] text-fs14 font-fw400 text-center  text-cTextBody min-w-[120px]'>
                    {formatDate(data?.created_at)}
                </td>
            </tr>

        </>
    )
}

export default VerifyIdentityTable;