/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import useLayoutStore from "../../App/Stores/LayoutStore";
import useSettingsStore from "../../App/Stores/SettingsStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
// import SettingsOption from "./SettingsOption";

const Settings = () => {

  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();
  const { setSelectedOption } = useSettingsStore();

  useEffect(() => {
    // initially selected option
    setSelectedOption("faq");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set the title of page and top bar
    setBarTitle(t("Settings"));
    PageTitle(t("Settings"));
  }, [t])


  return (
    <div className="">
      {/* green: SettingsDetails */}
      <Outlet />
    </div>
  );
};

export default Settings;