import React from "react";
import { useTranslation } from "react-i18next";
import useCategoryStore, { toggleCategoryActivation } from "../../../App/Stores/CategoryStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonModal from "../../../Components/Modal/CommonModal";

const DeactivateCategory = () => {
  const { t } = useTranslation();

  const { showDeactivateModal, setShowDeactivateModal } = useCategoryStore();
  
  return (
    <div>
      <CommonModal
        showModal={showDeactivateModal}
        setShowModal={setShowDeactivateModal}
        
        modalTitle={t("Deactivate Category")}
        mainContent={
          <div className="pt-5 pb-s30 flex justify-center items-center">
            <div>{t("Do you want to Deactivate this category?")}</div>
          </div>
        }
        primaryActionButton={
          <>
            <CommonButton
              btnLabel={t("Deactivate")}
              colorType="danger"
              onClick={() => {
                toggleCategoryActivation(0);
                setShowDeactivateModal(false);
              }}
            />
          </>
        }
        secondaryActionButton={
          <>
            <CommonButton
              btnLabel={t("Cancel")}
              onClick={() => setShowDeactivateModal(false)}
            />
          </>
        }
      />
    </div>
  );
};

export default DeactivateCategory;
