export const BaseUrlSrc = "https://gifty-api.non-logic.com/";
// export const BaseUrlSrc = "https://gifty-api-dev.non-logic.com/";
// export const BaseUrlSrc = "http://192.168.31.155:8000";


const BaseUrl = BaseUrlSrc + "api/v1";
export default BaseUrl;

// Auth Urls
export const userLoginUrl = "/common/auth/login";

// Dashboard Urls
export const dashBoardDataUrl = "/admin/dashboard";

// blue: shop urls
export const getAllShopListUrl = "/admin/shop/get-all-shops";
export const searchAllShopListUrl = "/admin/shop/get-shops-filter";
export const getShopDetailsUrl = "/admin/shop/show-shop";
export const addNewShopUrl = "/admin/shop/add-new-shop";
export const updateShopUrl = "/admin/shop/update-shop";
export const deleteShopUrl = "/admin/shop/delete-shop";

//green: category urls
export const getAllCategoryList = "/admin/category/index";
export const addCategory = "/admin/category/add";
export const deleteCategoryUrl = "/admin/category/delete";
export const editCategoryUrl = "/admin/category/update";
export const toggleCategoryUrl = "/admin/category/toggle";
export const rearrangeCategoryUrl = "/admin/category/update-index";
export const resetArrangementCategoryUrl = "/admin/category/reset-index";

// blue: customer urls
export const getAllCustomerListUrl = "/admin/customer/index";
export const searchCustomerUrl = "/admin/customer/filter";
export const showCustomerUrl = "/admin/customer/show";
export const updateCustomerUrl = "/admin/customer/update";
export const deleteCustomerUrl = "/admin/customer/delete";
export const addCustomerUrl = "/admin/customer/add";
export const toggleCustomerUrl = "/admin/customer/status-toggle";

// green: voucher urls
export const getAllVoucherUrl = "/admin/voucher/index";
export const searchVoucherUrl = "/admin/voucher/filter";
export const addVoucherUrl = "/admin/voucher/add";
export const toggleVoucherUrl = "/admin/voucher/status-toggle";
export const getVoucherDetailsUrl = "/admin/voucher/details";
export const deleteVoucherUrl = "/admin/voucher/delete";

// blue: orders urls
export const getAllOrdersUrl = "/admin/customer/order/filter";
export const updateOrderActionUrl = "/admin/orders/action";
export const getOrderDetailsUrl = "/admin/orders/show";

// yellow: gift card urls
export const getAllGiftCardsUrl = "/admin/post-cards/filter";
export const addGiftCardUrl = "/admin/post-cards/add";
export const deleteGiftCardUrl = "/admin/post-cards/delete";
export const updateGiftCardUrl = "/admin/post-cards/update";
export const getGiftCardsDetailsUrl = "/admin/post-cards/show";
export const getGiftCardsOrderUrl = "/admin/post-cards/order-filter";

// green: boosted shop urls
export const getAllBoostedShopsUrl = "/admin/boosted-shop/index";
export const addBoostedShopsUrl = "/admin/boosted-shop/add";
export const searchBoostedShopsUrl = "/admin/boosted-shop/filter";
export const deleteBoostedShopsUrl = "/admin/boosted-shop/delete";
export const updateBoostedShopsUrl = "/admin/boosted-shop/edit";

// FAQ urls
export const getAllFaqListUrl = "/admin/faq/index";
export const addFaqUrl = "/admin/faq/add";
export const updateFaqUrl = "/admin/faq/update";
export const deleteFaqUrl = "/admin/faq/delete";


// settings api urls
export const allFeesUrl = "/admin/settings/get";
export const updateFeesUrl = "/admin/settings/update";
export const changeLanguageUrl = "/common/auth/profile/change-language";

// recommended category
export const updateRecommendedCategoryUrl = "/admin/category/update-recommended-category";

// privacy policy
// export const getPrivacyUrl = "/admin/privacy-policy/get";
// export const updatePrivacyUrl = "/admin/privacy-policy/update";
export const getGeneralInfoUrl = "/common/general-info";
export const getGeneralInfoUpdateUrl = "/common/general-info/update";

// contact us
export const getContactIndexUrl = "/admin/contact-us/filter";   //for index, search and filtering all contacts with pagination
export const getContactShowUrl = "/admin/contact-us/show";
export const contactReplyUrl = "/admin/contact-us/reply";
export const getContactSearch = "/admin/contact-us/search";

// notifications
export const getNotificationIndexUrl = "/common/notification/index";
export const getNotificationShowUrl = "/common/notification/show";

// terms
export const getTermsUrl = "/admin/terms-and-condition/get";
export const updateTermsUrl = "/admin/terms-and-condition/update";

// update profile
export const updateProfileUrl = "/common/auth/profile/update";
export const getProfileUrl = "/common/auth/user";
export const changePasswordUrl = "/common/auth/profile/change-password";


// Verify Identity Request
export const verifyIdentityRequestUrl = "/admin/verification-requests"
export const verifyRequestStatusUrl = "/admin/customer/vid-status-toggle"
