import React from 'react'

const TakeItem = ({value, onChange}) => {

    return (
        <div className=''>
            <span className='pr-s10'>Show</span>
            <select id="cars"
                defaultValue={value ? value : 10}
                onChange={async (e) => {
                    console.log("take", e.target.value);
                    onChange(e.target.value);
                }}
                className='pl-2 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'
            >
                <option
                    className='py-s10 text-cHighlightedTexts'
                    // selected={value === 10}
                    value={10}
                >10</option>

                <option
                    className='py-s10 text-cHighlightedTexts'
                    // selected={value === 25}
                    value={25}
                >25</option>
                <option
                    className='py-s10 text-cHighlightedTexts'
                    // selected={value === 50}
                    value={50}
                >50</option>
                <option
                    className='py-s10 text-cMainBlack'
                    // selected={value === 100}
                    value={100}
                >100</option>
            </select>   <span className='pl-s10'>Entries</span>

        </div>
    )
}

export default TakeItem