import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const PageTitle = (newTitle) => {
  return (document.title = newTitle);
};

export const Toastr = ({ message = "", type = "error" }) => {
  toast(message, {
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: type,
  });
};

export const formatDate = (date) => {
  if (!date) return null;
  date = new Date(date.slice(0, 10));
  date.setHours(0, 0, 0, 0);

  const months = [];
  months["en"] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  months["da"] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${new Date(date).getDate()} ${months["da"][new Date(date).getMonth()]}, ${new Date(date).getFullYear()}`;
};

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result)
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}

export const getBase64FromUrl = async (url) => {
  console.log("image-url:: ", url);

  const data = await fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached    
    headers: {
      // 'Content-Type': 'application/json',
      'Content-Type': 'application/octet-stream',
      'Access-Control-Allow-Origin': '*',
    },
  });

  console.log("FETCH IMAGE DATA::::", data);

  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      console.log("BASE-64-DATA:::", base64data);
      resolve(base64data);
    }
  });
}

export function isOnlyWhitespace(str) {
  return /^\s*$/.test(str);
}


export function LogDanger(message, value) {
  console.log('%c' + message, 'background: #f40e44; color: #ffffff; font-weight: bold; padding:15px; border-radius: 1500px', value);
}

export function LogToDo(message, value) {
  console.log('%c' + message, 'background: #f4ef4b; color: #000000; font-weight: bold; padding:15px; border-radius: 1500px', value);
}

export function LogSuccess(message, value) {
  console.log('%c' + message, 'background: #47ff90; color: #000000; font-weight: bold; padding:15px; border-radius: 1500px', value);
}

export function LogWarning(message, value) {
  console.log('%c' + message, 'background: #FC4C02; color: #ffffff; font-weight: bold; padding:15px; border-radius: 1500px', value);
}

export function PastDateFromToday(value = 0) {
  let targetDate = new Date();
  targetDate.setDate(targetDate.getDate() - value);
  targetDate.setHours(0, 0, 0, 0);
  return targetDate.getFullYear() + "-" + (targetDate.getMonth() + 1 < 10 ? ("0" + (targetDate.getMonth() + 1)) : (targetDate.getMonth() + 1)) + "-" + (targetDate.getDate() < 10 ? "0" + targetDate.getDate() : targetDate.getDate());
}