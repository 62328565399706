import { Menu, Transition } from "@headlessui/react";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import useUtilityStore from "../../App/Stores/UtilityStore";
import BtnFillRounded from "../Button/BtnFillRounded";
import DropDownBtn from "../Button/DropDownBtn";

const CommonDropDown = ({
  toggleButton,
  toggleTitle,
  itemOnClick,
  useDataObject = false,
  withToolTip = false,
  dataObject = [
    {
      id: 1,
      name: "Object-one",
    },
    {
      id: 2,
      name: "Object-two",
    },
    {
      id: 3,
      name: "Object-three",
    },
    {
      id: 4,
      name: "Object-four",
    },
  ],
  data = [
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
  ],
  disabled = false,
  value = "",
  required = false,
  warningBorder = false,
  name,
  fullWidth = false,
  basicColor = false,
  placeholderTextShow = false,
  toggleButtonWidth = "",
  type = 1,
}) => {
  // const [selectedItem, setSelectedItem] = useState(toggleTitle);
  const [isActive, setIsActive] = useState(false);
  const [bgColor, setBgColor] = useState("");
  const [borderColor, setBorderColor] = useState("");

  const {commonDropDownSelectedItem, setCommonDropDownSelectedItem}=useUtilityStore();

  useEffect(() => {
    switch (commonDropDownSelectedItem) {
      case "completed":
        setBgColor("bg-cSuccess");
        setBorderColor("ring-cSuccess");
        break;
      case "processing":
        setBgColor("bg-cPending");
        setBorderColor("ring-cPending");
        break;
      case "pending":
        setBgColor("bg-cPending");
        setBorderColor("ring-cPending");
        break;
      case "rejected":
        setBgColor("bg-cRed");
        setBorderColor("ring-cRed");
        break;
      case "canceled":
        setBgColor("bg-cRed");
        setBorderColor("ring-cRed");
        break;
      default:
        setBgColor("bg-white");
        setBorderColor("ring-white");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonDropDownSelectedItem]);

  useEffect(() => {
    if (value) {
      setCommonDropDownSelectedItem(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCommonDropDownSelectedItem(toggleTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleTitle]);



  // useEffect(() => {
  //   console.log("ACTIVE FOCUS ?? ", isActive);
  // }, [isActive])

  return (
    <Menu>
      {toggleButton ? <Menu.Button>{toggleButton}</Menu.Button> : ""}

      {toggleTitle ? (
        <>
          <input
            name={name}
            value={commonDropDownSelectedItem}
            required={required}
            type="hidden"
            // className="opacity-0"
            onChange={() => { }}
          />
          <Menu.Button className={"w-full"}>
            <div className="w-full">
              {type === 1 ? (
                placeholderTextShow === true ? (
                  <DropDownBtn
                    fullWidth={fullWidth}
                    // onClick={() => setIsActive(!isActive)}
                    btnLabel={commonDropDownSelectedItem}
                    isBtnActive={isActive}
                    basicColor={basicColor}
                    dangerColor={commonDropDownSelectedItem !== toggleTitle ? false : warningBorder}
                    placeholderShow={
                      toggleTitle === commonDropDownSelectedItem ? true : false
                    }
                  />
                ) : (
                  <DropDownBtn
                    fullWidth={fullWidth}
                    // onClick={() => setIsActive(!isActive)}
                    btnLabel={commonDropDownSelectedItem}
                    dangerColor={commonDropDownSelectedItem !== toggleTitle ? false : warningBorder}
                    isBtnActive={isActive}
                    basicColor={basicColor}
                    placeholderShow={false}
                  />
                )
              ) : (
                <BtnFillRounded
                  fullWidth={fullWidth}
                  // onClick={() => setIsActive(!isActive)}
                  btnLabel={commonDropDownSelectedItem}
                  isBtnActive={isActive}
                  basicColor={basicColor}
                  dangerColor={commonDropDownSelectedItem !== toggleTitle ? false : warningBorder}
                  btnBgColor={bgColor}
                  btnBorderColor={borderColor}
                  placeholderShow={toggleTitle === commonDropDownSelectedItem ? true : false}
                />
              )}
            </div>
          </Menu.Button>
        </>
      ) : (
        ""
      )}
      {/* Use the Transition component. */}
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
          className="absolute right-0 z-50 mt-2 w-full max-h-s400 overflow-y-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className=" text-black flex flex-col items-start justify-start z-40">
            {useDataObject ?
              dataObject.map((item, index) => (
                withToolTip ?
                  <Menu.Item key={index} className="w-full">
                    {({ active }) => (
                      <button
                        onClick={() => {
                          setCommonDropDownSelectedItem(item.name);
                          setIsActive(false);
                          itemOnClick(item);
                          // onChange(item);
                        }}
                      >
                        <Tooltip onClick={() => setIsActive(false)} title={item?.name} placement="left" >
                          <div className={`${active
                            ? "bg-cBrand text-white rounded-br5"
                            : "bg-white text-cMainBlack"
                            } px-3 drop-shadow-md py-s6 capitalize text-fs16 cursor-pointer w-full truncate text-left`}>
                            {item?.name}
                          </div>
                        </Tooltip>
                      </button>
                    )}
                  </Menu.Item> :
                  <Menu.Item key={index} className="w-full" >
                    {({ active }) => (
                      <button
                        aria-hidden="true"
                        onClick={() => {
                          setCommonDropDownSelectedItem(item.name);
                          setIsActive(false);
                          itemOnClick(item);
                          // onChange(item);
                        }}
                      // className={`${active
                      //   ? "bg-cBrand text-white rounded-br5"
                      //   : "bg-white text-cMainBlack"
                      //   } px-3 drop-shadow-md py-s6 capitalize text-fs16 cursor-pointer max-w-[200px] truncate`}
                      >
                        <div className={`${active
                          ? "bg-cBrand text-white rounded-br5"
                          : "bg-white text-cMainBlack"
                          } px-3 drop-shadow-md py-s6 capitalize text-fs16 cursor-pointer w-full truncate text-left`}>
                          {item?.name}
                        </div>
                      </button>
                    )}
                  </Menu.Item>
              ))
              : data.map((item, index) => (
                <Menu.Item key={index} className="w-full" >
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setCommonDropDownSelectedItem(item.name ? item.name : item);
                        setIsActive(false);
                        itemOnClick(item.value ? item.value : item);
                        // onChange(item);
                      }}
                    // className={`${active
                    //   ? "bg-cBrand text-white"
                    //   : "bg-white text-cMainBlack"
                    //   } px-3 rounded-br5 drop-shadow-md py-s6 capitalize text-fs16 cursor-pointer`}
                    >
                      <div className={`${active
                        ? "bg-cBrand text-white rounded-br5"
                        : "bg-white text-cMainBlack"
                        } px-3 drop-shadow-md py-s6 capitalize text-fs16 cursor-pointer w-full truncate text-left`}>
                        {item.name ? item.name : item}
                      </div>
                    </button>
                  )}
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default CommonDropDown;