/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useSettingsStore, { getGeneralInfo, updateGeneralInfo } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import RichTextEditor from "../../../Components/Input/RichTextEditor";

const DeleteMessage = () => {
  const { t } = useTranslation();

  const { setEditDeleteMessage, editDeleteMessage, generalInfo, setGeneralInfo, privacyEditState, setPrivacyEditState } = useSettingsStore();

  //console.log("editDeleteMessage",editDeleteMessage);

  const handleSubmit = () => {
    if (generalInfo) {
      updateGeneralInfo('delete-message');
    }
  }

  useEffect(() => {
    getGeneralInfo('delete-message');
    setPrivacyEditState(false);
  }, [editDeleteMessage?.lang])

  return (
    <form onSubmit={(e) => e.preventDefault()} className="w-full">
      <div className="flex justify-between pb-5 ">
        <div className="pb-5 text-fs20 font-fw600">{t("Delete Message")}</div>
        {!privacyEditState && <select value={editDeleteMessage?.lang} onChange={(e) => { setEditDeleteMessage({ ...editDeleteMessage, lang: e.target.value }) }}
          className='px-2 py-2 border-2 border-gray-900 rounded-md text-md font-archivo'>
          <option value="en">English</option>
          <option value="ja">日本語</option>
          <option value="zh">中文</option>
          <option value="bn">বাংলা</option>
        </select>}
      </div>
      <div className={`bg-white p-5 rounded-br5 ${privacyEditState ? "h-s400" : ""} drop-shadow-md`}>
        {privacyEditState ? <div className="h-[318px]">
          <RichTextEditor
            placeholder={t("Write your business Privacy & Policy...")}
            value={editDeleteMessage?.info}
            onChange={(e) => { setEditDeleteMessage({ ...editDeleteMessage, info: e }) }}
          />
        </div> :
          <div className="flex">
            <div dangerouslySetInnerHTML={{ __html: editDeleteMessage?.info }}></div>
          </div>
        }
      </div>

      <div className="flex justify-end pt-5">
        {privacyEditState ? <CommonButton btnLabel={t("Save Changes")} type="submit" colorType="primary" onClick={handleSubmit} /> :
          <CommonButton btnLabel={t("Edit")} type="submit" colorType="primary" onClick={() => setPrivacyEditState(true)} />
        }
      </div>
    </form>
  );
};

export default DeleteMessage;
