import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import useLayoutStore from "../../App/Stores/LayoutStore";
import useUtilityStore from "../../App/Stores/UtilityStore";
import useVoucherStore, {
  deleteVoucher,
  getAllVoucher,
  searchVoucherData,
  toggleVoucher
} from "../../App/Stores/VoucherStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import CommonDeactivateModal from "../../Components/Modal/CommonDeactivateModal";
import CommonDeleteConfirmModal from "../../Components/Modal/CommonDeleteConfirmModal";
import CommonTable from "../../Components/Table/CommonTable";
import NoDataRow from "../../Components/Table/NoDataRow";
import VoucherDetails from "./VoucherDetails";
import VoucherTableItem from "./VoucherTableItem";

export default function VoucherTable() {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();
  const {
    voucherList,
    voucherListPagination,
    searchVoucherKey,
    setSearchVoucherKey,
    resetAddVoucherFormData,
    showDeactivateVoucher,
    setShowDeactivateVoucher,
    showDeleteVoucher,
    setShowDeleteVoucher,
    voucherLastPageAdd,
    voucherPaginationUrl,
    setVoucherLastPageAdd,
    voucherDetails,
    setVoucherPaginationUrl,
    voucherTakeAmount
  } = useVoucherStore();

  const VoucherHeaders = [
    { index: 1, name: t("title") },
    { index: 2, name: t("Voucher type") },
    { index: 3, name: t("code") },
    { index: 4, name: t("limit") },
    { index: 5, name: t("expire date") },
    { index: 6, name: t("amount") },
    { index: 7, name: t("Action") },
  ];

  const navigate = useNavigate();

  const [searchValue] = useDebounce(searchVoucherKey, 500);

  useEffect(() => {
    if (searchValue) {
      searchVoucherData(searchValue,"",voucherTakeAmount.take);
    } else {
      setSearchVoucherKey("")
      getAllVoucher(voucherPaginationUrl,voucherTakeAmount.take);
      setVoucherLastPageAdd(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   searchVoucherData(searchValue,"",voucherTakeAmount.take);
  // }, [searchValue]);

  useEffect(() => {
    // set the title of page and top bar
    setBarTitle(t("Voucher"));
    PageTitle(t("Voucher"));
  }, [t])



  return (
    <div>
      <VoucherDetails />

      <CommonDeactivateModal
        showDeactivateModal={showDeactivateVoucher}
        setShowDeactivateModal={setShowDeactivateVoucher}
        messageToShow={t("Are you sure, you want to Deactivate this Voucher?")}
        onConfirm={() => {
          let loadUrl =
            voucherListPagination.path +
            "?page=" +
            voucherListPagination.current_page;

          toggleVoucher(loadUrl);
        }}
      />

      <CommonDeleteConfirmModal
        showDeleteModal={showDeleteVoucher}
        setShowDeleteModal={setShowDeleteVoucher}
        messageToShow={t("Are you sure you want to Delete this Voucher ?")}
        onConfirm={() => {
          let loadUrl =
            voucherListPagination.path +
            "?page=" +
            voucherListPagination.current_page;
          // setVoucherPaginationUrl(loadUrl);
          deleteVoucher(voucherDetails.voucher.id, loadUrl);
        }}
      />

      <CommonTable
      takeComponent={<TakeItem/>}
        addItemTitle={t("add voucher")}
        addItemFunction={() => {
          navigate("add-voucher");
          resetAddVoucherFormData();
        }}
        headers={VoucherHeaders}
        items={
          voucherList?.length
            ? voucherList.map((item, index) => (
              <VoucherTableItem key={index} data={item} />
            ))
            : <NoDataRow columnNumber={VoucherHeaders?.length} />
        }
        showingFrom={voucherListPagination?.from}
        showingTo={voucherListPagination?.to}
        totalResult={voucherListPagination?.total}
        searchValue={searchVoucherKey}
        searchOnChange={(e) => setSearchVoucherKey(e.target.value)}
        onSearchClear={() => {
          setSearchVoucherKey("");
          getAllVoucher();
        }}

        paginationObject={voucherListPagination}
        pagesArray={voucherListPagination.links}
        currentPageNumber={voucherListPagination.current_page}
        prevPageUrl={voucherListPagination.prev_page_url}
        nextPageUrl={voucherListPagination.next_page_url}
        showPagination={voucherListPagination?.last_page !== 1 ? true : false}
        paginationOnClick={(url) => {
          setVoucherPaginationUrl(url)
          if (searchValue) {
            searchVoucherData(searchValue, url,voucherTakeAmount.take)
          } else getAllVoucher(url,voucherTakeAmount.take);
        }}
      />
    </div>
  );
}


const TakeItem = () => {

  const {
    voucherTakeAmount,
    setVoucherTakeAmount,
    searchVoucherKey
  } = useVoucherStore();

  const { setLoading } = useUtilityStore.getState();

  return (
    <div className=''>
      <span className='pr-s10'>Show</span>
      <select id="cars"
         value={voucherTakeAmount.take ? voucherTakeAmount.take : 10}
        onChange={async (e) => {
          setLoading(true);
          await setVoucherTakeAmount("take", e.target.value);
          
          if (searchVoucherKey) {
            await searchVoucherData(searchVoucherKey,"",e.target.value);
          } else {
            await getAllVoucher("",e.target.value)
          }
          setLoading(false);
        }}
        className='pl-2 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={voucherTakeAmount.take === 10}
          value={10}
        >10</option>

        <option
          className='py-s10 text-cHighlightedTexts'
          selected={voucherTakeAmount.take === 25}
          value={25}
        >25</option>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={voucherTakeAmount.take === 50}
          value={50}
        >50</option>
        <option
          className='py-s10 text-cMainBlack'
          selected={voucherTakeAmount.take === 100}
          value={100}
        >100</option>
      </select>   <span className='pl-s10'>Entries</span>
    </div>
  )
}