import React, { useState } from 'react'
import {
    DndContext,
    closestCenter
} from '@dnd-kit/core'

import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
    useSortable
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const Sortable = () => {
    const [data, setData] = useState([
        { name: 'St. Pierre & Miquelon', id: 196 },
        { name: 'Turkey', id: 193 },
        { name: 'Suriname', id: 124 },
        { name: 'Congo - Kinshasa', id: 110 },
    ]);

    const handleDragEnd = (e) => {
        console.log(e);
        const { active, over } = e;

        if (active.id !== over.id) {
            const activeIndex = data.findIndex((item) => item.id === active.id);
            const overIndex = data.findIndex((item) => item.id === over.id);
            setData((item) => {
                return arrayMove(item, activeIndex, overIndex)
            });
        }
    }

    return (
        <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <div className='w-full text-center text-4xl' >Sortable</div>

            <SortableContext
                items={data}
                strategy={verticalListSortingStrategy}
            >
                {
                    data?.map((item, index) => (
                        <SortableItem key={index} title={item.name} id={item.id} />
                    ))
                }
            </SortableContext>
        </DndContext>
    )
}

export default Sortable

const SortableItem = ({ title, id }) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        // transition
    } = useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        // transition
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        >
            <div>{title}: {id}</div>
        </div>
    )
}