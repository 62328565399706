import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useLayoutStore from "../../../App/Stores/LayoutStore";
import useOrderStore, { getAllOrderList } from "../../../App/Stores/OrderStore";
import useShopStore, { getShopDetails, toggleShopActivation } from "../../../App/Stores/ShopStore";
import { BaseUrlSrc } from "../../../App/Utility/Url";
import { PageTitle } from "../../../App/Utility/UtilityFunctions";
import CommonDetailsCard from "../../../Components/Card/CommonDetailsCard";
import BackLink from "../../../Components/Pagination/BackLink";
import CommonTable from "../../../Components/Table/CommonTable";
import NoDataRow from "../../../Components/Table/NoDataRow";
import { NoShop } from "../../../Components/Utility/ImageImports";
import GiftCards from "../../GiftCards/GiftCards";
import DeactivateShop from "../Popup/DeactivateShop";
import DeleteShop from "../Popup/DeleteShop";
import EditShop from "../Popup/EditShop";
import DataBox from "./DataBox";
import ShopGiftCardTable from "./ShopGiftCardTable";
import ShopOrderItem from "./ShopOrderItem";

const ShopDetails = () => {
  const { t } = useTranslation();

  const {
    setShowDeleteModal,
    setShowEditModal,
    setShowDeactivateModal,
    shopDetails,
    setDeleteShopForm,
    setDeactivateShopForm,
    selectedShopID,
    setDataBoxSelection,
    setSelectedShopID
  } = useShopStore();

  const { setBarTitle } = useLayoutStore();

  const location = useLocation();

  useEffect(() => {
    setDataBoxSelection(1);
    if (!shopDetails?.shop?.name) {
      setSelectedShopID(localStorage.getItem("shopID"));
      getShopDetails(localStorage.getItem("shopID"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBarTitle(t("Shop Details"));
    PageTitle(t("Shop Details"));
  }, [t,setBarTitle])


  return (
    <>
      {location.pathname === '/shops/details' && <BackLink linksArray={[
        { label: t("shops"), linkTo: "/shops" },
        { label: t("shop details"), linkTo: "/shops/details" },
      ]} />
      }
      {location.pathname === '/boosted-shop/details' && <BackLink linksArray={[
        { label: t("Boosted Shop"), linkTo: "/boosted-shop" },
        { label: t("Boosted Shop details"), linkTo: "" },
      ]} />
      }

      <DeactivateShop />
      <DeleteShop />
      <EditShop />
      <DetailsSection
        setShowDeleteModal={setShowDeleteModal}
        setShowEditModal={setShowEditModal}
        setShowDeactivateModal={setShowDeactivateModal}
        shopDetails={shopDetails}
        setDeleteShopForm={setDeleteShopForm}
        setDeactivateShopForm={setDeactivateShopForm}
      />
      {/* blue: data boxes */}
      <div className="mt-5 pt-s10 -mb-0 relative bg-white">
        <DataBoxArea shopDetails={shopDetails} />
        <div className="absolute bottom-[2px] w-full bg-white border-b-2 border-cNmSelect z-10"></div>
      </div>

      {/* green: bottom tables */}
      <TableSection />
    </>
  );
};

export default ShopDetails;

function DetailsSection(props) {
  return (
    <div className="mt-[10px] p-5 bg-white rounded-br10 shadow-sm">
      <div className="flex items-start">
        <CommonDetailsCard
          mainImage={props.shopDetails?.shop?.image_url ? BaseUrlSrc + "/" + props.shopDetails?.shop?.image_url : NoShop}
          totalReview={15}
          shopCode={
            props.shopDetails?.shop?.refer_code
              ? props.shopDetails?.shop?.refer_code
              : "NA"
          } // isCustomer={true}
          isActive={props.shopDetails?.shop?.is_active === 1 ? true : false}
          ratingAvg={
            props.shopDetails?.shop?.shop?.rate
              ? props.shopDetails?.shop?.shop?.rate
              : 0
          }
          name={
            props.shopDetails?.shop?.name ? props.shopDetails?.shop?.name : "NA"
          }
          email={
            props.shopDetails?.shop?.email
              ? props.shopDetails?.shop?.email
              : "NA"
          }
          phone={
            props.shopDetails?.shop?.phone
              ? props.shopDetails?.shop?.phone
              : "NA"
          }
          address={
            props.shopDetails?.shop?.address
              ? props.shopDetails?.shop?.address
              : "NA"
          }
          onDeactivate={() => {
            props.setDeactivateShopForm(props.shopDetails?.shop?.shop?.user_id);

            if (props.shopDetails?.shop?.is_active) {
              props.setShowDeactivateModal(true);
            } else {
              toggleShopActivation(1);
            }
          }}
          onDelete={() => {
            props.setShowDeleteModal(true);
            props.setDeleteShopForm(props.shopDetails?.shop?.shop?.user_id);
          }}
          onEdit={() => props.setShowEditModal(true)}
        />
      </div>
    </div>
  );
}

const DataBoxArea = ({ shopDetails }) => {
  const { t } = useTranslation();

  const { dataBoxSelection, setDataBoxSelection } = useShopStore();

  return (
    <div className="flex items-center justify-between overflow-x-auto">
      <DataBox
        key={1}
        isSelected={dataBoxSelection === 1 ? true : false}
        onClick={() => setDataBoxSelection(1)}
        title={t("Total Card")}
        totalNumber={shopDetails?.total_cards}
      />
      <DataBox
        key={2}
        isSelected={dataBoxSelection === 2 ? true : false}
        onClick={() => {
          setDataBoxSelection(2);
          getAllOrderList("", "", localStorage.getItem("shopID"));
        }}
        title={t("Total Order")}
        totalNumber={shopDetails?.total_orders}
      />
      <DataBox
        key={3}
        isSelected={dataBoxSelection === 3 ? true : false}
        onClick={() => {
          setDataBoxSelection(3);
          getAllOrderList("completed", "", localStorage.getItem("shopID"));
        }}
        title={t("Completed Order")}
        totalNumber={shopDetails?.total_complete_orders}
      />
      <DataBox
        key={4}
        isSelected={dataBoxSelection === 4 ? true : false}
        onClick={() => {
          setDataBoxSelection(4);
          getAllOrderList("ongoing", "", localStorage.getItem("shopID"));
        }}
        title={t("Ongoing Order")}
        totalNumber={shopDetails?.total_ongoing_orders}
      />
    </div>
  );
};

const TableSection = () => {
  const { t } = useTranslation();

  const { dataBoxSelection } = useShopStore();
  const { orderList, searchOrderValue, searchOrderList, orderListPagination } =
    useOrderStore();

  const orderHeaders = [
    { index: 1, name: t("Invoice Id") },
    { index: 2, name: t("Customer name") },
    { index: 3, name: t("Customer email") },
    { index: 4, name: t("Customer phone") },
    { index: 5, name: t("order date") },
    { index: 6, name: t("Status") },
  ];

  return (
    <div>
      {/* green: total card */}
      {dataBoxSelection === 1 ? (
        <div className="pt-5 pb-s5 px-s5 bg-white">
          <ShopGiftCardTable />
        </div>
      ) : (
        ""
      )}

      {/* green: total order */}
      {dataBoxSelection === 2 ? (
        <CommonTable
          title={t("total order")}
          headers={orderHeaders}
          items={
            orderList?.length > 0 ?
              orderList?.map((item, index) => (
                <ShopOrderItem
                  orderData={item}
                  key={index}
                  end={orderList?.length - 1}
                  barIndex={index}
                />
              )) : <NoDataRow columnNumber={orderHeaders?.length} />
          }
          addItemModal={<>{/* <AddShop /> */}</>}
          addItemTitle={""}
          showingFrom={orderListPagination.from}
          showingTo={orderListPagination.to}
          totalResult={orderListPagination.total}
          searchValue={searchOrderValue}
          searchOnChange={searchOrderList}
          showPagination={
            orderListPagination?.last_page > 1 ? true : false
          }

          paginationObject={orderListPagination}
          pagesArray={orderListPagination.links}
          currentPageNumber={orderListPagination.current_page}
          prevPageUrl={orderListPagination.prev_page_url}
          nextPageUrl={orderListPagination.next_page_url}
          paginationOnClick={(url) => {
            getAllOrderList("all orders", url, localStorage.getItem("shopID"));
          }}
        />
      ) : (
        ""
      )}

      {/* green: completed order */}
      {dataBoxSelection === 3 ? (
        <CommonTable
          title={t("completed order")}
          headers={orderHeaders}
          items={
            orderList?.length > 0 ?
              orderList?.map((item, index) => (
                <ShopOrderItem
                  orderData={item}
                  key={index}
                  end={orderList?.length - 1}
                  barIndex={index}
                />
              )) : <NoDataRow columnNumber={orderHeaders?.length} />
          }
          addItemModal={<>{/* <AddShop /> */}</>}
          addItemTitle={""}
          showingFrom={orderListPagination.from}
          showingTo={orderListPagination.to}
          totalResult={orderListPagination.total}
          searchValue={searchOrderValue}
          searchOnChange={searchOrderList}
          showPagination={
            orderListPagination?.last_page > 1 ? true : false
          }

          paginationObject={orderListPagination}
          pagesArray={orderListPagination.links}
          currentPageNumber={orderListPagination.current_page}
          prevPageUrl={orderListPagination.prev_page_url}
          nextPageUrl={orderListPagination.next_page_url}
          paginationOnClick={(url) => {
            getAllOrderList("completed", url, localStorage.getItem("shopID"));
          }}
        />
      ) : (
        ""
      )}

      {/* green: ongoing order */}
      {dataBoxSelection === 4 ? (
        <CommonTable
          title={t("ongoing order")}
          headers={orderHeaders}
          items={
            orderList?.length > 0 ?
              orderList?.map((item, index) => (
                <ShopOrderItem
                  orderData={item}
                  key={index}
                  end={orderList?.length - 1}
                  barIndex={index}
                />
              )) : <NoDataRow columnNumber={orderHeaders?.length} />
          }
          addItemModal={<>{/* <AddShop /> */}</>}
          addItemTitle={""}
          showingFrom={orderListPagination.from}
          showingTo={orderListPagination.to}
          totalResult={orderListPagination.total}
          searchValue={searchOrderValue}
          searchOnChange={searchOrderList}
          showPagination={
            orderListPagination?.last_page > 1 ? true : false
          }

          paginationObject={orderListPagination}
          pagesArray={orderListPagination.links}
          currentPageNumber={orderListPagination.current_page}
          prevPageUrl={orderListPagination.prev_page_url}
          nextPageUrl={orderListPagination.next_page_url}
          paginationOnClick={(url) => {
            console.log(url);
            getAllOrderList("ongoing", url, localStorage.getItem("shopID"));
          }}
        />
      ) : (
        ""
      )}

      {/* green: popular card */}
      {dataBoxSelection === 5 ? (
        <div className="pt-10">
          <GiftCards isWithCategory={false} customTitle={t("popular cards")} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
