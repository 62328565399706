import React, { useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import CloseIcon from "../../Images/icon/close-modal.svg";

const CommonModal = ({
  showModal,
  setShowModal,
  mainContent,
  modalTitle = "title goes here",
  subTitle = "",
  singleButton,
  primaryActionButton,
  secondaryActionButton,
  useAutoClose = true,
  withExtraPadding = false,
  widthClass = "w-full md:w-[60vw] lg:w-[55vw] xl:w-[50vw] 2xl:w-[45vw]",
}) => {

  window.scrollTo(0, 0);

  return (
    <div>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="overflow-y-auto fixed inset-0 z-index-120"
          onClose={() => setShowModal(false)}
        >
          <div className={`px-4 min-h-screen text-center opacity-100 bg-cModalDropBg font-poppins ${withExtraPadding ? "py-10" : "py-0"} `}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`inline-block p-5 text-left align-middle bg-white rounded-lg shadow-xl opacity-100 transition-all transform gs-text-main-black ${widthClass}`}
              >
                <div className="flex relative flex-col justify-center items-center">
                  <div
                    onClick={() => setShowModal(false)}
                    className="absolute top-0 right-0 z-50 cursor-pointer"
                  >
                    <img src={CloseIcon} alt="" />
                  </div>
                  <div className="w-full text-center capitalize text-fs24 font-fw600">
                    {modalTitle}
                  </div>
                  <div className="w-full" >{subTitle}</div>
                </div>
                {mainContent}
                {singleButton ? (
                  <div
                    onClick={() => {
                      if (useAutoClose) {
                        setShowModal(false);
                      }
                    }}
                    className="flex justify-center items-center mx-auto w-fit"
                  >
                    {singleButton}
                  </div>
                ) : (
                  ""
                )}
                <div className="flex justify-between items-center">
                  <div onClick={() => setShowModal(false)}>
                    {secondaryActionButton}
                  </div>
                  <div
                    onClick={() => {
                      if (useAutoClose) {
                        setShowModal(false);
                      }
                    }}
                  >
                    {primaryActionButton}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default CommonModal;
