import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFaqStore, { deleteFaq } from '../../../../App/Stores/FaqStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonModal from '../../../../Components/Modal/CommonModal';

const DeleteFaq = () => {
  const { t } = useTranslation();
  const { showDeleteModal, setShowDeleteModal } = useFaqStore();

  return (
    <>
      <CommonModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        
        modalTitle={t("Confirmation")}
        mainContent={
          <div className="flex justify-center items-center py-5 mb-5">
            <div className="text-fs16">
              {t("Are you sure you want to delete the FAQ?")}
            </div>
          </div>
        }
        primaryActionButton={
          <>
            <CommonButton
              onClick={() => { deleteFaq() }}
              colorType="danger"
              btnLabel={t("Yes")}
            />
          </>
        }
        secondaryActionButton={
          <>
            <CommonButton
              // onClick={() => setShowDeleteModal(false)}
              btnLabel={t("No")}
            />
          </>
        }
      />
    </>
  );
};

export default DeleteFaq;
