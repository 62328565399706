import React from "react";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";
import useCategoryStore, {
  updateCategory,
} from "../../../App/Stores/CategoryStore";
import ImageUpload from "../../../Components/Input/ImageUpload";
import { useTranslation } from "react-i18next";

const EditCategory = () => {
  const { t } = useTranslation();

  const {
    showEditModal,
    setShowEditModal,
    editForm,
    changeEditForm,
    setUpdateImg,
    setUpdateName,
  } = useCategoryStore();
  const handleChangeValue = (e) => {
    if (e.target.name === "name") {
      setUpdateName(true);
    }

    changeEditForm(e.target.name, e.target.value);
  };
  const { showEditID } = useCategoryStore();

  const onEditForm = (e) => {
    setShowEditModal(false);
    updateCategory({ categoryId: showEditID });
    // console.log("on Edit ", showEditID);
  };

  console.log("editForm::", editForm);

  return (
    <>
      <CommonModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        
        modalTitle={t("Edit Category")}
        mainContent={
          <div className="py-s20 bg-white">
            <div>
              <CommonInput
                label={t("Category Name")}
                name="name"
                value={editForm.name}
                onChange={handleChangeValue}
              />
              <div className="pb-5"></div>
              <ImageUpload
                btnLabel={t("change image")}
                imgValue={editForm.image}
                customWidth={"w-s100"}
                onChangeFile={(e) => {
                  setUpdateImg(true);
                  changeEditForm("image", e);
                }}
              />
            </div>
          </div>
        }
        singleButton={
          <div>
            <CommonButton
              btnLabel={t("Save Changes")}
              colorType="primary"
              onClick={() => onEditForm()}
            />
          </div>
        }
      />
    </>
  );
};

export default EditCategory;
