import React, { useEffect } from 'react'
import DefaultSelect from '../../Components/Select/DefaultSelect'
import useCategoryStore from '../../App/Stores/CategoryStore';
import CustomSelect from '../../Components/Select/CustomSelect';

const SelectionTest = () => {
    const { categoryListAll } = useCategoryStore();
    let category_array = [];

    useEffect(() => {
        categoryListAll?.map((item, index) => category_array.push({ label: item?.name, value: item?.id }))
    }, []);

    return (
        <div
            onClick={() => {
                console.log("categoryListAll:::", categoryListAll);
                console.log("category_array:::", category_array);
            }}
        >
            <div className='text-4xl'>SelectionTest</div>
            <DefaultSelect
                data={category_array}
            />
            <CustomSelect
                onChange={(e) => {
                    console.log(e.target.value);
                }}
            />
        </div>
    )
}

export default SelectionTest