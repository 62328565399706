/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useSettingsStore, { getGeneralInfo, updateGeneralInfo } from "../../../App/Stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import RichTextEditor from "../../../Components/Input/RichTextEditor";

const PrivacyDetails = () => {
  const { t } = useTranslation();

  const { generalInfo, setEditPrivacyPolicy, privacyEditState, setPrivacyEditState, editPrivacyPolicy } = useSettingsStore();

  console.log(generalInfo.info);

  const handleSubmit = () => {
    if (generalInfo) {
      updateGeneralInfo('privacy-policy');
    }
  }

  useEffect(() => {
    getGeneralInfo('privacy-policy');
    setPrivacyEditState(false);
  }, [editPrivacyPolicy?.lang])

  return (
    <form onSubmit={(e) => e.preventDefault()} className="w-full">
      <div className="flex justify-between pb-5">
        <div className=" text-fs20 font-fw600">{t("Privacy & Policy")}</div>
        {!privacyEditState && <select value={editPrivacyPolicy?.lang}
          onChange={(e) => { setEditPrivacyPolicy({ ...editPrivacyPolicy, lang: e.target.value }) }}
          className='px-2 py-2 border-2 border-gray-900 rounded-md text-md font-archivo'>
          <option value="en">English</option>
          <option value="ja">日本語</option>
          <option value="zh">中文</option>
          <option value="bn">বাংলা</option>
        </select>}
      </div>
      <div className={`bg-white p-5 rounded-br5 ${privacyEditState ? "h-s400" : ""} drop-shadow-md`}>
        {privacyEditState ? <div className="h-[318px]">
          <RichTextEditor
            placeholder={t("Write your business Privacy & Policy...")}
            value={editPrivacyPolicy?.info}
            onChange={(e) => setEditPrivacyPolicy({ ...editPrivacyPolicy, info: e })}
          />
        </div> :
          <div className="flex">
            <div dangerouslySetInnerHTML={{ __html: editPrivacyPolicy?.info }}></div>
          </div>
        }
      </div>

      <div className="flex justify-end pt-5">
        {privacyEditState ? <CommonButton btnLabel={t("Save Changes")} type="submit" colorType="primary" onClick={handleSubmit} /> :
          <CommonButton btnLabel={t("Edit")} type="submit" colorType="primary" onClick={() => setPrivacyEditState(true)} />
        }
      </div>
    </form>
  );
};

export default PrivacyDetails;
