import React from "react";
import { useTranslation } from "react-i18next";
import useShopStore, {
  toggleShopActivation,
} from "../../../App/Stores/ShopStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonModal from "../../../Components/Modal/CommonModal";

const DeactivateShop = () => {
  const { t } = useTranslation();

  const { showDeactivateModal, setShowDeactivateModal, shopListPagination } =
    useShopStore();
  return (
    <div>
      <CommonModal
        showModal={showDeactivateModal}
        setShowModal={setShowDeactivateModal}
        
        modalTitle={t("Confirmation")}
        mainContent={
          <div className="pt-5 pb-[30px] flex justify-center items-center">
            <div>{t("Do you want to Deactivate this shop?")}</div>
          </div>
        }
        primaryActionButton={
          <>
            <CommonButton
              btnLabel={t("Deactivate")}
              colorType="danger"
              onClick={() => {
                let loadUrl =
                  shopListPagination.path +
                  "?page=" +
                  shopListPagination.current_page;
                toggleShopActivation(0, loadUrl);
                setShowDeactivateModal(false);
              }}
            />
          </>
        }
        secondaryActionButton={
          <>
            <CommonButton
              btnLabel={t("Cancel")}
              onClick={() => setShowDeactivateModal(false)}
            />
          </>
        }
      />
    </div>
  );
};

export default DeactivateShop;
