import React from 'react';
import useContactStore, { getContactIndex } from '../../App/Stores/ContactStore';
import { LogSuccess, PastDateFromToday, Toastr } from '../../App/Utility/UtilityFunctions';
import CommonButton from '../../Components/Button/CommonButton';
import CommonInput from '../../Components/Input/CommonInput';
import CommonModal from '../../Components/Modal/CommonModal';
import { iCloseRed } from '../../Components/Utility/ImageImports';

const FilterContactMessage = () => {

    const {
        showFilterMessage,
        setShowFilterMessage,
        customDateShow,
        setCustomDateShow,
        contactFilterForm,
        setContactFilterForm,
        contactSearchKey,
        isFilterMessage,
        setIsFilterMessage,
    } = useContactStore();

    return (
        <>
            <CommonModal
                showModal={showFilterMessage}
                setShowModal={setShowFilterMessage}
                modalTitle="Filter Contact Us"
                widthClass='w-[700px]'

                mainContent={
                    <form onSubmit={(e) => e.preventDefault()} className='text-cChipText'>

                        {/* g: user type */}
                        <div className="pt-5 font-semibold text-fs14">Select User Type</div>
                        <div className="flex pt-s10 space-x-s10">
                            <FilterChip
                                onclick={() => {
                                    console.log("all")
                                    setContactFilterForm({ ...contactFilterForm, status: "" });
                                }}
                                label={"all"}
                                active={contactFilterForm.status === "" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setContactFilterForm({ ...contactFilterForm, status: "customer" });

                                }}
                                label={"customer"}
                                active={contactFilterForm.status === "customer" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setContactFilterForm({ ...contactFilterForm, status: "shop" });

                                }}
                                label={"shop"}
                                active={contactFilterForm.status === "shop" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setContactFilterForm({ ...contactFilterForm, status: "guest" });

                                }}
                                label={"guest"}
                                active={contactFilterForm.status === "guest" ? true : false}
                            />

                            {/* <FilterChip
                                onclick={() => {
                                    console.log("guest")
                                }}
                                label={"guest"}
                            /> */}
                        </div>

                        {/* g: message type */}
                        <div className="pt-5 font-semibold text-fs14">Select Message Type</div>
                        <div className="flex pt-s10 space-x-s10">

                            <FilterChip
                                onclick={() => {
                                    setContactFilterForm({ ...contactFilterForm, is_seen: "" });
                                    console.log("all");
                                }}
                                label={"all"}
                                active={contactFilterForm.is_seen === "" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setContactFilterForm({ ...contactFilterForm, is_seen: "true" });
                                    console.log("read");
                                }}
                                label={"read"}
                                active={contactFilterForm.is_seen === "true" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setContactFilterForm({ ...contactFilterForm, is_seen: "false" });
                                    console.log("unread");
                                }}
                                label={"unread"}
                                active={contactFilterForm.is_seen === "false" ? true : false}
                            />
                        </div>

                        {/* g: reply status */}
                        <div className="pt-5 font-semibold text-fs14">Select Reply Status</div>
                        <div className="flex pt-s10 space-x-s10">

                            <FilterChip
                                onclick={() => {
                                    setContactFilterForm({ ...contactFilterForm, is_reply: "" });
                                    console.log("all");
                                }}
                                label={"all"}
                                active={contactFilterForm.is_reply === "" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setContactFilterForm({ ...contactFilterForm, is_reply: "true" });
                                    console.log("replied");
                                }}
                                label={"replied"}
                                active={contactFilterForm.is_reply === "true" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setContactFilterForm({ ...contactFilterForm, is_reply: "false" });
                                    console.log("not_replied");
                                }}
                                label={"not replied"}
                                active={contactFilterForm.is_reply === "false" ? true : false}
                            />
                        </div>

                        {/* g: select date */}
                        <div className="pt-5 font-semibold text-fs14 pb-s5">Select Date</div>
                        {!customDateShow ? <div className="flex pt-s10 space-x-s10">
                            <FilterChip
                                onclick={() => {
                                    setContactFilterForm({ ...contactFilterForm, date: "" });
                                    console.log("all");
                                }}
                                label={"all"}
                                active={contactFilterForm.date === "" ? true : false}
                            />

                            <FilterChip
                                // weekly filter = current date -7
                                onclick={() => {
                                    setContactFilterForm({ ...contactFilterForm, date: "weekly", end_date: PastDateFromToday(0), start_date: PastDateFromToday(7) });
                                    console.log("weekly");
                                }}
                                label={"weekly"}
                                active={contactFilterForm.date === "weekly" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setContactFilterForm({ ...contactFilterForm, date: "monthly", end_date: PastDateFromToday(0), start_date: PastDateFromToday(30) });
                                    console.log("monthly");
                                }}
                                label={"monthly"}
                                active={contactFilterForm.date === "monthly" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setContactFilterForm({ ...contactFilterForm, date: "custom" });
                                    setCustomDateShow(true)
                                }}
                                label={"custom date"}
                            />
                        </div>
                            :
                            <div className='flex justify-between items-center w-full bg-cLine rounded-br10'>
                                <div className='p-5 rounded-full'>
                                    <CommonInput
                                        required={customDateShow ? true : false}
                                        type='date'
                                        value={contactFilterForm.start_date}
                                        startDate={"1901-01-01"}
                                        label="start date"
                                        allowPastDates={true}
                                        onChange={(e) => {
                                            // console.log((e.target.value));
                                            const startDate = new Date(e.target.value);
                                            const endDate = new Date(contactFilterForm.end_date);

                                            // console.log("startDate::::", startDate, "; endDate::::", endDate);

                                            if (startDate > endDate) {
                                                setContactFilterForm({ ...contactFilterForm, end_date: "", start_date: e.target.value });
                                            } else {
                                                setContactFilterForm({ ...contactFilterForm, start_date: e.target.value });
                                            }
                                            // setContactFilterForm({ ...contactFilterForm, start_date: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className='flex items-center'>
                                    <div className='pr-s15'>
                                        <CommonInput
                                            required={customDateShow ? true : false}
                                            type='date'
                                            label="end date"
                                            value={contactFilterForm.end_date}
                                            // disabled={!contactFilterForm.start_date}
                                            startDate={contactFilterForm.start_date ? contactFilterForm?.start_date : "1901-01-01"}
                                            onChange={(e) => {
                                                // console.log(e.target.value);
                                                setContactFilterForm({ ...contactFilterForm, end_date: (e.target.value) });
                                            }}
                                        />
                                    </div>
                                    <img
                                        onClick={() => {
                                            setContactFilterForm({ ...contactFilterForm, start_date: "", end_date: (""), date: "" });
                                            setCustomDateShow(false)
                                        }}
                                        src={iCloseRed}
                                        alt=""
                                        className='cursor-pointer pr-s15 w-s40 h-s30'
                                    />
                                </div>
                            </div>
                        }

                        <div className="flex justify-between pt-5">
                            <CommonButton
                                onClick={async () => {
                                    setCustomDateShow(false);
                                    await setContactFilterForm({
                                        status: "",
                                        is_reply: "",
                                        is_seen: "",
                                        date: "",
                                        custom_date: "",
                                        start_date: "",
                                        end_date: "",
                                    });
                                    let filterSuccess = await getContactIndex("", contactSearchKey, {});
                                    if (filterSuccess) {
                                        Toastr({ message: "All filters cleared", type: "success" });                                        
                                        setShowFilterMessage(false);
                                        setIsFilterMessage(false);
                                    }
                                }}
                                fullRounded={true}
                                btnLabel='Clear'
                                colorType="FilterClearButton"
                                text="fs16"
                            />
                            <CommonButton
                                onClick={async () => {
                                    LogSuccess("Filter form: contact us:", contactFilterForm);
                                    if (!isFilterMessage) setIsFilterMessage(true);
                                    if (customDateShow) {
                                        if (contactFilterForm.start_date && contactFilterForm.end_date) {
                                            Toastr({ message: "Your filter successfully Apply", type: "success" });
                                            let filterSuccess = await getContactIndex("", contactSearchKey, contactFilterForm);
                                            if (filterSuccess) setShowFilterMessage(false);
                                        }
                                    } else {
                                        let filterSuccess = await getContactIndex("", contactSearchKey, contactFilterForm);
                                        if (filterSuccess) {
                                            Toastr({ message: "Your filter successfully Apply", type: "success" });
                                            // setContactUsSearch("")
                                            setShowFilterMessage(false);
                                        }
                                    }

                                }}
                                type="submit"
                                fullRounded={true}
                                btnLabel='apply'
                                colorType='primary'
                                roundedFull="true" />
                        </div>

                    </form>
                }
            />
        </>
    )
}

export default FilterContactMessage

const FilterChip = ({ label, active = false, onclick }) => {

    return (
        <div
            onClick={onclick}
            className={`
                capitalize rounded-full border border-cChipBorder hover:border-cBrand py-s5 px-5 text-medium hover:text-white hover:bg-cBrand select-none cursor-pointer
                ${active ? "text-white bg-cBrand border-cBrand" : "bg-white text-cChipText border-cChipBorder"}
            `}
        >
            {label}
        </div>
    )
}
