/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import { getAllCategory } from "../../App/Stores/CategoryStore";
import useGiftCardStore, {
  deleteGiftCard,
  getAllGiftCard,
  searchGiftCards,
} from "../../App/Stores/GiftCardStore";
import useLayoutStore from "../../App/Stores/LayoutStore";
import useUtilityStore from "../../App/Stores/UtilityStore";
import { PageTitle } from "../../App/Utility/UtilityFunctions";
import AddBtnOutlined from "../../Components/Button/AddBtnOutlined";
import Card from "../../Components/Card/Card";
// import CommonDropDown from "../../Components/DropDown/CommonDropDown";
import AutoPaginate from "../../Components/Pagination/AutoPaginate";
import CommonSearchBox from "../../Components/SearchBox/CommonSearchBox";
import AddGiftCard from "./Popup/AddGiftCard";
import DeleteGiftCard from "./Popup/DeleteGiftCard";
import EditGiftCard from "./Popup/EditGiftCard";
import DefaultSelect from "../../Components/Select/DefaultSelect";

const GiftCards = ({
  isWithAddBtn = true,
  isWithCategory = true,
  showPagination = true,
}) => {
  const { t } = useTranslation();

  const { setBarTitle } = useLayoutStore();

  const {
    setAddingNewGiftCard,
    searchGiftCardsKey,
    setSearchGiftCardsKey,
    giftCardListAll,
    giftCardListPagination,
    selectedCategoryIDs,
    setSelectedCategoryIDs,
    setShowDeleteModal,
    gCategoryList,
    giftCardTakeAmount,
    setGiftCardPaginationUrl,
    giftCardPaginationUrl
  } = useGiftCardStore();

  const { setCommonDropDownSelectedItem } = useUtilityStore();

  const [dataList, setDataList] = useState([]);

  async function getDataReady() {
    await getAllCategory();
    // setSelectedCategoryIDs("");

    let temp = []

    temp.unshift({
      value: 0,
      label: "All Category"
    })
    await gCategoryList.map((item) =>
      temp.push({ value: item.id, label: item.name })
    )
    setDataList(temp);
  }

  useEffect(() => {
    setBarTitle(t("Gift Cards"));
    PageTitle(t("Gift Cards"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    if (dataList.length <= 1) {
      getDataReady();
    }
  }, [gCategoryList]);


  // useEffect(() => {
  // console.log("paginationLinks::: ", paginationLinks.length);
  // }, [paginationLinks]);

  const [searchValue] = useDebounce(searchGiftCardsKey, 500);

  useEffect(() => {
    if (searchValue) {
      searchGiftCards(searchValue, giftCardPaginationUrl, giftCardTakeAmount.take);
    } else {
      // if (giftCardListPagination.current_page) {
      //   let urlToLoad = giftCardListPagination.path + "?page=" + giftCardListPagination.current_page;
      //   getAllGiftCard("", urlToLoad, giftCardTakeAmount.take);
      // } else {
      getAllGiftCard(selectedCategoryIDs, giftCardPaginationUrl, giftCardTakeAmount.take);
      // }
    }
  }, [searchValue]);


  return (
    <div className="">
      <AddGiftCard />
      <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 justify-between lg:items-center">
        <CommonSearchBox
          withClearSearch={true}
          onSearchClear={async () => {
            await setSearchGiftCardsKey("");
            await setGiftCardPaginationUrl("")
            await setCommonDropDownSelectedItem("all category")
            await setSelectedCategoryIDs("")
            await getAllGiftCard();
          }}
          value={searchGiftCardsKey}
          onChange={async (e) => {
            await setSearchGiftCardsKey(e.target.value)
            await setGiftCardPaginationUrl("")
            await setSelectedCategoryIDs("")
            await setCommonDropDownSelectedItem("all category")
          }}
        />
        <div className="flex flex-col space-y-5 sm:space-y-0 sm:flex-row sm:space-x-3 sm:items-center">
          <TakeItem />
          {isWithAddBtn === true ? (
            <AddBtnOutlined
              btnLabel={t("Add Gift Card")}
              colorType="primary"
              onClick={() => setAddingNewGiftCard(true)}
            />
          ) : (
            ""
          )}


          {<div className="z-40" >
            {dataList.length > 0 ?

              <DefaultSelect
                colorType="danger"
                placeholder="Filter by Category"
                data={dataList}
                withPlaceholder={false}

                value={selectedCategoryIDs}
                onChange={(e) => {
                  console.log('category selected:::::::', e.target.value);
                  setSelectedCategoryIDs(e.target.value);
                  setSearchGiftCardsKey("")
                  if (e.target.value === 0 || e.target.value === '0') {
                    getAllGiftCard("", "");
                    // navigateTo("/gift-cards")
                  } else {
                    getAllGiftCard(e.target.value, "");
                    // setGiftCardFilterCategory(e.target.value)
                    // navigateTo("/gift-cards/filter/" + e.target.value)
                  }
                }}
              />

              // <CommonDropDown
              //   withToolTip={true}
              //   toggleTitle={"all category"}
              //   useDataObject={true}
              //   dataObject={dataList}
              //   itemOnClick={(e) => {
              //     console.log(e);
              //     setSelectedCategoryIDs(e.id);
              //     //tanmoy
              //     setSearchGiftCardsKey("")
              //     if (e.id === 0) {
              //       getAllGiftCard();
              //       // navigateTo("/gift-cards")
              //     } else {
              //       getAllGiftCard(e.id, "");
              //       // setGiftCardFilterCategory(e.id)
              //       // navigateTo("/gift-cards/filter/" + e.id)
              //     }
              //   }}
              // />
              : ""}
          </div>}
        </div>
      </div>

      {/* show all gift cards in a grid system responsive */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-s20 mt-s30">
        {giftCardListAll?.length > 0
          ? giftCardListAll?.map((card, index) => (
            <Card key={index} data={card} />
          ))
          : ""}
      </div>

      <div className="flex justify-between items-center pt-5">
        {showPagination ? (
          <div className="text-sm">
            {giftCardListPagination?.total > 0 ? (
              <span>
                {t("Showing")} {giftCardListPagination?.from} {t("to")}{" "}
                {giftCardListPagination?.to}, {t("out of")}{" "}
                {giftCardListPagination?.total} {t("results.")}
              </span>
            ) : (
              <span>{t("No results found.")}</span>
            )}
          </div>
        ) : (
          ""
        )}
        {giftCardListPagination?.last_page !== 1 ? (
          <div className="">
            <AutoPaginate
              paginationObject={giftCardListPagination}
              paginationOnClick={(url) => {
                setGiftCardPaginationUrl(url)
                console.log(url);
                if (selectedCategoryIDs) {
                  getAllGiftCard(selectedCategoryIDs, url, giftCardTakeAmount.take);
                } else if (searchValue) {
                  searchGiftCards(searchValue, url, giftCardTakeAmount.take);
                } else {
                  getAllGiftCard("", url, giftCardTakeAmount.take);
                }
              }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <EditGiftCard />
      <DeleteGiftCard
        OnConfirmDelete={() => {
          // console.log("giftCardListPagination:::", giftCardListPagination);
          let loadUrl = giftCardListPagination.path + "?page=" + giftCardListPagination.current_page;
          // console.log(loadUrl);
          let delSuccess = deleteGiftCard(loadUrl);
          if (delSuccess) {
            setShowDeleteModal(false);
          }
        }}
      />
    </div>
  );
};

export default GiftCards;


const TakeItem = () => {

  const {
    giftCardTakeAmount,
    setGiftCardTakeAmount,
    searchGiftCardsKey,
    setGiftCardPaginationUrl
  } = useGiftCardStore();

  const { setLoading } = useUtilityStore.getState();

  return (
    <div className=''>
      <span className='pr-s10'>Show</span>
      <select id="cars"
        value={giftCardTakeAmount.take ? giftCardTakeAmount.take : 15}
        onChange={async (e) => {
          setLoading(true);
          await setGiftCardTakeAmount("take", e.target.value);
          await setGiftCardPaginationUrl("")
          if (searchGiftCardsKey) {
            await searchGiftCards(searchGiftCardsKey, "", e.target.value);
          } else {
            await getAllGiftCard("", "", e.target.value)
          }
          setLoading(false);
        }}
        className='pl-2 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={giftCardTakeAmount.take === 15}
          value={15}
        >15</option>

        <option
          className='py-s10 text-cHighlightedTexts'
          selected={giftCardTakeAmount.take === 25}
          value={25}
        >25</option>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={giftCardTakeAmount.take === 50}
          value={50}
        >50</option>
        <option
          className='py-s10 text-cMainBlack'
          selected={giftCardTakeAmount.take === 100}
          value={100}
        >100</option>
      </select>   <span className='pl-s10'>Entries</span>
    </div>
  )
}