import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import UseNotificationStore, { getNotificationDetails, getNotificationIndex } from "../../App/Stores/NotificationStore";
import { formatDate } from "../../App/Utility/UtilityFunctions";

export default function NotificationDropDown() {
  const { notificationIndex, showNotificationLoading } = UseNotificationStore();

  const { t } = useTranslation();

  useEffect(() => {
    fetchNotification();
  }, []);

  const fetchNotification = async () => {
    await getNotificationIndex("", true);
  }
  return (
    <div className="bg-white p-p10 rounded-br10 w-full">

      <div className="flex justify-between w-full">
        <div className="font-fw600 pb-s5">{t("Notifications")}</div>
        <Link className="text-cMainBlue text-fs14 font-semibold underline" to={"/notifications"}>{t("See All")}</Link>
      </div>

      <div className="overflow-y-auto h-[350px]">
        {
          showNotificationLoading ?
            <Box className="top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute  w-[100px] h-[100px] max-w-3xl px-5 py-5 my-5 text-left align-middle transition-all transform  outline-none  rounded-lg flex flex-col justify-center items-center">
              <RotatingLines
                width="100"
                strokeColor="#FB607F"
                strokeWidth={4}
                strokeWidthSecondary={3}
              />
            </Box> :

            notificationIndex?.data?.map((item, index) =>
              index < 5 ? <NotificationItem key={index} data={item} index={index} startPoint={notificationIndex?.from} /> : ""
            )
        }
      </div>
    </div>
  );
}

function NotificationItem({ data, index }) {
  const { setShowNotificationDetails } = UseNotificationStore();

  return (
    <div
      onClick={async () => {
        // return console.log(data);
        await getNotificationDetails(data?.id);
        setShowNotificationDetails(true);
      }}
      className="my-s5 p-s10 bg-cGridView rounded-br5 flex items-center cursor-pointer">
      <div className="mr-s5 w-2">
        {data?.is_seen === 0 ? <div className="w-2 h-2 rounded-full bg-cBrand"></div> : ""}
      </div>
      <div>
        <div className="font-fw500">
          {data?.title}
        </div>
        <div className="text-cTextBody">{formatDate(data?.created_at)}</div>
      </div>
    </div>
  );
}
