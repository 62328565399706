import { Link, useLocation } from "react-router-dom";
import useLayoutStore from "../../App/Stores/LayoutStore";

// pink: icons and logos
import {
  BoostIconNormal,
  BoostIconSelected, CategoryNormal, CategorySelected, ContactUsNormal, ContactUsSelected, CustomerNormal,
  CustomerSelected,
  DashboardNormal,
  DashboardSelected, DownArrowWhite, FeesIconNormal, FeesIconSelected, GiftNormal,
  GiftSelected, LanguageNormal, LanguageSelected, LeftArrow,
  LogoutIcon, ManageFAQNormal,
  ManageFAQSelected, NotificationNormal, NotificationSelected, OrderNormal,
  OrderSelected, PrivacyIconNormal, PrivacyIconSelected, RecommendedIconNormal, RecommendedIconSelected, RightArrow,
  SettingsNormal,
  SettingsSelected,
  ShopNormal,
  ShopSelected, TermsIconNormal, TermsIconSelected, VerifyIdentityReqNormal,
  VerifyIdentityReqSelect, VoucherIconNormal,
  VoucherIconSelected
} from "../Utility/ImageImports";

// blue: components
import { Tooltip } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useCustomerStore from "../../App/Stores/CustomerStore";
import useGiftCardStore from "../../App/Stores/GiftCardStore";
import UseTestStore from "../../App/Stores/TestStore";
import {
  GIFTY_LOGO, iAPP_LOGO_SM
} from "../../App/Utility/source";
import ChangePassword from "../../Pages/Settings/Details/ChangePassword";
import CommonModal from "../Modal/CommonModal";
import NormalSideBarItem from "./NormalSideBarItem";
import useVerifyIdentityStore from "../../App/Stores/VerifyIdentityStore";
import useVoucherStore from "../../App/Stores/VoucherStore";
import useShopStore from "../../App/Stores/ShopStore";
import useOrderStore from "../../App/Stores/OrderStore";
import useBoostedStore from "../../App/Stores/BoostedStore";

const Sidebar = ({ isOpenSidebar, setIsSidebarOpen }) => {
  const { t } = useTranslation();
  const location = useLocation();

  // green: initialize modal states from zustand
  const { showEditPassModal, setShowEditPassModal } = UseTestStore();
  const { setSearchCustomerKey, setCustomerTakeAmount, setCustomerPaginationUrl } = useCustomerStore();
  const { setSearchGiftCardsKey, setGiftCardPaginationUrl, setGiftCardTakeAmount, setSelectedCategoryIDs } = useGiftCardStore();
  const {
    setVerifyIdentityPaginationUrl,
    setVerifyIdentityStatus,
    setVerifyIdentitySearchValue,
    setVerifyIdentityDropDownValue
  } = useVerifyIdentityStore()
  const {
    setShowLogoutModal,
    setExpandRole,
    activeSection,
    setActiveSection,
    expandSettings,
    setExpandSettings,
  } = useLayoutStore();

  const { setVoucherTakeAmount, setSearchVoucherKey, setVoucherPaginationUrl } = useVoucherStore();
  const { setShopTakeAmount, setShopPaginationUrl, setSearchKey } = useShopStore();
  const { setSearchOrderValue, setPaginationUrl, setOrderTakeAmount } = useOrderStore();
  const { setBoostShopPaginationUrl, setSearchShopBoost, setBoostShopTakeAmount } = useBoostedStore();

  useEffect(() => {
    if (location.pathname) {
      switch (location.pathname) {
        case "/":
          setActiveSection("dashboard");
          break;
        // blue: customer
        case "/customers":
          setActiveSection("customers");
          break;
        case "/customers/customer-details":
          setActiveSection("customers");
          break;
        case "/customers/verification-history":
          setActiveSection("customers");
          break;
        case "/shops":
          setActiveSection("shops");
          break;
        case "/shops/details":
          setActiveSection("shops");
          break;
        case "/category":
          setActiveSection("category");
          break;
        // green: gift-cards
        case "/gift-cards":
          setActiveSection("gift-cards");
          break;
        case "/gift-card-details":
          setActiveSection("gift-cards");
          break;
        case "/orders":
          setActiveSection("orders");
          break;

        // green: verify Identity
        case "/verify/identity":
          setActiveSection("verifyIdentity");
          break;
        case "/verify/identity/details":
          setActiveSection("verifyIdentity");
          break;

        // blue: voucher
        case "/voucher":
          setActiveSection("voucher");
          break;
        case "/voucher/no-voucher":
          setActiveSection("voucher");
          break;
        case "/voucher/add-voucher":
          setActiveSection("voucher");
          break;

        // green: role
        case "/role":
          setActiveSection("role");
          break;
        case "/role/manage":
          setActiveSection("role");
          break;
        case "/role/manage/admins":
          setActiveSection("role");
          break;
        case "/role/manage/no-role-available":
          setActiveSection("role");
          break;

        // blue: boosted-shop
        case "/boosted-shop":
          setActiveSection("boosted-shop");
          break;
        case "/boosted-shop/add-shop":
          setActiveSection("boosted-shop");
          break;
        case "/boosted-shop/no-shop":
          setActiveSection("boosted-shop");
          break;

        // yellow: logs
        case "/logs":
          setActiveSection("logs");
          break;

        // red: settings
        case "/settings/faq":
          setActiveSection("settings");
          break;
        case "/settings/language-setup":
          setActiveSection("settings");
          break;
        case "/settings/privacy-policy":
          setActiveSection("settings");
          break;
        case "/settings/terms-condition":
          setActiveSection("settings");
          break;
        case "/settings/about-us":
          setActiveSection("settings");
          break;
        case "/settings/delete-message":
          setActiveSection("settings");
          break;
        case "/settings/account-settings":
          setActiveSection("settings");
          break;
        case "/settings/change-password":
          setActiveSection("settings");
          break;
        case "/settings/authority-fee":
          setActiveSection("settings");
          break;
        case "/settings/fees":
          setActiveSection("settings");
          break;
        case "/settings/shipping-fee":
          setActiveSection("settings");
          break;
        case "/settings/voucher":
          setActiveSection("settings");
          break;
        case "/settings/logs":
          setActiveSection("settings");
          break;
        case "/settings/role-management":
          setActiveSection("settings");
          break;
        case "/settings/recommended-categories":
          setActiveSection("settings");
          break;
        case "/contact-message":
          setActiveSection("contactUs");
          break;
        case "/notifications":
          setActiveSection("notifications");
          break;

        // pink: test
        case "/test":
          setActiveSection("test");
          break;
        default:
          setActiveSection("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (activeSection && activeSection !== "role") {
      setExpandRole(false);
    } else if (activeSection && activeSection === "role") {
      setExpandRole(true);
    }
    if (activeSection && activeSection !== "settings") {
      setExpandSettings(false);
    } else if (activeSection && activeSection === "settings") {
      setExpandSettings(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection]);

  return (
    <>
      {/* change pass */}
      <CommonModal
        modalTitle={t("Change Password")}
        showModal={showEditPassModal}
        setShowModal={setShowEditPassModal}
        mainContent={
          <>
            <ChangePassword />
          </>
        }
      />

      {/* green: Main side bar list items */}
      <aside
        className={`${isOpenSidebar ? "w-72" : "w-s100"
          } fixed top-0 bottom-0 z-index-120 h-full md:shadow-md transform transition-all 
          duration-150 ease-in bg-cSidebarDarkBg overflow-y-auto`}
      >
        <div
          className={`flex flex-col bg-cSidebarDarkBg ${isOpenSidebar ? "px-5 " : " items-center"
            } select-none outline-none relative`}
        >
          {/* blue:  brand logo */}
          <Link
            to="/"
            className={`${isOpenSidebar ? "mx-auto mt-10" : "mt-5"}`}
          >
            <img
              // onClick={() => navigateTo("/")}
              src={isOpenSidebar === true ? GIFTY_LOGO : iAPP_LOGO_SM}
              alt="BrandLogo"
              className={`${isOpenSidebar ? "w-s120" : "w-s30"} cursor-pointer`}
            />
          </Link>

          {/* green: expand btn */}
          <div
            onClick={() => {
              setIsSidebarOpen(!isOpenSidebar);
            }}
            className={`w-5 h-5 rounded-br3 absolute right-0 top-s90 cursor-pointer bg-cBrand`}
          >
            <img src={isOpenSidebar ? LeftArrow : RightArrow} alt="" />
          </div>

          <div className="pt-s60"></div>

          {/* green: dashboard */}
          <NormalSideBarItem
            onClick={() => setActiveSection("dashboard")}
            title={t("dashboard")}
            linkTo="/"
            isActiveLink={activeSection === "dashboard" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={DashboardNormal}
            selectedIcon={DashboardSelected}
          />

          {/* green: customer */}
          <NormalSideBarItem
            onClick={() => {
              setActiveSection("customers");
              if (location.pathname !== '/customers') {
                setSearchCustomerKey("");
                setCustomerTakeAmount("take", 10)
                setCustomerPaginationUrl("url", "")
              }
            }}
            title={t("customers")}
            linkTo="/customers"
            isActiveLink={activeSection === "customers" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={CustomerNormal}
            selectedIcon={CustomerSelected}
          />

          {/* green: shop */}
          <NormalSideBarItem
            onClick={() => {
              setActiveSection("shops");
              if (location.pathname !== '/shops') {
                setShopTakeAmount("take", 10)
                setShopPaginationUrl("")
                setSearchKey("")
              }
            }}
            title={t("shops")}
            linkTo="/shops"
            isActiveLink={activeSection === "shops" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={ShopNormal}
            selectedIcon={ShopSelected}
          />

          {/* green: category */}
          <NormalSideBarItem
            onClick={() => setActiveSection("category")}
            title={t("category")}
            linkTo="/category"
            isActiveLink={activeSection === "category" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={CategoryNormal}
            selectedIcon={CategorySelected}
          />

          {/* green: gift cards */}
          <NormalSideBarItem
            onClick={() => {
              setActiveSection("gift-cards");
              if (location.pathname !== '/gift-cards') {
                setSearchGiftCardsKey('');
                setGiftCardPaginationUrl("");
                setGiftCardTakeAmount("take", 15)
                setSelectedCategoryIDs("")
              }

            }}
            title={t("gift cards")}
            linkTo="/gift-cards"
            isActiveLink={activeSection === "gift-cards" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={GiftNormal}
            selectedIcon={GiftSelected}
          />

          {/* green: orders */}
          <NormalSideBarItem
            onClick={() => {
              setActiveSection("orders")
              if (location.pathname !== '/orders') {
                setSearchOrderValue("")
                setPaginationUrl("")
                setOrderTakeAmount("take", 10);
              }
            }}
            title={t("orders")}
            linkTo="/orders"
            isActiveLink={activeSection === "orders" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={OrderNormal}
            selectedIcon={OrderSelected}
          />

          {/* green: voucher */}
          <NormalSideBarItem
            onClick={() => {
              setActiveSection("voucher");
              if (location.pathname !== '/voucher') {
                setVoucherTakeAmount("take", 10)
                setSearchVoucherKey("");
                setVoucherPaginationUrl("")
              }

            }}
            title={t("voucher")}
            linkTo="/voucher"
            isActiveLink={activeSection === "voucher" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={VoucherIconNormal}
            selectedIcon={VoucherIconSelected}
          />

          {/* green: verify identity */}
          <NormalSideBarItem
            onClick={() => {
              setActiveSection("verifyIdentity")
              setVerifyIdentityPaginationUrl("")
              setVerifyIdentityStatus('pending')
              setVerifyIdentitySearchValue('')
              setVerifyIdentityDropDownValue("value", "")
            }}
            title={t("Verify Identity")}
            linkTo="/verify/identity"
            isActiveLink={activeSection === "verifyIdentity" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={VerifyIdentityReqNormal}
            selectedIcon={VerifyIdentityReqSelect}
          />

          {/* green: contact us */}
          <NormalSideBarItem
            onClick={() => setActiveSection("contactUs")}
            title={t("Contact Us")}
            linkTo="/contact-message"
            isActiveLink={activeSection === "contactUs" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={ContactUsNormal}
            selectedIcon={ContactUsSelected}
          />

          {/* green: notifications */}
          <NormalSideBarItem
            onClick={() => setActiveSection("notifications")}
            title={t("Notifications")}
            linkTo="/notifications"
            isActiveLink={activeSection === "notifications" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={NotificationNormal}
            selectedIcon={NotificationSelected}
          />

          {/* green: role */}
          {/* todo: after 7th august */}
          {/* <div
            className={`
            transition-height duration-500
            mt-s15 overflow-hidden
            ${isOpenSidebar ? "rounded-br25" : "rounded-none"}
            ${expandRole === true ? "h-s150  bg-cSideBarDropColor" : "h-s50"} `}
          >
            <div className="cursor-pointer">
              <div
                onClick={() => {
                  setExpandRole(!expandRole);
                  setActiveSection("role");
                }}
                className={`flex items-center h-s50 
                  ${
                    activeSection === "role"
                      ? "bg-cBrand text-white"
                      : "bg-cSidebarDarkBg text-cSidebarText"
                  }
                  ${
                    isOpenSidebar
                      ? "pl-s5 rounded-full justify-between "
                      : "justify-center w-20 rounded-none"
                  }
                `}
              >
                <div className="flex items-center">
                  <div
                    className={`flex justify-center items-center rounded-full ${
                      activeSection === "role"
                        ? "bg-cWhite"
                        : "bg-cSidebarDarkBg "
                    }   ${
                      isOpenSidebar
                        ? "ml-s7 my-s7 p-s5 w-s30 h-s30 "
                        : "p-2 w-s30 h-s30"
                    }`}
                  >
                    <img
                      className="object-cover w-s16 h-s16"
                      src={
                        activeSection === "role"
                          ? RoleIconSelected
                          : RoleIconNormal
                      }
                      alt=""
                    />
                  </div>
                  {isOpenSidebar ? (
                    <div className="pl-5 font-semibold text-left text-fs14 ">
                      Role
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {isOpenSidebar ? (
                  <div className="pr-5">
                    <img
                      src={DownArrowWhite}
                      alt=""
                      className={`${
                        expandRole === false ? "rotate-0" : "rotate-180"
                      } transition-all duration-500`}
                    />
                  </div>
                ) : (
                  ""
                )}
                {!isOpenSidebar ? (
                  <div
                    className={`rounded-full w-s5 h-s5 ml-1 ${
                      activeSection === "role" ? "bg-white" : "bg-cIconColor"
                    }`}
                  ></div>
                ) : (
                  ""
                )}
              </div>

              <Link
                to="/role/manage"
                className={`flex items-center py-5 ${
                  isOpenSidebar ? "pl-5" : "justify-center"
                }`}
              >
                <img
                  src={
                    location.pathname === "/role/manage"
                      ? ManageRoleSelected
                      : ManageRoleNormal
                  }
                  alt=""
                  className="object-cover w-s14 h-s14"
                />
                {isOpenSidebar ? (
                  <div
                    className={`${
                      location.pathname === "/role/manage"
                        ? "text-white font-fw600"
                        : "text-cIconColor"
                    } text-fs14 pl-s10`}
                  >
                    Manage Role
                  </div>
                ) : (
                  ""
                )}
              </Link>

              <Link
                to="/role/manage/admins"
                className={`flex items-center ${
                  isOpenSidebar ? "pl-5" : "justify-center"
                }`}
              >
                <img
                  src={
                    location.pathname === "/role/manage/admins"
                      ? ManageAdminSelected
                      : ManageAdminNormal
                  }
                  alt=""
                  className="object-cover w-s14 h-s14"
                />
                {isOpenSidebar ? (
                  <div
                    className={`${
                      location.pathname === "/role/manage/admins"
                        ? "text-white font-fw600"
                        : "text-cIconColor"
                    } text-fs14 pl-s10`}
                  >
                    Admins
                  </div>
                ) : (
                  ""
                )}
              </Link>
            </div>
          </div> */}

          {/* green: boosted-shop */}
          <NormalSideBarItem
            onClick={() => {
              setActiveSection("boosted-shop")

              if (location.pathname !== "boosted-shop") {
                setBoostShopPaginationUrl("")
                setSearchShopBoost("")
                setBoostShopTakeAmount("take", 10)
              }
            }}
            title={t("boosted shop")}
            linkTo="/boosted-shop"
            isActiveLink={activeSection === "boosted-shop" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={BoostIconNormal}
            selectedIcon={BoostIconSelected}
          />

          {/* green: logs */}
          {/* todo after 7th august */}
          {/* <NormalSideBarItem
            onClick={() => setActiveSection("logs")}
            title={"logs"}
            linkTo="/logs"
            isActiveLink={activeSection === "logs" ? true : false}
            isOpenSidebar={isOpenSidebar}
            normalIcon={LogIconNormal}
            selectedIcon={LogIconSelected}
          /> */}

          {/* green: settings */}
          <div
            className={`
            transition-height duration-500
            mt-s15 overflow-hidden capitalize
            ${isOpenSidebar ? "rounded-br25" : "rounded-none w-20"}
            ${expandSettings === true
                ? isOpenSidebar
                  ? "h-[355px] bg-cSideBarDropColor"
                  : "h-[340px] bg-cSideBarDropColor"
                : "h-s50"
              } `}
          >
            <div className="cursor-pointer">
              <div
                onClick={() => {
                  setExpandSettings(!expandSettings);
                  setActiveSection("settings");
                }}
                className={`flex items-center h-s50 ${activeSection === "settings"
                  ? "bg-cBrand text-white"
                  : "bg-cSidebarDarkBg text-cSidebarText"
                  }
            ${isOpenSidebar
                    ? "pl-s5  rounded-full justify-between "
                    : "justify-center"
                  }
            `}
              >
                <Tooltip
                  title={isOpenSidebar ? "" : "Settings"}
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        textTransform: "capitalize"
                      }
                    }
                  }}
                >
                  <div className="flex items-center ">
                    <div
                      className={`flex justify-center items-center rounded-full    ${activeSection === "settings"
                        ? "bg-cWhite"
                        : "bg-cSidebarDarkBg "
                        }   ${isOpenSidebar
                          ? "ml-s7 my-s7 p-s5 w-s30 h-s30 "
                          : "p-2 w-s30 h-s30"
                        }`}
                    >
                      <img
                        className="w-5"
                        src={
                          activeSection === "settings"
                            ? SettingsSelected
                            : SettingsNormal
                        }
                        alt=""
                      />
                    </div>
                    {!isOpenSidebar ? (
                      <div
                        className={`rounded-full w-s5 h-s5 ml-1 ${activeSection === "settings"
                          ? "bg-white"
                          : "bg-cIconColor"
                          }`}
                      ></div>
                    ) : (
                      ""
                    )}
                    {isOpenSidebar ? (
                      <div className="pl-5 font-semibold text-left text-fs14 ">
                        {t("Settings")}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Tooltip>

                {isOpenSidebar ? (
                  <div className="pr-5">
                    <img
                      src={DownArrowWhite}
                      alt=""
                      className={`${expandSettings === false ? "rotate-0" : "rotate-180"
                        } transition-all duration-500`}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <Tooltip
              title={isOpenSidebar ? "" : t("FAQ")}
              placement="right"
              componentsProps={{
                tooltip: {
                  sx: {
                    textTransform: "capitalize"
                  }
                }
              }}
            ><Link
              to="/settings/faq"
              className={`
                flex items-center
                ${isOpenSidebar ? " pl-5" : "justify-center"}
               ${location.pathname === "/settings/faq"
                  ? "text-white font-fw600"
                  : "text-cIconColor"
                } text-fs14 py-5`}
            >
                <img
                  src={
                    location.pathname === "/settings/faq"
                      ? ManageFAQSelected
                      : ManageFAQNormal
                  }
                  alt=""
                  className="object-cover w-s14 h-s14"
                />
                {isOpenSidebar ? <div className="pl-s10">{t("FAQ")}</div> : ""}
              </Link></Tooltip>

            <Tooltip
              title={isOpenSidebar ? "" : t("Language")}
              placement="right"
              componentsProps={{
                tooltip: {
                  sx: {
                    textTransform: "capitalize"
                  }
                }
              }}
            ><Link
              to="/settings/language-setup"
              className={`
                flex items-center
                ${isOpenSidebar ? " pl-5" : "justify-center"}
               ${location.pathname === "/settings/language-setup"
                  ? "text-white font-fw600"
                  : "text-cIconColor"
                } text-fs14 pb-5`}
            >
                <img
                  src={
                    location.pathname === "/settings/language-setup"
                      ? LanguageSelected
                      : LanguageNormal
                  }
                  alt=""
                  className="object-cover w-s14 h-s14"
                />
                {isOpenSidebar ? (
                  <div className="pl-s10">{t("Language")}</div>
                ) : (
                  ""
                )}
              </Link></Tooltip>

            <Tooltip
              title={isOpenSidebar ? "" : t("Privacy & Policy")}
              placement="right"
              componentsProps={{
                tooltip: {
                  sx: {
                    textTransform: "capitalize"
                  }
                }
              }}
            ><Link
              to="/settings/privacy-policy"
              className={`
                flex items-center
                ${isOpenSidebar ? " pl-5" : "justify-center"}
               ${location.pathname === "/settings/privacy-policy"
                  ? "text-white font-fw600"
                  : "text-cIconColor"
                } text-fs14 pb-5`}
            >
                <img
                  src={
                    location.pathname === "/settings/privacy-policy"
                      ? PrivacyIconSelected
                      : PrivacyIconNormal
                  }
                  alt=""
                  className="object-cover w-s14 h-s14"
                />
                {isOpenSidebar ? (
                  <div className="pl-s10">{t("Privacy & Policy")}</div>
                ) : (
                  ""
                )}
              </Link></Tooltip>

            <Tooltip
              title={isOpenSidebar ? "" : t("Terms & Condition")}
              placement="right"
              componentsProps={{
                tooltip: {
                  sx: {
                    textTransform: "capitalize"
                  }
                }
              }}
            ><Link
              to="/settings/terms-condition"
              className={`
                flex items-center
                ${isOpenSidebar ? " pl-5" : "justify-center"}
               ${location.pathname === "/settings/terms-condition"
                  ? "text-white font-fw600"
                  : "text-cIconColor"
                } text-fs14 pb-5`}
            >
                <img
                  src={
                    location.pathname === "/settings/terms-condition"
                      ? TermsIconSelected
                      : TermsIconNormal
                  }
                  alt=""
                  className="object-cover w-s14 h-s14"
                />
                {isOpenSidebar ? (
                  <div className="pl-s10">{t("Terms & Condition")}</div>
                ) : (
                  ""
                )}
              </Link></Tooltip>


            <Tooltip
              title={isOpenSidebar ? "" : t("About Us")}
              placement="right"
              componentsProps={{
                tooltip: {
                  sx: {
                    textTransform: "capitalize"
                  }
                }
              }}
            ><Link
              to="/settings/about-us"
              className={`
                flex items-center
                ${isOpenSidebar ? " pl-5" : "justify-center"}
               ${location.pathname === "/settings/about-us"
                  ? "text-white font-fw600"
                  : "text-cIconColor"
                } text-fs14 pb-5`}
            >
                <img
                  src={
                    location.pathname === "/settings/about-us"
                      ? TermsIconSelected
                      : TermsIconNormal
                  }
                  alt=""
                  className="object-cover w-s14 h-s14"
                />
                {isOpenSidebar ? (
                  <div className="pl-s10">{t("About Us")}</div>
                ) : (
                  ""
                )}
              </Link></Tooltip>


            <Tooltip
              title={isOpenSidebar ? "" : t("Delete Message")}
              placement="right"
              componentsProps={{
                tooltip: {
                  sx: {
                    textTransform: "capitalize"
                  }
                }
              }}
            ><Link
              to="/settings/delete-message"
              className={`
                flex items-center
                ${isOpenSidebar ? " pl-5" : "justify-center"}
               ${location.pathname === "/settings/delete-message"
                  ? "text-white font-fw600"
                  : "text-cIconColor"
                } text-fs14 pb-5`}
            >
                <img
                  src={
                    location.pathname === "/settings/delete-message"
                      ? TermsIconSelected
                      : TermsIconNormal
                  }
                  alt=""
                  className="object-cover w-s14 h-s14"
                />
                {isOpenSidebar ? (
                  <div className="pl-s10">{t("Delete Message")}</div>
                ) : (
                  ""
                )}
              </Link></Tooltip>

              <Tooltip
              title={isOpenSidebar ? "" : t("Fees")}
              placement="right"
              componentsProps={{
                tooltip: {
                  sx: {
                    textTransform: "capitalize"
                  }
                }
              }}
            ><Link
              to="/settings/fees"
              className={`
                flex items-center
                ${isOpenSidebar ? " pl-5" : "justify-center"}
               ${location.pathname === "/settings/fees"
                  ? "text-white font-fw600"
                  : "text-cIconColor"
                } text-fs14 pb-5`}
            >
                <img
                  src={
                    location.pathname === "/settings/fees"
                      ? FeesIconSelected
                      : FeesIconNormal
                  }
                  alt=""
                  className="object-cover w-s14 h-s14"
                />
                {isOpenSidebar ? <div className="pl-s10">{t("Fees")}</div> : ""}
              </Link></Tooltip>

            <Tooltip
              title={isOpenSidebar ? "" : t("Recommended Ctg.")}
              placement="right"
              componentsProps={{
                tooltip: {
                  sx: {
                    textTransform: "capitalize"
                  }
                }
              }}
            ><Link
              to="/settings/recommended-categories"
              className={`
                flex items-center
                ${isOpenSidebar ? " pl-5" : "justify-center"}
               ${location.pathname === "/settings/recommended-categories"
                  ? "text-white font-fw600"
                  : "text-cIconColor"
                } text-fs14 `}
            >
                <img
                  src={
                    location.pathname === "/settings/recommended-categories"
                      ? RecommendedIconSelected
                      : RecommendedIconNormal
                  }
                  alt=""
                  className="object-cover w-s14 h-s14"
                />
                {isOpenSidebar ? (
                  <div className="pl-s10">{t("Recommended Ctg.")}</div>
                ) : (
                  ""
                )}
              </Link></Tooltip>
          </div>

          {/* green: logout */}
          <Tooltip
            title={isOpenSidebar ? "" : t("Log out")}
            placement="right"
            componentsProps={{
              tooltip: {
                sx: {
                  textTransform: "capitalize"
                }
              }
            }}
          >
            <div
              onClick={() => {
                setShowLogoutModal(true);
              }}
              className={`flex items-center h-s50 mt-s15 cursor-pointer ${activeSection === "/home5"
                ? "bg-cBrand text-white"
                : "bg-cSidebarDarkBg text-cSidebarText"
                }
          ${isOpenSidebar ? "pl-s5  rounded-full] " : "justify-center w-20"}
          `}
            >
              <div
                className={`flex justify-center items-center rounded-full  bg-cSidebarDarkBg ${isOpenSidebar ? "ml-s16 my-s7 " : ""
                  }`}
              >
                <img
                  src={LogoutIcon}
                  alt=""
                  className="object-cover w-s16 h-s16"
                />
              </div>
              {isOpenSidebar ? (
                <div className="pl-5 font-semibold text-left text-fs14">
                  {t("Log out")}
                </div>
              ) : (
                ""
              )}
            </div>
          </Tooltip>
          {/* red ends sidebar */}
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
