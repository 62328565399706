import axios from "axios";
import create from "zustand";
import {
  getAllCustomerListUrl,
  showCustomerUrl,
  updateCustomerUrl,
  addCustomerUrl,
  toggleCustomerUrl,
  deleteCustomerUrl,
  getAllOrdersUrl,
  searchCustomerUrl,
} from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import useOrderStore from "./OrderStore";
import useUtilityStore from "./UtilityStore";

const { setLoading, setLoadingSearch } = useUtilityStore.getState();
const useCustomerStore = create((set) => ({

  customerFormData: {
    name: "",
    email: "",
    phone: "",
    password: "",
    password_confirmation: "",
    address: "",
    image_url: "",
    role: "customer",
  },
  setCustomerFormData: (e) =>
    set((state) => (state.customerFormData[e.target.name] = e.target.value)),
  resetCustomerFormData: () =>
    set(
      (state) =>
      (state.customerFormData = {
        name: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
        address: "",
        image_url: "",
      })
    ),

  customerUpdateData: {
    user_id: "",
    name: "",
    email: "",
    phone: "",
    address: "",
    password: "",
    password_confirmation: "",
    image_url: "",
  },
  setCustomerUpdateData: (e) =>
    set((state) => (state.customerUpdateData[e.target.name] = e.target.value)),
  resetCustomerUpdateData: () =>
    set(
      (state) =>
      (state.customerUpdateData = {
        user_id: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        password: "",
        password_confirmation: "",
        image_url: "",
      })
    ),

  customerIndexP: {
    init: false,
    url: ""
  },
  setCustomerIndexP: (value) => set({ customerIndexP: value }),

  customerUpdateBody: {},
  setCustomerUpdateBody: (value) => set({ customerUpdateBody: value }),

  customerListAll: [],
  setCustomerListAll: (value) => set({ customerListAll: value }),

  customerListPagination: {},
  setCustomerListPagination: (value) => set({ customerListPagination: value }),

  customerListAllTemp: [],
  setCustomerListAllTemp: (value) => set({ customerListAllTemp: value }),

  selectedCustomerID: 0,
  setSelectedCustomerID: (value) => set({ selectedCustomerID: value }),

  customerDetails: {},
  setCustomerDetails: (value) => set({ customerDetails: value }),

  deactivateCustomerForm: {
    user_id: -1,
  },
  setDeactivateCustomerForm: (value) =>
    set((state) => (state.deactivateCustomerForm["user_id"] = value)),

  // customerPaginationUrl: "",
  // setCustomerPaginationUrl: (value) => set({ customerPaginationUrl: value }),

  customerPaginationUrl: { url: "" },
  setCustomerPaginationUrl: (name, value) => set((state) => (state.customerPaginationUrl[name] = value)),

  customerTakeAmount: { take: 10 },
  setCustomerTakeAmount: (name, value) => set((state) => (state.customerTakeAmount[name] = value)),
  
  // Modal
  showDeleteModal: false,
  setShowDeleteModal: (value) => set({ showDeleteModal: value }),

  showEditModal: false,
  setShowEditModal: (value) => set({ showEditModal: value }),

  editImageState: false,
  setEditImageState: (value) => set({ editImageState: value }),

  showDeactivateModal: false,
  setShowDeactivateModal: (value) => set({ showDeactivateModal: value }),

  showCustomerDetails: false,
  setShowCustomerDetails: (value) => set({ showCustomerDetails: value }),

  addingNewCustomer: false,
  setAddingNewCustomer: (value) => set({ addingNewCustomer: value }),

  deletableCustomer: true,
  setDeletableCustomer: (value) => set({ deletableCustomer: value }),

  searchCustomerKey: "",
  setSearchCustomerKey: (value) => set({ searchCustomerKey: value }),
}));

export default useCustomerStore;


// green: Get all Customer list
export const getAllCustomer = async (paginationUrl = "",take=10) => {

  try {
    const { customerListPagination,customerTakeAmount } = useCustomerStore.getState();

    setLoading(true);

    console.log("customerTakeAmount",customerTakeAmount)

    let targetUrl = "";

    // if (paginationUrl !== "") {
    //   // targetUrl = paginationUrl.indexOf("?");
    //   paginationUrl = JSON.stringify(paginationUrl);
    //   targetUrl = paginationUrl.slice(
    //     paginationUrl.indexOf("?"),
    //     paginationUrl.length - 1
    //   );
    //   targetUrl = getAllCustomerListUrl + targetUrl;
    // } else {
    //   if (customerListPagination.current_page) {
    //     let urlToLoad = customerListPagination.path + "?page=" + customerListPagination.current_page;
    //     console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
    //     targetUrl = urlToLoad;
    //   } else targetUrl = getAllCustomerListUrl;
    // }
    // console.log("targetUrl:::", targetUrl);

    const res = await axios.get(paginationUrl===""?getAllCustomerListUrl:paginationUrl, {
      params: { take: take }
    });

    console.log("getAllCustomerListUrl res.data", res.data.data);

    if (res.data.success) {
      useCustomerStore.getState().setCustomerListAll(res.data.data.data);
      useCustomerStore.getState().setCustomerListAllTemp(res.data.data.data);
      useCustomerStore.getState().setCustomerListPagination(res.data.data);
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getAllCustomer: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};


// yellow: search customer list
export const searchCustomerList = async (searchValue, url = "",take=10) => {

  const {customerTakeAmount}=useCustomerStore.getState();

  try {
    setLoadingSearch(true);
    const res = await axios.get(url === "" ? searchCustomerUrl : url, {
      params: {
        take:take,
        search: searchValue,
      },
    });

    console.log("searchCustomerList::: res.data", res.data);

    if (res.data.success) {
      useCustomerStore.getState().setCustomerListAll(res.data.data.data);
      useCustomerStore.getState().setCustomerListAllTemp(res.data.data.data);
      useCustomerStore.getState().setCustomerListPagination(res.data.data);
      setLoadingSearch(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      console.log("searchCustomerList: ", res.data.message);
      setLoadingSearch(false);
      return false;
    }
  } catch (error) {
    console.log("searchCustomerList: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoadingSearch(false);
    return false;
  }
};


// green: get all orders list for CUSTOMER
export const getAllOrderListOfCustomer = async (
  filterStatus,
  paginationUrl = "",
  customerID = ""
) => {
  try {
    // setLoading(true);

    let targetUrl = "";

    if (paginationUrl !== "") {
      // targetUrl = paginationUrl.indexOf("?");
      paginationUrl = JSON.stringify(paginationUrl);
      targetUrl = paginationUrl.slice(
        paginationUrl.indexOf("?"),
        paginationUrl.length - 1
      );
      targetUrl = getAllOrdersUrl + targetUrl;
      console.log("targetUrl:::", targetUrl);
    } else {
      targetUrl = getAllOrdersUrl;
    }

    console.log("targetUrl:::", targetUrl);

    let res = {};

    if (filterStatus && filterStatus !== "all orders") {
      console.log("HERE!!!!!!");
      res = await axios.post(targetUrl, {
        take: 5,
        status: filterStatus,
        customer_id: customerID,
      });
    } else {
      res = await axios.post(targetUrl, {
        take: 5,
        customer_id: customerID,
      });
    }

    console.log("getAllOrderListOfCustomer res.data:::: ", res.data);

    if (res.data.success) {
      useOrderStore.getState().setOrderList(res.data.data.data);
      useOrderStore.getState().setOrderListTemp(res.data.data.data);
      useOrderStore.getState().setOrderListPagination(res.data.data);
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    // setLoading(false);
  } catch (error) {
    console.log("getAllOrderListOfCustomer: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    // setLoading(false);
  }
};


// blue: add new customer
export const saveNewCustomer = async (urlToLoad = "url...") => {
  try {
    const { customerFormData } = useCustomerStore.getState();
    setLoading(true);

    let res = {};
    let addFormFinalBody = {
      name: customerFormData?.name,
      email: customerFormData?.email,
      password: customerFormData?.password,
      password_confirmation: customerFormData?.password_confirmation,
      address: customerFormData?.address,
      role: "customer",
    };

    // setLoading(false);
    //  return
    console.log("addFormFinalBody::::", addFormFinalBody);

    if (customerFormData?.image_url) {
      res = await axios.post(
        addCustomerUrl,
        customerFormData
      );
    } else {
      res = await axios.post(addCustomerUrl, addFormFinalBody);
    }

    console.log("add customer res.data", res.data);
    if (res.data.success) {
      useCustomerStore.getState().resetCustomerFormData();
      getAllCustomer(urlToLoad);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("saveNewShop: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


// blue: update customer
export const updateCustomer = async (urlToLoad = "url...") => {
  try {
    const { customerUpdateBody } = useCustomerStore.getState();
    console.table("customerUpdateBody::::", customerUpdateBody);
    setLoading(true);

    let res = {};

    res = await axios.post(
      updateCustomerUrl,
      customerUpdateBody
    );

    console.log("updateCustomer res.data", res.data);
    if (res.data.success) {
      useCustomerStore.getState().resetCustomerUpdateData();
      // Toastr({ message: res.data.message, type: "success" });
      getCustomerDetails(updateCustomer.user_id);
      getAllCustomer(urlToLoad)
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("updateCustomer: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


// yellow: Show Customer Details
export const getCustomerDetails = async (id) => {
  console.log("customer details");
  try {
    // setLoading(true);

    const res = await axios.get(showCustomerUrl, {
      params: { id: id }
    });

    console.log("getCustomerDetails res.data ", res.data.data);

    if (res.data.success) {
      useCustomerStore.getState().setCustomerDetails(res.data.data);
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    // setLoading(false);
  } catch (error) {
    console.log("getCustomerDetails: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    // setLoading(false);
  }
};

// blue: Toggle Customer
export const toggleCustomerActivation = async (activeStatusToSet, urlToLoad = "url...") => {
  const body = {
    user_id: useCustomerStore.getState().deactivateCustomerForm.user_id
    // is_active: activeStatusToSet,
  };

  try {
    setLoading(true);

    console.log("toggle body:::", body);

    const res = await axios.post(toggleCustomerUrl, body);

    console.log(res.data);

    if (res.data.success) {
      getAllCustomer(urlToLoad);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("ToggleCustomer: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// orange: delete Customer
export const deleteCustomer = async (deleteID) => {

  try {
    setLoading(true);

    const res = await axios.post(deleteCustomerUrl, { user_id: deleteID });

    console.log(res.data);

    if (res.data.success) {
      getAllCustomer();
      setLoading(false);
      return true;
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("ToggleCustomer: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};
