import React from "react";
import CommonModal from "../../../Components/Modal/CommonModal";
import CommonButton from "../../../Components/Button/CommonButton";
import useGiftCardStore from "../../../App/Stores/GiftCardStore";
import { useTranslation } from "react-i18next";

const DeleteGiftCard = ({ OnConfirmDelete }) => {
  const { t } = useTranslation();

  const { showDeleteModal, setShowDeleteModal } = useGiftCardStore();
  return (
    <>
      <CommonModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        
        mainContent={
          <div className="flex justify-center items-center py-5 mb-5">
            <div className="text-fs16">
              {t("Are you sure you want to delete this gift card?")}
            </div>
          </div>
        }
        modalTitle={t("Delete Gift Card")}
        primaryActionButton={
          <>
            <CommonButton
              onClick={() => {
                OnConfirmDelete();
              }}
              colorType="danger"
              btnLabel={t("Delete")}
            />
          </>
        }
        secondaryActionButton={
          <>
            <CommonButton
              onClick={() => setShowDeleteModal(false)}
              btnLabel={t("Cancel")}
            />
          </>
        }
      />
    </>
  );
};

export default DeleteGiftCard;
