/* eslint-disable react-hooks/exhaustive-deps */
import CustomerTableItem from "./CustomerTableItem";
import CommonTable from "../../../Components/Table/CommonTable";
import useCustomerStore, { getAllCustomer, searchCustomerList } from "../../../App/Stores/CustomerStore";
import AddCustomer from "../Popup/AddCustomer";
import { useDebounce } from "use-debounce";
import { useEffect } from "react";
import NoDataRow from "../../../Components/Table/NoDataRow";
import { PageTitle } from "../../../App/Utility/UtilityFunctions";
import { useTranslation } from "react-i18next";
import useLayoutStore from "../../../App/Stores/LayoutStore";
import useUtilityStore from "../../../App/Stores/UtilityStore";

const CustomerTable = ({ title, headers = [], isImage, tableBtnTitle }) => {
  const {
    setAddingNewCustomer,
    searchCustomerKey,
    customerListAll,
    customerListPagination,
    setSearchCustomerKey,
    setCustomerPaginationUrl,
    customerPaginationUrl,
    customerTakeAmount
  } = useCustomerStore();

  const { setBarTitle } = useLayoutStore();

  const { t } = useTranslation();

  const [searchValue] = useDebounce(searchCustomerKey, 500);

  useEffect(() => {
    if (searchValue) {
      searchCustomerList(searchValue, customerPaginationUrl.url,customerTakeAmount.take);
    } else {
      // if (customerListPagination.current_page) {
      //   let urlToLoad = customerListPagination.path + "?page=" + customerListPagination.current_page;
      //   getAllCustomer(urlToLoad,customerTakeAmount.take);
      // } else {
        getAllCustomer(customerPaginationUrl.url,customerTakeAmount.take);
      // }
    }
  }, [searchValue]);

  useEffect(() => {
    setBarTitle(t("Customers"));
    PageTitle(t("Customers"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CommonTable
        takeComponent={<TakeItem />}
        headers={headers}
        addItemTitle={tableBtnTitle}
        showingFrom={customerListPagination.from}
        showingTo={customerListPagination.to}
        totalResult={customerListPagination.total}
        items={
          customerListAll.length > 0
            ? customerListAll.map((item, index) => (
              <CustomerTableItem
                key={index}
                barIndex={index}
                end={customerListAll.length - 1}
                tableData={item}
              />
            ))
            : <NoDataRow columnNumber={headers?.length} />
        }
        addItemModal={
          <>
            <AddCustomer />
          </>
        }
        addItemFunction={setAddingNewCustomer}
        searchValue={searchCustomerKey}
        searchOnChange={(e) => {setCustomerPaginationUrl("url","");setSearchCustomerKey(e.target.value)}}
        onSearchClear={async () => {
          await setSearchCustomerKey("");
          await setCustomerPaginationUrl("url","")
          // await getAllCustomer("");
        }}
        paginationObject={customerListPagination}
        pagesArray={customerListPagination.links}
        currentPageNumber={customerListPagination.current_page}
        prevPageUrl={customerListPagination.prev_page_url}
        nextPageUrl={customerListPagination.next_page_url}
        showPagination={customerListPagination?.last_page !== 1 ? true : false}
        paginationOnClick={(url) => {
          setCustomerPaginationUrl("url",url)
          if (searchValue) {
            searchCustomerList(searchValue, url,customerTakeAmount.take)
          } else getAllCustomer(url,customerTakeAmount.take);
        }}
      />
    </>
  );
};

export default CustomerTable;


const TakeItem = () => {
  const {
    setCustomerTakeAmount,
    customerTakeAmount,
    setCustomerPaginationUrl,
    customerPaginationUrl,
    searchCustomerKey
  } = useCustomerStore();
  
  const { setLoading } = useUtilityStore.getState();

  return (
    <div className=''>
      <span className='pr-s10'>Show</span>
      <select id="cars"
        value={customerTakeAmount.take ? customerTakeAmount.take : 10}
        onChange={async (e) => {
          setLoading(true);
          await setCustomerTakeAmount("take",e.target.value);
          await setCustomerPaginationUrl("url","")
          if (searchCustomerKey) {
            await setCustomerPaginationUrl("url","")
            await searchCustomerList(searchCustomerKey, customerPaginationUrl.url,e.target.value);
          } else {
            await setCustomerPaginationUrl("url","")
            await getAllCustomer(customerPaginationUrl.url,e.target.value);
          }
          setLoading(false);
        }} className='pl-2 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={customerTakeAmount.take === 10}
          value={10}
        >10</option>

        <option
          className='py-s10 text-cHighlightedTexts'
          selected={customerTakeAmount.take === 25}
          value={25}
        >25</option>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={customerTakeAmount.take === 50}
          value={50}
        >50</option>
        <option
          className='py-s10 text-cMainBlack'
          selected={customerTakeAmount.take === 100}
          value={100}
        >100</option>
      </select>   <span className='pl-s10'>Entries</span>
    </div>
  )
}