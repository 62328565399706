import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useLayoutStore from '../../App/Stores/LayoutStore';
import UseNotificationStore, { getNotificationIndex } from '../../App/Stores/NotificationStore';
import { PageTitle } from '../../App/Utility/UtilityFunctions';
import CommonTable from '../../Components/Table/CommonTable';
import NoDataRow from '../../Components/Table/NoDataRow';
import TakeItem from '../../Components/Table/TakeItem';
import NotificationTableRow from './NotificationTableRow';

const NotificationManagement = () => {
    const { setBarTitle } = useLayoutStore();

    const { notificationTakeAmount, setNotificationTakeAmount, notificationIndex } = UseNotificationStore();

    const { t } = useTranslation();

    const notificationHeaders = [
        { index: 1, name: "#" },
        { index: 2, name: t("Title") },
        { index: 3, name: t("Details") },
        { index: 4, name: t("Date") },
    ]

    useEffect(() => {
        fetchNotification();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchNotification = async () => {
        await getNotificationIndex();
    }

    useEffect(() => {
        // set the title of page and top bar
        setBarTitle(t("Notifications"));
        PageTitle(t("Notifications"));
    }, [t])


    return (
        <div>
            <CommonTable
                takeComponent={
                    <TakeItem
                        value={notificationTakeAmount}
                        onChange={async (value) => {
                            await setNotificationTakeAmount(value);
                            await getNotificationIndex();
                        }}
                    />
                }
                headers={notificationHeaders}
                items={
                    notificationIndex?.data?.length > 0 ?
                        notificationIndex?.data?.map((item, index) =>
                            <NotificationTableRow key={index} data={item} index={index} startPoint={notificationIndex?.from} />
                        ) : <NoDataRow columnNumber={notificationHeaders?.length} />
                }

                shoSearchBox={false}
                showPagination={
                    notificationIndex?.last_page !== 1 ? true : false
                }
                prevPageUrl={notificationIndex?.prev_page_url}
                paginationObject={notificationIndex}
                nextPageUrl={notificationIndex?.next_page_url}
                pagesArray={notificationIndex?.links}
                paginationOnClick={async (url) => {
                    console.log("url::::", url);
                    await getNotificationIndex(url);
                }}


                showingFrom={notificationIndex?.from ?? 0}
                showingTo={notificationIndex?.to ?? 0}
                totalResult={notificationIndex?.total ?? 0}
            />
        </div>
    )
}

export default NotificationManagement