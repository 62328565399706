import CommonTable from "../../../Components/Table/CommonTable";
import useDashboardStore from "../../../App/Stores/DashboardStore";
import TopSellingTableItem from "./TopSellingTableItem";
import NoDataRow from "../../../Components/Table/NoDataRow";

const TopSellingTable = ({ title, headers = [] }) => {
  const { topSellingCards } = useDashboardStore();

  return (
    <>
      <CommonTable
        shoSearchBox={false}
        tableTitle={title}
        headers={headers}
        pagination={false}
        items={
          topSellingCards?.length > 0 ?
            topSellingCards?.map((item, index) => (
              <TopSellingTableItem
                key={index}
                barIndex={index + 1}
                tableData={item}
              />
            )) : <NoDataRow columnNumber={headers?.length} />
        }
      />
    </>
  );
};

export default TopSellingTable;
