import React from 'react'
import { MuiCustomSwitch } from '../../../App/Stores/UtilityStore';
import useSettingsStore, { settingsUpdate } from '../../../App/Stores/SettingsStore';
import { useTranslation } from 'react-i18next';

function OrderBonus() {

    const { is_order_bonus,setIs_order_bonus,setShow_order_bonus_modal } = useSettingsStore();

    const { t } = useTranslation();

    return (
        <div>
            <div className="flex flex-row items-center justify-between w-full p-5 pb-5 bg-white shadow-md text-fs20 font-fw600 rounded-br5">
                <span>{t("Order Bonus")}</span>
                <MuiCustomSwitch
                    color="secondary"
                    size="small"
                    checked={is_order_bonus}
                    onClick={() => {
                        if (is_order_bonus) {
                            setShow_order_bonus_modal(true);
                        } else {
                            const success = settingsUpdate({type:"is_order_bonus",value:!is_order_bonus });
                            if (success) return setIs_order_bonus(1);
                        }
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                />
            </div>
        </div>
    )
}

export default OrderBonus;
