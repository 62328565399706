import axios from "axios";
import create from "zustand";
import {
  addBoostedShopsUrl,
  deleteBoostedShopsUrl,
  getAllBoostedShopsUrl,
  searchBoostedShopsUrl,
  updateBoostedShopsUrl,
} from "../Utility/Url";
import { Toastr } from "../Utility/UtilityFunctions";
import { getAllShop } from "./ShopStore";
import useUtilityStore from "./UtilityStore";

const { setLoading, setLoadingSearch } = useUtilityStore.getState();

const useBoostedStore = create((set) => ({
  addBoostedShopForm: {
    shop_id: "",
    start_date: "",
    end_date: "",
  },
  setAddBoostedShopForm: (e) =>
    set((state) => (state.addBoostedShopForm[e.target.name] = e.target.value)),
  resetAddBoostedShopForm: () =>
    set(
      (state) =>
      (state.addBoostedShopForm = {
        shop_id: "",
        start_date: "",
        end_date: "",
      })
    ),

  updateBoostedShopForm: {
    shop_id: "",
    shop_name: "",
    status: "",
    start_date: "",
    end_date: "",
  },
  setUpdateBoostedShopForm: (e) =>
    set(
      (state) => (state.updateBoostedShopForm[e.target.name] = e.target.value)
    ),

  boostedShopDetails: {},
  setBoostedShopDetails: (value) => set({ boostedShopDetails: value }),

  boostedShopList: [],
  setBoostedShopList: (value) => set({ boostedShopList: value }),

  boostedShopListTemp: [],
  setBoostedShopListTemp: (value) => set({ boostedShopListTemp: value }),

  boostedShopListPagination: [],
  setBoostedShopListPagination: (value) =>
    set({ boostedShopListPagination: value }),

  deleteShopBoostID: "",
  setDeleteShopBoostID: (value) => set({ deleteShopBoostID: value }),

  showDeleteShopBoost: false,
  setShowDeleteShopBoost: (value) => set({ showDeleteShopBoost: value }),

  showAddBoostedShop: false,
  setShowAddBoostedShop: (value) => set({ showAddBoostedShop: value }),

  showEditBoostedShop: false,
  setShowEditBoostedShop: (value) => set({ showEditBoostedShop: value }),

  searchShopBoost: "",
  setSearchShopBoost: (value) => set({ searchShopBoost: value }),

  boostShopTakeAmount: { take: 10 },
  setBoostShopTakeAmount: (name, value) => set((state) => (state.boostShopTakeAmount[name] = value)),

  boostShopPaginationUrl: "",
  setBoostShopPaginationUrl: (value) => set({ boostShopPaginationUrl: value }),
}));

export default useBoostedStore;

/* 
    API Functions
 */

// green: get all boosted shop list
export const getAllBoostedShop = async (paginationUrl = "") => {
  try {
    setLoading(true);
    const { boostShopTakeAmount } = useBoostedStore.getState()
    // let targetUrl = "";
    // if (paginationUrl !== "") {
    //   // targetUrl = paginationUrl.indexOf("?");
    //   paginationUrl = JSON.stringify(paginationUrl);
    //   targetUrl = paginationUrl.slice(
    //     paginationUrl.indexOf("?"),
    //     paginationUrl.length - 1
    //   );
    //   targetUrl = getAllBoostedShopsUrl + targetUrl;
    // } else {
    //   targetUrl = getAllBoostedShopsUrl;
    // }
    // console.log("targetUrl:::", targetUrl);

    const res = await axios.get(paginationUrl === "" ? getAllBoostedShopsUrl : paginationUrl, {
      params: {
        take: boostShopTakeAmount.take,
      },
    });

    if (res.data.success) {
      console.log("getAllBoostedShop res.data:::: ", res.data.data);
      useBoostedStore.getState().setBoostedShopList(res.data.data.data);
      useBoostedStore.getState().setBoostedShopListTemp(res.data.data.data);
      useBoostedStore.getState().setBoostedShopListPagination(res.data.data);
      // Toastr({ message: res.data.message, type: "success" });
    } else {
      Toastr({ message: res.data.message, type: "error" });
    }
    setLoading(false);
  } catch (error) {
    console.log("getAllBoostedShop: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
  }
};

// yellow: search boosted sop list
export const searchBoostedShops = async (searchValue, paginationUrl = "") => {

  const { boostShopTakeAmount } = useBoostedStore.getState();
  try {
    setLoadingSearch(true);
    console.log("searchValue::", searchValue);
    const res = await axios.get(paginationUrl === "" ? searchBoostedShopsUrl : paginationUrl, {
      params: {
        take: boostShopTakeAmount.take,
        search: searchValue,
      },
    });

    console.log("searchBoostedShops::: res.data", res.data);

    if (res.data.success) {
      useBoostedStore.getState().setBoostedShopList(res.data.data.data);
      useBoostedStore.getState().setBoostedShopListTemp(res.data.data.data);
      useBoostedStore.getState().setBoostedShopListPagination(res.data.data);

      setLoadingSearch(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      console.log("searchBoostedShops: ", res.data.message);
      setLoadingSearch(false);
      return false;
    }
  } catch (error) {
    console.log("searchBoostedShops: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoadingSearch(false);
    return false;
  }
};

// blue: add new boosted shop
export const addNewBoostedShop = async (urlToLoad) => {
  // console.log("urlToLoad:::::", urlToLoad);
  try {
    setLoading(true);
    const res = await axios.post(
      addBoostedShopsUrl,
      useBoostedStore.getState().addBoostedShopForm
    );

    console.log("addNewBoostedShop::: res.data", res.data);

    if (res.data.success) {
      useBoostedStore.getState().resetAddBoostedShopForm();
      // setTimeout(function () {
      // }, 2000);
      getAllBoostedShop(urlToLoad);

      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      console.log("addNewBoostedShop: ", res.data.message);
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("addNewBoostedShop: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// yellow: add new boosted shop
export const updateBoostedShop = async (urlToLoad) => {
  console.log(urlToLoad);
  try {
    setLoading(true);
    const updateBoostedShopForm =
      useBoostedStore.getState().updateBoostedShopForm;
    const res = await axios.post(updateBoostedShopsUrl, {
      boosted_id: updateBoostedShopForm.boosted_id,
      start_date: updateBoostedShopForm.start_date,
      end_date: updateBoostedShopForm.end_date,
      status: updateBoostedShopForm.status,
    });

    console.log("updateBoostedShop::: res.data", res.data);

    if (res.data.success) {
      // useBoostedStore.getState().resetAddBoostedShopForm();
      getAllBoostedShop(urlToLoad);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      console.log("updateBoostedShop: ", res.data.message);
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("updateBoostedShop: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// orange: delete boosted shop
export const deleteBoostedShop = async (urlToLoad = "",
  id = useBoostedStore.getState().deleteShopBoostID
) => {
  console.log(id);
  try {
    setLoading(true);

    const res = await axios.post(deleteBoostedShopsUrl, {
      boosted_id: id,
    });

    console.log("deleteBoostedShops::: res.data", res.data);

    if (res.data.success) {
      getAllBoostedShop(urlToLoad);
      getAllShop("", 500, true);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      console.log("deleteBoostedShops: ", res.data.message);
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("deleteVoucher: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

/* 
    Helper Functions
 */
