import CommonTable from "../../../Components/Table/CommonTable";
import CategoryTableItem from "./CategoryTableItem";
import useCategoryStore, { getAllCategory, resetArrangedCategory, searchCategoryList, submitNewArrangedCategory } from "../../../App/Stores/CategoryStore";
import AddCategory from "../Popup/AddCategory";
import DeleteCategory from "../Popup/DeleteCategory";
import EditCategory from "../Popup/EditCategory";
import DeactivateCategory from "../Popup/DeactivateCategory";
import NoDataRow from "../../../Components/Table/NoDataRow";
import { useEffect, useState } from "react";
import CommonButtonOutlined from "../../../Components/Button/CommonButtonOutlined";

// for sortables
import {
  DndContext,
  closestCenter
} from '@dnd-kit/core'

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import CommonButton from "../../../Components/Button/CommonButton";

const CategoryTable = ({ title, headers = [], isImage, tableBtnTitle }) => {
  const {
    setAddingNewCategory,
    searchKey,
    categoryListAll,
    setCategoryListAll,
    categoryListPagination,
    setSearchKey,
  } = useCategoryStore();

  const [sortingMode, setSortingMode] = useState(false);

  useEffect(() => {
    setSearchKey("");
  }, []);

  const handleDragEnd = (e) => {
    console.log(e);
    const { active, over } = e;

    if (active.id !== over.id) {
      const activeIndex = categoryListAll.findIndex((item) => item.id === active.id);
      const overIndex = categoryListAll.findIndex((item) => item.id === over.id);

      console.log('activeIndex, overIndex :::: ', activeIndex, overIndex);
      console.log(arrayMove(categoryListAll, activeIndex, overIndex))
      let new_array = arrayMove(categoryListAll, activeIndex, overIndex);
      setCategoryListAll(new_array);
    }
  }

  const handleSubmit = async () => {
    let t_array = [];

    categoryListAll?.map((item, index) => t_array.push({ category_id: item?.id, order_index: index },));
    let sortSuccess = await submitNewArrangedCategory(t_array);

    if (sortSuccess) setSortingMode(false);
  }

  return (
    <>
      <DeleteCategory />
      <EditCategory />
      <DeactivateCategory />



      {sortingMode ?
        <div>
          <div className="flex pb-5 items-center justify-between pt-[2px]">
            <div className="text-xl font-semibold">Rearrange Categories</div>
            <div className="flex items-center space-x-3">
              <CommonButtonOutlined
                colorType="primary"
                btnLabel="Reset To Default"
                onClick={async () => {
                  let restSuccess = await resetArrangedCategory();
                  if (restSuccess) setSortingMode(false);
                }}
              />

              <CommonButton
                btnLabel="Save and Exit"
                onClick={handleSubmit}
              />
            </div>
          </div>
          <table className="w-full">
            <tr className="">
              {
                headers?.map((item, index) => (
                  <th className="border py-2" key={index}>{item.name}</th>
                ))
              }
            </tr>
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={categoryListAll}
                strategy={verticalListSortingStrategy}
              >
                {
                  categoryListAll.map((item, index) => (
                    <CategoryTableItem
                      key={index}
                      barIndex={index}
                      end={categoryListAll.length - 1}
                      tableData={item}
                    />
                  ))
                }
              </SortableContext>
            </DndContext>
          </table>
        </div>
        :
        <CommonTable
          titleComponent={
            <div className="-mr-14" >
              <CommonButtonOutlined
                colorType="primary"
                btnLabel="Rearrange Categories"
                width={'w-[180px]'}
                onClick={() => setSortingMode(true)}
              />
            </div>
          }
          headers={headers}
          addItemTitle={tableBtnTitle}
          showingFrom={1}
          showingTo={categoryListAll.length}
          totalResult={categoryListAll.length}
          items={
            categoryListAll.length > 0
              ? categoryListAll.map((item, index) => (
                <CategoryTableItem
                  key={index}
                  barIndex={index}
                  end={categoryListAll.length - 1}
                  tableData={item}
                />
              ))
              : <NoDataRow columnNumber={headers?.length} />
          }
          addItemModal={
            <>
              <AddCategory />
            </>
          }
          addItemFunction={setAddingNewCategory}

          searchValue={searchKey}
          onSearchClear={() => {
            setSearchKey("");
            getAllCategory();
          }}
          searchOnChange={async (e) => {
            await setSearchKey(e.target.value);
            if (e.target.value) searchCategoryList();
            else await getAllCategory();
          }}

          pagination={false}
          pagesArray={categoryListPagination.links}
          currentPageNumber={categoryListPagination.current_page}
        />}
    </>
  );
};

export default CategoryTable;
