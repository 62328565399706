import { useEffect, useState } from 'react'
import { Listbox } from '@headlessui/react'
const CustomSelect = ({
    value = "2",
    onChange = () => { },
    placeholder = "Select an option",
    required = false,
    data = [
        { value: "1", label: 'Durward Reynolds' },
        { value: "2", label: 'Kenton Towne' },
        { value: "3", label: 'Therese Wunsch' },
        { value: "4", label: 'Benedict Kessler' },
        { value: "5", label: 'Katelyn Rohan' },
    ]
}) => {
    const [selectedValue, setSelectedValue] = useState(value);

    const handleChange = (e) => {
        // console.log(e);
        let event = { target: { value: e } }
        onChange(event);
    }

    useEffect(() => {
        setSelectedValue(value)
    }, [value]);
    return (
        <Listbox value={selectedValue} onChange={handleChange}>
            <Listbox.Button>{selectedValue}</Listbox.Button>
            <Listbox.Options>
                {data.map((item, index) => (
                    <Listbox.Option
                        key={index}
                        value={item?.value}
                        // disabled={person.unavailable}
                    >
                        {item.label}
                    </Listbox.Option>
                ))}
            </Listbox.Options>
        </Listbox>
    )
}

export default CustomSelect