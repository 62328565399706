import { Tooltip } from "@mui/material";
import React from "react";
import Clamp from "react-multiline-clamp";
import { useNavigate } from "react-router-dom";
import useCustomerStore, {
  getCustomerDetails,
  toggleCustomerActivation
} from "../../../App/Stores/CustomerStore";
import { MuiCustomSwitch } from "../../../App/Stores/UtilityStore";
import Image from "../../../Components/Image/Image";
import { NoUser } from "../../../Components/Utility/ImageImports";

export default function CustomerTableItem({ tableData = {}, barIndex, end }) {
  const {
    setShowDeactivateModal,
    setDeactivateCustomerForm,
    setSelectedCustomerID,
    customerListPagination
  } = useCustomerStore();
  const handleActiveShop = (e) => {
    console.log(e.target.checked);
  };

  // initialize useNavigate
  const navigate = useNavigate();

  const navigateToDetails = () => {
    navigate("customer-details");
  };


  return (
    <>
      <tr
        className={`hover:bg-cBandHover bg-white shadow-cShopItem  select-none cursor-pointer text-fs14 ${barIndex === end ? "border-b-0" : "border-b"
          }`}
      >
        <th
          onClick={() => {
            navigateToDetails();
            getCustomerDetails(tableData?.id);
            localStorage.setItem("customerID", tableData?.id);
            setSelectedCustomerID(tableData?.id);
          }}
          className={`p-s10 text-center font-fw400 border-r min-w-[170px] max-w-[170px] truncate`}
        >
          <div className="flex items-center">
            <div className="max-w-[25px] min-w-[25px]">
            <Image
              className="w-6 h-6 object-cover rounded-full"
              alt=""
              src={tableData?.image_url}
              dummyImage={NoUser}
            /></div>
            {tableData?.name?  <Tooltip title={tableData?.name}><span className="pl-s5 truncate">{tableData?.name}</span></Tooltip>:'NA'}
          </div>
        </th>

        <td
          onClick={() => {
            navigateToDetails();
            getCustomerDetails(tableData?.id);
            localStorage.setItem("customerID", tableData?.id);
            setSelectedCustomerID(tableData?.id);
          }}
          className="text-center  border-r min-w-[160px] max-w-[160px]"
        >
          <Clamp withTooltip lines={1}>
            {tableData?.address ? tableData?.address : "NA"}
          </Clamp>
        </td>

        <CommonTD
          tableData={tableData}
          showData={tableData?.phone}
          noData="NA"
          maxW="max-w-[120px]"
          minW="min-w-[120px]"
        />

        
        <CommonTD
          tableData={tableData}
          showData={tableData?.total_orders}
          noData="0"
          maxW="max-w-[85px]"
          minW="min-w-[85px]"
        />

        <CommonTD
          noData="NA"
          tableData={tableData}
          showData={tableData?.customer_latest_orders?.card_name}
          maxW="max-w-[120px]"
          minW="min-w-[120px]"
        />

        <td className=" py-s10 cursor-default min-w-[110px] max-w-[110px] ">
          <tr className="flex space-x-3 items-center justify-center ">
            <td>
              {/* {tableData?.id} */}
              <MuiCustomSwitch
                // color="secondary"
                checked={tableData?.is_active}
                onClick={() => {
                  let loadUrl =
                    customerListPagination.path +
                    "?page=" +
                    customerListPagination.current_page;
                  setDeactivateCustomerForm(tableData?.id);
                  if (tableData?.is_active) {
                    setShowDeactivateModal(true);
                  } else {
                    toggleCustomerActivation(1, loadUrl);
                  }
                }}
                onChange={(e) => handleActiveShop(e)}
                inputProps={{ "aria-label": "controlled" }}
              />
              {/* <GreenSwitch defaultChecked /> */}
            </td>
          </tr>
        </td>

      </tr>
    </>
  );
}
const CommonTD = ({ showData, tableData, noData = "...", minW = 'min-w-[180px]', maxW = 'max-w-[180px]' }) => {
  const { setSelectedCustomerID } = useCustomerStore();

  // initialize useNavigate
  const navigate = useNavigate();

  const navigateToDetails = () => {
    navigate("customer-details");
  };
  return (
    <td
      onClick={() => {
        navigateToDetails();
        getCustomerDetails(tableData?.id);
        localStorage.setItem("customerID", tableData?.id);
        setSelectedCustomerID(tableData?.id);
      }}
      className={`text-center p-s10 border-r ${minW} ${maxW}`}
    ><Clamp withTooltip lines={1}>
        {showData ? showData : noData}
      </Clamp>
    </td>
  );
};
